import { PaginatedResponse } from 'src/api-dtos/paginated-response.dto'
import { DeleteRequest, GetRequest } from 'src/helpers/lopay_api_helper'
import { SupportUserSessionDto } from './support-merchant-team.type'

export function getUserSessions({
    userId,
    pageSize,
}: {
    userId: string
    pageSize: number
}): Promise<PaginatedResponse<SupportUserSessionDto>> {
    return GetRequest<PaginatedResponse<SupportUserSessionDto>>(`support/users/${userId}/sessions?pageSize=${pageSize}`)
}

export function deactivateUserSession({
    userId,
    sessionId,
}: {
    userId: string
    sessionId: string
}): Promise<void> {
    return DeleteRequest<void>(`support/users/${userId}/sessions/${sessionId}`)
}