import React, { useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
    Container,
    Col,
    Card,
    CardBody,
    CardHeader,
    Row,
    Modal,
} from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useHardware } from './hardware.hook'
import HardwareTable from './components/hardware-table/hardware-table.component'
import { Link, useHistory } from 'react-router-dom'
import CreateHardware from './components/create-hardware.component'
import { HardwareDto } from './hardware.type'
import { createHardware, deleteHardware } from './hardware.api'
import DeleteHardware from './components/delete-hardware.component'

type IComponentProps = {}

const HardwarePage: React.FC<IComponentProps> = () => {
    const history = useHistory()
    const { data, isError, isLoading } = useHardware()
    const [isCreatingNewHardware, setIsCreatingNewHardware] = useState(false);
    const [createHardwareError, setCreateHardwareError] = useState('');
    const [hardwareToDelete, setHardwareToDelete] = useState<HardwareDto | undefined>();
    const [deletingHardware, setDeletingHardware] = useState(false);
    const [deleteHardwareError, setDeleteHardwareError] = useState('');

    const onCreateHardware = async (newHardware: Partial<HardwareDto>, newMerchantsOnly: boolean, overwriteDefaultTerminals: boolean) => {
        setCreateHardwareError('')
        try {
            await createHardware(newHardware, newMerchantsOnly, overwriteDefaultTerminals);
            history.go(0)
        } catch(error: any) {
            setCreateHardwareError(error.message)
        }
    }

    const onOpenDeleteHardwareModal = (hardware: HardwareDto) => {
        setDeletingHardware(true);
        setHardwareToDelete(hardware);
    }

    const onDeleteHardware = async (hardwareId: string) => {
        try {
            await deleteHardware(hardwareId)
            history.go(0)
        } catch (error: any) {
            setDeleteHardwareError(error.message)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Hardware</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={'LoPay Support'}
                        breadcrumbItem="Hardware"
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <div className="row align-ite  ms-center">
                                        <div className="col-md-6">
                                            <p className="card-title-desc">
                                                This is a list of hardware that is available for our merchants to purchase in-app.
                                            </p>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                                <div>
                                                    <Link
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setIsCreatingNewHardware(true)
                                                        }}
                                                        to="#"
                                                        className="btn btn-primary"
                                                    >
                                                        <i className="bx bx-plus me-1"></i> Add Hardware
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <HardwareTable
                                        data={data}
                                        isError={isError}
                                        isLoading={isLoading}
                                        onDeleteHardware={hardware => onOpenDeleteHardwareModal(hardware)}
                                        deletingHardware={deletingHardware}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Create New Hardware Modal */}
            <Modal
                isOpen={!!isCreatingNewHardware}
                toggle={() => {
                    setIsCreatingNewHardware(!isCreatingNewHardware)
                }}
                scrollable={true}
            >
                <CreateHardware
                    data={data}
                    isError={isError}
                    isLoading={isLoading}
                    onCloseModal={() => setIsCreatingNewHardware(false)}
                    onSubmitNewHardware={(newHardware, newMerchantsOnly, overwriteDefaultTerminals)=>{onCreateHardware(newHardware, newMerchantsOnly, overwriteDefaultTerminals)}}
                    createError={createHardwareError}
                />
            </Modal>

            {/* Delete Hardware Modal */}
            {hardwareToDelete ?
            <Modal
                isOpen={!!deletingHardware}
                toggle={() => {
                    setDeletingHardware(!deletingHardware)
                }}
                scrollable={true}
            >
                <DeleteHardware
                    hardware={hardwareToDelete!}
                    onCloseModal={() => {
                        setDeletingHardware(false)
                    }}
                    onSubmitDeleteHardware={(hardwareId)=>{onDeleteHardware(hardwareId)}}
                    deleteError={deleteHardwareError}
                />
            </Modal>
            : null}

        </React.Fragment>
    )
}

export default HardwarePage
