/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
    useDisconnectQuickBooksMutation,
    useRefreshQuickBooksAccountsListMutation,
} from '../../quickbooks.hook'
import QuickBooksSyncConfig from '../quickbooks-sync-config/quickbooks-sync-config.component'
import { Card, CardBody } from 'reactstrap'
import moment from 'moment'

type IComponentProps = {
    merchantId: string
    apiKey: string
    companyName?: string
    status?: string
    lastSyncDate?: string
}

const QuickbooksConfig: React.FC<IComponentProps> = ({
    merchantId,
    apiKey,
    companyName,
    status,
    lastSyncDate,
}) => {
    const history = useHistory()

    const disconnectQuickbooksMutation = useDisconnectQuickBooksMutation({
        merchantId,
        key: apiKey,
    })

    const { mutate, isLoading } = useRefreshQuickBooksAccountsListMutation({
        merchantId,
        key: apiKey,
    })

    return (
        <div className="auth-content my-auto">
            <div>
                <h4 className="mb-0">
                    Setup your QuickBooks Online connection
                </h4>
                <p className="text-muted mt-2">
                    <a
                        href="#"
                        onClick={() => {
                            if (!isLoading) {
                                mutate()
                            }
                        }}
                    >
                        Refresh
                    </a>{' '}
                    to show your updated QuickBooks Online accounts.
                </p>
            </div>
            <div className="text-center mt-4 mb-4 pt-2">
                <div className="text-muted mb-2">{companyName}</div>
                <button
                    disabled={disconnectQuickbooksMutation.isLoading}
                    onClick={async () => {
                        await disconnectQuickbooksMutation.mutateAsync()

                        history.replace('/quickbooks/disconnected')
                    }}
                    className="btn btn-sm btn-outline-danger"
                >
                    {disconnectQuickbooksMutation.isLoading
                        ? 'Disconnecting...'
                        : 'Disconnect from QuickBooks'}
                </button>
            </div>
            <QuickBooksSyncConfig
                merchantId={merchantId}
                apiKey={apiKey}
                isRefreshingAccountList={isLoading}
            />
            {status && status !== 'failed' && (
                <Card className="mt-5 border border-primary">
                    <div className="card-header bg-transparent border-primary">
                        <h5 className="my-0 text-primary">
                            Data export status
                        </h5>
                    </div>
                    <CardBody>
                        <div>
                            <h5 className="card-title">Status</h5>
                            <p className="card-text text-uppercase">{status}</p>
                        </div>
                        {lastSyncDate && (
                            <>
                                <div className="mt-4">
                                    <h5 className="card-title">
                                        Last sync date
                                    </h5>
                                    <p className="card-text">
                                        {moment(lastSyncDate).format(
                                            'DD MMM, YYYY'
                                        )}
                                    </p>
                                </div>
                            </>
                        )}
                        <div className="mt-4">
                            <h5 className="card-title">Next sync date</h5>
                            <p className="card-text">
                                {status === 'pending'
                                    ? 'In a few moments'
                                    : moment(lastSyncDate ?? undefined)
                                          .add(1, 'day')
                                          .format('DD MMM, YYYY')}
                            </p>
                        </div>
                    </CardBody>
                </Card>
            )}
            {status === 'failed' && (
                <Card className="mt-5 border border-danger">
                    <div className="card-header bg-transparent border-danger">
                        <h5 className="my-0 text-danger">Data export status</h5>
                    </div>
                    <CardBody>
                        <div>
                            <h5 className="card-title">Status</h5>
                            <p className="card-text text-uppercase">{status}</p>
                        </div>
                        {lastSyncDate && (
                            <div className="mt-4">
                                <h5 className="card-title">Last sync date</h5>
                                <p className="card-text">
                                    {moment(lastSyncDate).format(
                                        'DD MMM, YYYY'
                                    )}
                                </p>
                            </div>
                        )}
                        <div className="mt-4">
                            <h5 className="card-title">Next sync date</h5>
                            <p className="card-text">
                                {moment(lastSyncDate ?? undefined)
                                    .add(1, 'day')
                                    .format('DD MMM, YYYY')}
                            </p>
                        </div>
                        <div className="mt-4">
                            <h5 className="card-title">
                                Troubleshooting steps
                            </h5>
                            <p className="card-text">
                                <ul>
                                    <li>
                                        Confirm that your QuickBooks
                                        subscription is still active.
                                    </li>
                                    <li>
                                        Confirm your QuickBooks subscription is
                                        one of the following:
                                        <ul>
                                            <li>
                                                QuickBooks Online Essentials
                                            </li>
                                            <li>QuickBooks Online Plus</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    If the problem persists, please contact
                                    Lopay support.
                                </p>
                            </p>
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    )
}

export default QuickbooksConfig
