/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { ReactComponent as QuickbooksConnectDefault } from 'src/assets/images/quickbooks/C2QB_green_btn_med_default.svg'
import { ReactComponent as QuickbooksConnectHover } from 'src/assets/images/quickbooks/C2QB_green_btn_med_hover.svg'

type IComponentProps = {
    merchantId: string
    apiKey: string
}

const QuickbooksConnectButton: React.FC<IComponentProps> = ({ merchantId, apiKey }) => {
    const [isHover, setIsHover] = React.useState<boolean>(false)
    const connectOnClick = React.useCallback(() => {
        const consentUri = `${process.env.REACT_APP_API_HOST}merchant/${merchantId}/quickbooks/connect?key=${apiKey}`

        window.location.href = consentUri
    }, [merchantId, apiKey])

    return (
        <a
            href="#"
            onClick={connectOnClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {isHover ? (
                <QuickbooksConnectDefault />
            ) : (
                <QuickbooksConnectHover />
            )}
        </a>
    )
}

export default QuickbooksConnectButton
