import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Input,
    CardHeader,
    Button,
    Container,
} from "reactstrap";
import CountryAPIResponse from "../../api-dtos/country/countries-response.dto.interface";
import { AvForm } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createMerchant, fetchCountries } from "../../helpers/lopay_api_helper";
import { fetchProfile } from "../../store/actions";
import CreateMerchantRequest from "../../api-dtos/merchant/create-merchant-request.dto.interface";
import UserProfileResponse from "../../api-dtos/profile/user-profile-response.dto.interface";

interface MerchantPageProps {
    history: any
}


const SetupMerchantPage = ({ history }: MerchantPageProps) => {

    const dispatch = useDispatch();

    const [countries, setCountries] = useState<CountryAPIResponse[]>()
    const [error, setError] = useState<string | null>()
    const [loading, setLoading] = useState<boolean>(false)

    // State of form
    const [pinNumber, setPinNumber] = useState<string>("")
    const [companyName, setCompanyName] = useState<string>("")
    const [country, setCountry] = useState<CountryAPIResponse>()

    const { profile } = useSelector((state: { profile: { profile: UserProfileResponse } }) => ({
        profile: state.profile.profile,
    }))

    // Fetch countries and currencies:
    console.log('profile updated:', profile)

    if (profile?.user?.merchants?.length && profile.user.merchants[0].id) {
        history.push("/dashboard");
    }

    const handleFetchCountries = async () => {
        const countries = await fetchCountries()
        setCountries(countries)
        if (countries.length) {
            setCountry(countries[0])
        }
    }

    useEffect(() => {
        handleFetchCountries()
        dispatch(fetchProfile())
        // eslint-disable-next-line
    }, [])

    const handleSaveButton = async () => {

        setError(null)

        if (!pinNumber || pinNumber.trim().length !== 4) {
            setError("Please choose a 4 digit PIN to help protect your account")
            return
        }

        if (!companyName || !companyName.length) {
            setError("Please enter your company or trading name")
            return
        }

        if (!country) {
            setError("Please select your country")
            return
        }

        console.log('saving', companyName, country?.code, country?.defaultCurrency?.code)

        setLoading(true)

        const merchant: CreateMerchantRequest = {
            name: companyName,
            pin: pinNumber,
            country: country.code,
            currencyCode: country.defaultCurrency.code,
        }

        try {
            const newMerchant = await createMerchant(merchant)

            console.log('newMerchant:', newMerchant)

            // Update the application state with the users profile
            dispatch(fetchProfile())

        } catch (e) {
            setError("Sorry, there was a problem registering your details")
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Setup your merchant</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Setup" breadcrumbItem="New Merchant" />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                <h4 className="card-title">Complete your details</h4>
                                    <p className="card-title-desc">
                                        Enter your details details to get started
                                    </p>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <Col lg={6}>
                                        <AvForm>
                                            <div>
                                                {error ? <Alert color="danger">{error}</Alert> : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="example-text-input" className="form-Label">Business or trading name</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        value={companyName}
                                                        onChange={e => setCompanyName(e.target.value)}
                                                        id="company-name-text-input"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-Label">Country</Label>
                                                    <select
                                                        className="form-select"
                                                        onChange={e => countries && setCountry(countries[parseInt(e.target.value)])}
                                                    >
                                                        {countries && countries.map(
                                                            (country, i) => <option
                                                                key={country.code}
                                                                value={i}>{country.flag} {country.name}
                                                            </option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-Label">Setup a 4 digit PIN</Label>
                                                    <Input
                                                        name="pin-code"
                                                        label="Setup a 4 digit PIN"
                                                        placeholder="1234"
                                                        onChange={e => setPinNumber(e.target.value)}
                                                        type="number"
                                                        errorMessage="Choose a 4 digit PIN"
                                                        validate={{
                                                            required: { value: true },
                                                            maxLength: { value: 4, errorMessage: "Must be 4 digits." },
                                                            minLength: { value: 4, errorMessage: "Must be 4 digits." },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AvForm>
                                    </Col>
                                    <Button disabled={loading} onClick={handleSaveButton} color="primary" type="submit">
                                        {loading ? `Loading` : `Save`}
                                    </Button>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default SetupMerchantPage;
