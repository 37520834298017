import { useState } from "react"
import { Badge, Modal, Table } from "reactstrap"
import { UserApiDto } from "src/api-dtos/user/user-api.dto.interface"
import { resetMobile2fa } from "src/helpers/lopay_api_helper"

interface UserDetailsModalProps {
    user: UserApiDto | undefined
    onClose: () => void
    history: any
}

export const UserDetailsModal = ({user, onClose, history}: UserDetailsModalProps) => {
    const [copyUserIdText, setCopyUserIdText] = useState<string>('Copy')
    const [resetAreYouSure, setResetAreYouSure] = useState(false);
    const [mobile2faReset, setMobile2faReset] = useState(false);

    const copyUserId = (id: string) => {
        navigator.clipboard.writeText(id).then(
        function () {
            setCopyUserIdText("Copied 🎉");
        },
        function (err) {
            setCopyUserIdText("Could not copy 😭");
        }
        );
        setTimeout(function () {
            setCopyUserIdText("Copy");
        }, 2500)
    };


    const getFullName = (user: UserApiDto) => user ? `${user.firstName} ${user.lastName}` : ''

    const onResetMobile2FAClick = async (userId: string) => {
        if (!resetAreYouSure) {
            setResetAreYouSure(true)
        } else {
            await resetMobile2fa(userId)
            setResetAreYouSure(false)
            setMobile2faReset(true);
        }
    }

    const handleClose = () => {
        onClose()
        setResetAreYouSure(false);
        setMobile2faReset(false);
    }

    if (!user) return null

    return (
        <Modal
            isOpen={!!user}
            toggle={() => {
                handleClose()
            }}
            scrollable={true}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    {getFullName(user)}
                </h5>
                <button
                    type="button"
                    onClick={() => handleClose()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div>
                    <div className="mb-4">
                        <h5 className="font-size-14 mb-1">
                            User Details
                        </h5>
                        <p className="card-title-desc mb-2">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            ID: {user.id} <b><a href='#' onClick={() => copyUserId(user.id)}>[{copyUserIdText}]</a></b>
                        </p>
                        <div>
                            <span>{getFullName(user)}</span>
                            <br/>
                            <span>{user.email}</span>
                            <br/>
                            <span>{user.phone}</span>
                        </div>
                        <hr/>
                        <div>
                            <h5 className="font-size-14 mb-1">
                                2FA
                            </h5>
                            <div>
                                <span>Mobile 2FA Enabled: {user.security.smsEnabled && !mobile2faReset ? `✅ (${user.security.phone})` : '❌'}</span>
                                <br/>
                                <span>Passcode Enabled: {user.security.passcodeEnabled ? '✅' : '❌' }</span>
                                <br/>
                                <span>Biometrics Enabled: {user.security.biometricsEnabled ? '✅' : '❌'}</span>
                                <br/>
                                <span>In Grace Period: {user.security.inGracePeriod ? '✅' : '❌'}</span>
                            </div>
                            <br/>
                            { !mobile2faReset && user.security.smsEnabled ?
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onResetMobile2FAClick(user.id)
                                    }}
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                >
                                    {resetAreYouSure ? 'Click here again to confirm 2FA reset' : 'Reset Mobile 2FA'}
                                </button>
                            : null }
                        </div>
                        <hr />
                        <div>
                            <h5 className="font-size-14 mb-1">
                                Merchants
                            </h5>
                            <div className="mb-3">
                                <div className="table-responsive">
                                    <Table className="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                <th>
                                                    Is Owner
                                                </th>
                                                <th>
                                                    Options
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {user
                                                ?.merchants
                                                ?.length ===
                                                0 && (
                                                <Badge className="me-2 bg-danger">
                                                    Danger:
                                                    This
                                                    user
                                                    has no
                                                    merchants.
                                                    Perhaps
                                                    they
                                                    have
                                                    been
                                                    deleted?
                                                </Badge>
                                            )}
                                            {user?.merchants?.map(
                                                (merchant) => {
                                                    return (
                                                        <tr
                                                            key={`${user?.id}=${merchant.id}`}
                                                        >
                                                            <th scope="row">
                                                                {
                                                                    merchant.name
                                                                }
                                                            </th>
                                                            <td>
                                                                {
                                                                    merchant.isOwner ? '✅' : '❌'
                                                                }
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        history.push(
                                                                            '/merchant/' + merchant.id
                                                                        )
                                                                    }}
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary"
                                                                >
                                                                    View Merchant
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}