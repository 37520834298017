import {
    DeleteRequest,
    GetRequest,
    PostRequest,
    PutRequest,
} from 'src/helpers/lopay_api_helper'
import {
    MerchantCapability,
    PaginatedWaitingLists,
    WaitingListDto,
} from './waiting-lists.type'

export function getWaitingLists(): Promise<PaginatedWaitingLists> {
    return GetRequest<PaginatedWaitingLists>('support/waiting-lists')
}

export function createWaitingList(data: {
    type: string
    waitingListCapabilities: MerchantCapability[]
    grantedAccessCapabilities: MerchantCapability[]
}): Promise<WaitingListDto> {
    return PostRequest<WaitingListDto>('support/waiting-lists', data)
}

export function updateWaitingList(
    id: string,
    data: {
        waitingListCapabilities: MerchantCapability[]
        grantedAccessCapabilities: MerchantCapability[]
    }
): Promise<WaitingListDto> {
    return PutRequest<WaitingListDto>(`support/waiting-lists/${id}`, data)
}

export function deleteWaitingList(id: string): Promise<void> {
    return DeleteRequest<void>(`support/waiting-lists/${id}`)
}
