import { KYBSettingsDTO } from '../../dtos/kyb-settings.dto'
import { Col, Input, Label, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useUpdateKYBSettingsMutation } from '../../hooks/kyb-settings.hook'

type KYB3DS2LimitsProperties = Pick<KYBSettingsDTO, 'daily3DS2Limit'>

type KYB3DS2LimitsProps = {
    merchantId: string
    limits: KYB3DS2LimitsProperties
    refetchFn: () => void
}

const KYB3DS2Limits = (props: KYB3DS2LimitsProps) => {
    const { merchantId, limits, refetchFn } = props

    const mutation = useUpdateKYBSettingsMutation(merchantId, refetchFn)
    const [dailyLimit, setDailyLimit] = useState<number | undefined>(limits.daily3DS2Limit)
    const [originalDailyLimit] = useState<number>(limits.daily3DS2Limit)

    /**
     * Keeps track of whether the value has been updated by someone else (or automatically), since the page
     * was loaded.
     *
     * We don't update the value automatically since we don't want to confuse the user.
     */
    const hasOriginalValueChanged = originalDailyLimit !== limits.daily3DS2Limit

    const handleSubmit = () => {
        if (dailyLimit !== undefined) {
            mutation.mutate({
                daily3DS2Limit: dailyLimit,
            })
        }
    }

    return (
        <div>
            <Row>
                <Label
                    // className="visually-hidden"
                    htmlFor="dailyTransactionLimitUnits"
                >
                    Non-3DS2 Allowances
                </Label>
                <Col sm={12} lg={4}>
                    <div className="input-group">
                        <div className="input-group-text">
                            Daily £
                        </div>
                        <Input
                            type="number"
                            className="form-control"
                            id="dailyTransactionLimitUnits"
                            value={typeof dailyLimit === 'number' ? dailyLimit / 100 : undefined}
                            placeholder="0.00"
                            onChange={(e) => {
                                const value = e.target.value === ''
                                    ? undefined
                                    : e.target.value
                                setDailyLimit(value
                                    ? parseFloat(value) * 100
                                    : undefined
                                )
                            }}
                            disabled={mutation.isLoading}
                        />
                    </div>
                    {
                        hasOriginalValueChanged &&
                            <small className="text-danger">
                                Current active value set to £{limits.daily3DS2Limit / 100} after recent update!
                            </small>
                    }
                </Col>
                <Col>
                    <button
                        className="btn btn-primary py-0 pe-2 px-1 d-flex align-items-center justify-content-center"
                        onClick={handleSubmit}
                        disabled={mutation.isLoading || dailyLimit === undefined}
                    >
                        {
                            mutation.isLoading
                                ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                : <i className="mdi mdi-content-save font-size-24"></i>
                        }
                        <span className="ms-1">Save</span>
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default KYB3DS2Limits