import React, { useState } from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TerminalOrderDto from "src/api-dtos/terminal-order/terminal-order.dto.interface";
import OrderDeliveryStatus from "src/api-dtos/types/order-delivery-status.type";
import OrderIssueStatus from 'src/api-dtos/types/order-issue-status.type';
import OrderReturnStatus from 'src/api-dtos/types/order-return-status.type';
import { updateDeliveryStatus, updateTerminalOrderIssue, updateTerminalOrderReturn } from "src/helpers/lopay_api_helper";

interface IComponentProps {
    merchantId: string
    type: 'delivery' | 'return' | 'issue'
    terminalOrder: TerminalOrderDto
    selectedStatusDropdown: {
        terminalOrderId: string | null
        statusType: 'delivery' | 'return' | 'issue'
    }
    onToggleDropdown: ({
        terminalOrderId,
        statusType
    }: {
        terminalOrderId: string | null
        statusType: 'delivery' | 'return' | 'issue'
    }) => void
}

const SupportMerchantHardwareStatusButton: React.FC<IComponentProps> = ({
    merchantId,
    type,
    terminalOrder,
    selectedStatusDropdown,
    onToggleDropdown
}) => {
    const [issueDetailsModalOpen, setIssueDetailsModalOpen] = useState(false);
    const [issueDetails, setIssueDetails] = useState('');
    const isOpen = selectedStatusDropdown.terminalOrderId === terminalOrder.id && selectedStatusDropdown.statusType === type
    
    const getStatus = () => {
        switch (type) {
            case 'delivery':
                return terminalOrder.delivery.status
            case 'return':
                return terminalOrder.delivery.returnStatus || 'No Return'
            case 'issue':
                return terminalOrder.delivery.issueStatus || 'No Issue'
        }
    }

    const getButtonColour = () => {
        const status = getStatus();
        if (status === 'No Return' || status === 'No Issue'){
            return 'light'
        }
        if ([...orderIssueStatuses, 'order_failed'].includes(status)) {
            return 'danger'
        }

        if (['order_delivered', 'return_received'].includes(status)) {
            return 'success'
        }

        return 'warning'
    }

    const orderDeliveryStatuses: OrderDeliveryStatus[] = [
        'pending_placement',
        'order_submitted',
        'order_failed',
        'order_shipped',
        'order_delivered',
    ];
    
    const orderReturnStatuses: OrderReturnStatus[] = [
        'return_due',
        'return_not_expected',
        'return_received',
    ];
    
    const orderIssueStatuses: OrderIssueStatus[] = [
        'battery_issue',
        'cancelled',
        'change_of_mind',
        'discretionary',
        'error_code',
        'not_received',
        'tamper_detected',
        'other'
    ];

    const friendlyOrderStatusName = (status: string) => {
        switch (status) {
            case "pending_placement":
            return "Pending postage";
            case "order_delivered":
            return "Delivered";
            default:
            return status.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
        }
    };

    const toggleIssueDetailsModal = () => {
        setIssueDetailsModalOpen(false);
        setIssueDetails('');
    }

    const handleUpdateStatus = async (
        orderId: string,
        status: OrderDeliveryStatus | OrderReturnStatus | OrderIssueStatus | null
    ) => {
        switch(type) {
            case 'delivery':
                terminalOrder.delivery.status = status as OrderDeliveryStatus;
                await updateDeliveryStatus(orderId, status as OrderDeliveryStatus);
                break;
            case 'return':
                terminalOrder.delivery.returnStatus = status as OrderReturnStatus
                await updateTerminalOrderReturn(merchantId, orderId, status as OrderReturnStatus)
                break;
            case 'issue':
                if (status) {
                    setIssueDetailsModalOpen(true);
                    terminalOrder.delivery.issueStatus = status as OrderIssueStatus
                } else {
                    terminalOrder.delivery.issueStatus = null;
                    terminalOrder.delivery.issueDescription = '';
                    await updateTerminalOrderIssue(merchantId, terminalOrder.id, null, null);
                }
                break;
        }
    }

    const onSubmitIssueUpdate = async () => {
        terminalOrder.delivery.issueDescription = issueDetails;
        await updateTerminalOrderIssue(merchantId, terminalOrder.id, terminalOrder.delivery.issueStatus as OrderIssueStatus, issueDetails);
        toggleIssueDetailsModal();
    }

    const getStatuses = (statusType: 'delivery' | 'return' | 'issue') => {
        switch(statusType) {
            case 'delivery':
                return orderDeliveryStatuses;
            case 'return':
                return [...orderReturnStatuses, null];
            case 'issue':
                return [...orderIssueStatuses, null];
        }
    }

    let dropdownStatuses = getStatuses(type)

    return (
        <>
      <div className="btn-group">
        <ButtonDropdown
          isOpen={isOpen}
          toggle={() => {
            onToggleDropdown({
                terminalOrderId: !isOpen ? terminalOrder.id : null,
                statusType: type
            });
          }}
        >
          <Button id="caret" color={getButtonColour()}>
            {
            friendlyOrderStatusName(
              getStatus()
            )}
          </Button>
          <DropdownToggle caret color={getButtonColour()}>
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>
              Update {type} status
            </DropdownItem>
            {dropdownStatuses.map((status) => {
              let selected = type === 'delivery' ?
                status === terminalOrder.delivery.status : false;
              if (!selected) {
                if (type === 'return') {
                    selected = status === terminalOrder.delivery.returnStatus;
                }
                if (type === 'issue') {
                    selected = status === terminalOrder.delivery.issueStatus;
                }
              }
              return (
                <DropdownItem
                  onClick={() => {
                    handleUpdateStatus(
                        terminalOrder.id,
                        status
                    )
                  }}
                  active={selected}
                  disabled={selected}
                  key={status}
                >
                  {friendlyOrderStatusName(status !== null ? status : type === 'return' ? 'no_return' : 'no_issue')}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>

      <Modal isOpen={issueDetailsModalOpen} toggle={toggleIssueDetailsModal}>
        <ModalHeader toggle={toggleIssueDetailsModal}>Update Hardware Fault Status</ModalHeader>
        <ModalBody>
            <p>New Status: {friendlyOrderStatusName(
              getStatus()
            )}</p>
            <div className="mb-3">
                <Label
                htmlFor="issue-details"
                className="form-Label"
                >
                    Fault Details
                </Label>

                <div className="input-group">
                    <Input
                        type="text"
                        className="form-control"
                        value={issueDetails}
                        onChange={(e) => {
                            setIssueDetails(e.target.value)
                            
                        }}
                        id="issue-details"
                        placeholder="Details about the fault"
                    />
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button onClick={toggleIssueDetailsModal}>Cancel</Button>
            <Button color="primary" onClick={onSubmitIssueUpdate}>Update Fault</Button>
        </ModalFooter>
      </Modal>
      </>
    )
  }

export default SupportMerchantHardwareStatusButton;