import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React from 'react'

type ConfirmationModalProps = {
    children: any
    onConfirm: () => void
    onCancel?: () => void
    isOpen: boolean
    toggle: () => void
}

const ConfirmationModal = ({
    children,
    onConfirm,
    onCancel,
    isOpen,
    toggle,
}: ConfirmationModalProps) => {
    const confirm = () => {
        onConfirm()
        toggle()
    }

    const cancel = () => {
        if (onCancel) {
            onCancel()
        }
        toggle()
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Please confirm action</ModalHeader>
            <ModalBody>
                { children }
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={confirm}>Confirm action</Button>
                <Button onClick={cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
