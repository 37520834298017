import { ProfileTypes } from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  supportProfile: null,
}

const profile = (state = initialState, action : any) => {
  switch (action.type) {

    case ProfileTypes.FETCH_PROFILE_SUPPORT:
      state = { ...state }
      break
    case ProfileTypes.FETCH_PROFILE_SUPPORT_SUCCESS:
      state = { ...state, supportProfile: action.payload }
      break

    case ProfileTypes.UPDATE_EDIT_PROFILE_SUPPORT:
      state = { ...state }
      break
    case ProfileTypes.UPDATE_PROFILE_SUPPORT_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case ProfileTypes.UPDATE_PROFILE_SUPPORT_ERROR:
      state = { ...state, error: action.payload }
      break
    case ProfileTypes.UPDATE_RESET_PROFILE_SUPPORT_FLAG :
      state = { ...state, success: "" }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
