import React from 'react'
import { Progress } from 'reactstrap'

type IComponentProps = {
    onboardingPercentage: number
}

const AccountSetupProgress: React.FC<IComponentProps> = ({
    onboardingPercentage,
}) => (
    <Progress
        className="progress-xl"
        color={onboardingPercentage === 100 ? 'success' : 'info'}
        value={onboardingPercentage}
    >
        {onboardingPercentage}%
    </Progress>
)

export default AccountSetupProgress
