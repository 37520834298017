import React from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import MerchantApiDto from 'src/api-dtos/merchant/merchant.dto.interface'
import { useRewardChallenges } from './support-merchant-reward-challenges.hook'
import RewardChallengeSection from './components/reward-challenge-section.component'

const StyledTitle = styled.h6`
    margin-bottom: 1rem;
`

const StyledCenterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`

const StyledSpacer = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
`

const StyledMerchantRewardChallengesContainer = styled.div``

type IComponentProps = {
    merchant: MerchantApiDto
    isActive: boolean
}

const SupportMerchantRewardChallenges: React.FC<IComponentProps> = ({
    merchant,
    isActive,
}) => {
    const result = useRewardChallenges({
        merchant,
        isActive,
    })

    const sections = React.useMemo(
        () => result.data?.sections ?? [],
        [result.data]
    )

    if (result.isLoading || !result.data) {
        return <Spinner className="ms-2" color="primary" />
    }

    if (result.isError) {
        return (
            <>
                Something went wrong whilst loading reward challenges. Please
                refresh the page.
            </>
        )
    }

    if (sections.length === 0) {
        return (
            <React.Fragment>
                <StyledTitle>Reward challenges</StyledTitle>
                <StyledCenterContainer>
                    The merchant has no reward challenges.
                </StyledCenterContainer>
            </React.Fragment>
        )
    }

    return (
        <StyledMerchantRewardChallengesContainer>
            {sections.map((section) => (
                <>
                    <RewardChallengeSection section={section} />
                    <StyledSpacer />
                </>
            ))}
        </StyledMerchantRewardChallengesContainer>
    )
}

export default SupportMerchantRewardChallenges
