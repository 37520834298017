import { convertCamelCaseToWords, currencyFormat, percentageFormat } from "src/helpers/utils"
import { HardwareDto } from "./hardware.type"
import CountryAPIResponse from "src/api-dtos/country/countries-response.dto.interface"

export const formatNiceName = (notNiceName: string | undefined) => {
    if (!notNiceName) return ''
    const spaced = notNiceName.replace(/_/g, ' ')
    return spaced.replace(/\b\w/g, (m) => m.toUpperCase())
}

export const formatNotNiceName = (niceName: string | undefined) => {
    if (!niceName) return ''
    let cleanedString = niceName.trim().replace(/[^a-zA-Z0-9 ]/g, '')
    return cleanedString.replace(/\s+/g, '_').toLowerCase()
}

export const getDifferencesForUpdatedHardware = (
    originalHardware: HardwareDto,
    updatedHardware: HardwareDto
) => {
    const differences: Record<string, { oldValue: any; newValue: any }> = {}

    for (const key in originalHardware) {
        if (
            originalHardware.hasOwnProperty(key) &&
            updatedHardware.hasOwnProperty(key)
        ) {
            const originalHardwareValue =
                originalHardware[key as keyof HardwareDto]
            const updatedHardwareValue =
                updatedHardware[key as keyof HardwareDto]
            if (originalHardwareValue !== updatedHardwareValue) {
                differences[key] = {
                    oldValue: originalHardwareValue,
                    newValue: updatedHardwareValue,
                }
            }
        }
    }

    return differences
}

export const getNiceNameOfField = (key: string) => {
    switch (key) {
        case 'hardwareCostUnits':
            return 'Hardware Cost'
        case 'postageCostUnits':
            return 'Postage Cost'
        case 'firstOrderDiscountUnits':
            return 'First Order Discount Amount'
        case 'vatPercent':
            return 'VAT'
        case 'fallbackToStripe':
            return 'Provided By Mintsoft Only'
        case 'isTerminal':
            return 'Is a Terminal'
        case 'hardwareParentId':
            return 'Hardware Parent'
        default:
            return convertCamelCaseToWords(key)
    }
}

export const formatValueBasedOnKey = (data: HardwareDto[], countriesData: CountryAPIResponse[] , key: string, value: any) => {
    const currencyKeys = [
        'hardwareCostUnits',
        'postageCostUnits',
        'firstDiscountAmountUnits',
    ]
    const percentKeys = ['vatPercent']
    if (currencyKeys.includes(key)) {
        return `£${currencyFormat(value)}`
    } else if (percentKeys.includes(key)) {
        return percentageFormat(value)
    } else if (key === 'isTerminal') {
        return value ? 'Yes' : 'No'
    } else if (key === 'fallbackToStripe') {
        return value ? 'No' : 'Yes'
    } else if (key === 'hardwareParentId') {
        if (!value) return 'No Parent'
        const hardwareParent = data?.find((h) => h.id === value)
        return formatNiceName(hardwareParent!.name)
    } else if (key === 'countriesAvailableIn') {
        return value.map((cc: string) => {
            const countryData = countriesData.find(c => c.code === cc)
            return `${countryData?.flag} ${countryData?.name}`
        }).join(', ')
    } else {
        return value
    }
}