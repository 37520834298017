import styled from 'styled-components'

const TimelineItemError = styled.p`
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0.375rem;
    line-height: 1.25rem;
    white-space: nowrap;
    color: #fd625e;
`

export default TimelineItemError