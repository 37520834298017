import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import moment from 'moment'
import { Search } from "react-bootstrap-table2-toolkit";

import _ from 'lodash'

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Modal,
  Badge,
  Table,
  Container,
} from "reactstrap";

import {
  supportArchivePaymentRequest,
  supportListPaymentRequests,
} from "../../../helpers/lopay_api_helper";
import { PaymentRequestsResponseDto } from "../../../api-dtos/payment-requests/list-payment-requests-response.dto.interface";
import PaymentRequestDto from "../../../api-dtos/payment-requests/payment-request.dto.interface";
import { buildStripeDashboardURL, currencyFormat, priceFormat } from "../../../helpers/utils";
import PaymentRequestStatus from "../../../api-dtos/payment-requests/payment-request-status.enum";
import SweetAlert from "react-bootstrap-sweetalert";
import MerchantApiDto from "../../../api-dtos/merchant/merchant.dto.interface";

const PaymentRequestsSupportComponent = (props: {
    merchant: MerchantApiDto;
  }) => {  
    
  const { SearchBar } = Search;
  const [searchText, setSearchText] = useState<string>("");

  // Selected payment request (for modal)
  const [selectedPaymentRequest, setSelectedPaymentRequest] = useState<
    PaymentRequestDto | undefined
  >();

  const [
    deletePaymentRequestConfirmation,
    setDeletePaymentRequestConfirmation,
  ] = useState(false);

  // List of payment request
  const [isLoadingPaymentRequests, setIsLoadingPaymentRequests] =
    useState<boolean>(false);
  const [paymentRequests, setPaymentRequests] =
    useState<PaymentRequestsResponseDto>({
      pageSize: 0,
      count: 0,
      paymentRequests: [],
    });

  var debouncedSearch = _.debounce(function (e) {
    if (!props.merchant) {
      return
    }
    console.log('Searching payment links for:', e);
    loadPaymentRequests(
        props.merchant.id,
      undefined,
      e.trim(),
    );
    }, 1200);
  
  const paymentDate = (date: Date) => {
    return new Date(date);
  };

  const copyLink = (link: PaymentRequestDto) => {
    navigator.clipboard.writeText(link.link).then(
      function () {
        alert("Link copied");
      },
      function (err) {
        alert("Could not copy link");
      }
    );
  };

  useEffect(() => {
    if (props.merchant) {
        loadPaymentRequests(props.merchant.id);
    }
    // eslint-disable-next-line
  }, [props.merchant]);


  if (!props.merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Payment Links</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading your payment links
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  const loadPaymentRequests = async (
    merchantId: string,
    offsetId?: string,
    query?: string,
  ) => {
    setIsLoadingPaymentRequests(true);
    const loadedPaymentRequests = await supportListPaymentRequests(
      merchantId,
      offsetId,
      query,
      undefined,
    );
    setIsLoadingPaymentRequests(false);

    if (offsetId && paymentRequests) {
      loadedPaymentRequests.paymentRequests =
        paymentRequests.paymentRequests.concat(loadedPaymentRequests.paymentRequests);
    }

    setPaymentRequests(loadedPaymentRequests);
  };

  const renderPaymentStatus = (status: PaymentRequestStatus, paidAt?: Date) => {
    switch (status) {
      case "pending":
        return <Badge className="me-2 bg-danger">Pending payment</Badge>;
      case "cancelled":
        return <Badge className="me-2 bg-dark">Cancelled</Badge>;
      case "completed":
        if (paidAt) {
          return <Badge className="me-2 bg-success">Completed at {moment(
            paymentDate(paidAt)
          ).format("MMM Do YYYY h:mm:ss a")}</Badge>;
        } else {
          return <Badge className="me-2 bg-success">Completed</Badge>;
        }
    }
  };

  const handleArchiveOpenPaymentRequest = async () => {
    if (!selectedPaymentRequest) {
      return;
    }

    await supportArchivePaymentRequest(props.merchant.id, selectedPaymentRequest.id);

    const indexOfPaymentRequest = paymentRequests.paymentRequests.findIndex(
      (pr) => pr.id === selectedPaymentRequest.id
    );

    paymentRequests.paymentRequests.splice(indexOfPaymentRequest, 1);

    setSelectedPaymentRequest(undefined);
  };

  return (
    <React.Fragment>
        <MetaTags>
          <title>Payment Links</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                  <div className="col-md-12 align-items-left">
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <SearchBar placeholder='Search by reference' searchText={searchText}  onSearch={(e) => {
                            console.log('e', e)
                            setSearchText(e)
                            debouncedSearch(e)
                          }} />
                          <i className="bx bx-search-alt search-icon-search" />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Ref (name)</th>
                          <th>Amount (£)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {(isLoadingPaymentRequests && !paymentRequests?.paymentRequests?.length) ? (
                        <tbody><tr>
                        <th>Loading...</th></tr></tbody>
                      ) : (
                        <tbody>
                          {paymentRequests &&
                            paymentRequests.paymentRequests.map(
                              (paymentRequest) => {
                                return (
                                  <tr
                                    onClick={async () => {
                                      setSelectedPaymentRequest(paymentRequest)
                                    }}
                                    key={paymentRequest.id}
                                  >
                                    <td>
                                      <span className="time">
                                        {moment(
                                          paymentDate(paymentRequest.createdAt)
                                        ).format("MMM Do YYYY h:mm:ss a")}
                                      </span>
                                    </td>
                                    <th scope="row">
                                      {paymentRequest.customerFacingDescription}
                                    </th>
                                    <td>
                                      {priceFormat(paymentRequest.amount)}
                                    </td>
                                    <td>
                                      {renderPaymentStatus(
                                        paymentRequest.status,
                                        paymentRequest.paidAt,
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      )}
                    </Table>

                    {paymentRequests?.paymentRequests?.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-dark btn-sm waves-effect waves-light mt-2"
                        onClick={() => {
                          if (isLoadingPaymentRequests) {
                            return;
                          }
                          const lastItem = paymentRequests.paymentRequests[paymentRequests?.paymentRequests.length-1]
                          loadPaymentRequests(
                            props.merchant.id,
                            lastItem.id,
                            searchText,
                          );
                        }}
                      >
                        {isLoadingPaymentRequests && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                          </>
                        )}
                        {isLoadingPaymentRequests ? <b>Loading...</b> : <b>Show more</b>}
                      </button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={!!selectedPaymentRequest}
            toggle={() => {
              setSelectedPaymentRequest(undefined);
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                £{currencyFormat(selectedPaymentRequest?.amount.units || 0)}{" "}
                {selectedPaymentRequest &&
                  renderPaymentStatus(selectedPaymentRequest?.status)}
              </h5>
              <button
                type="button"
                onClick={() => setSelectedPaymentRequest(undefined)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>


              <div className="mb-4">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                          {
                              selectedPaymentRequest?.createdAt && <tr>
                              <th>Created</th>
                              <td>{moment(
                                    paymentDate(selectedPaymentRequest.createdAt)
                                  ).format("MMM Do YYYY h:mm:ss a")}</td>
                            </tr>
                            }
                            <tr>
                              <th>Ref (name)</th>
                              <td>{selectedPaymentRequest?.customerFacingDescription}</td>
                            </tr>
                            {
                              selectedPaymentRequest?.paidAt && <tr>
                              <th>Paid</th>
                              <td>{moment(
                                    paymentDate(selectedPaymentRequest.paidAt)
                                  ).format("MMM Do YYYY h:mm:ss a")}</td>
                            </tr>
                            }
                            {selectedPaymentRequest?.id && (
                              <tr>
                                <th>Payment intents</th>
                                <td>
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={buildStripeDashboardURL(`/search?query=${encodeURIComponent(`${selectedPaymentRequest.id} is:payment_intent`)}`)}
                                  >
                                    View in Stripe
                                  </a>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>

                <div className="mb-4">
                  {
                    selectedPaymentRequest?.status === PaymentRequestStatus.pending && <>
                    <h5 className="font-size-14 mb-1">Sharable payment link</h5>
                  <p className="card-title-desc mb-2">
                    Share this link via email or messaging app. The recipient
                    can click the link to pay:
                  </p>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={selectedPaymentRequest?.link}
                  >
                    {selectedPaymentRequest?.link}
                  </a>
                    </>
                  }                        
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {selectedPaymentRequest?.status === PaymentRequestStatus.pending && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setDeletePaymentRequestConfirmation(true)}
                >
                  Delete link
                </button>
              )}

              {selectedPaymentRequest?.status === PaymentRequestStatus.pending && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => copyLink(selectedPaymentRequest)}
                >
                  Copy payment link
                </button>
              )}
              {selectedPaymentRequest?.status !== "cancelled" &&
                deletePaymentRequestConfirmation && (
                  <SweetAlert
                    title="Confirm Deletion?"
                    warning
                    showCancel
                    // confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      setDeletePaymentRequestConfirmation(false);
                      handleArchiveOpenPaymentRequest();
                    }}
                    onCancel={() => setDeletePaymentRequestConfirmation(false)}
                  >
                    Please confirm you would like to delete this payment link
                  </SweetAlert>
                )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setSelectedPaymentRequest(undefined)}
              >
                Close
              </button>
            </div>
          </Modal>
        </Container>
    </React.Fragment>
  );
};

export default PaymentRequestsSupportComponent;
