import PriceDto from '../../../../api-dtos/types/price.dto'

export enum CashAdvanceStatus {
    PendingApplication = 'pendingApplication',
    AwaitingDecision = 'awaitingDecision',
    OffersAvailable = 'offersAvailable',
    AwaitingOfferAcceptance = 'awaitingOfferAcceptance',
    MoreInformationRequired = 'moreInformationRequired',
    InstantSignRequired = 'instantSignRequired',
    DocusignRequired = 'docusignRequired',
    OnTrack = 'onTrack',
    OffTrack = 'offTrack',
    Repaid = 'repaid',
    Declined = 'declined',
    AwaitingFunding = 'awaitingFunding',
}

export interface MerchantCashAdvanceDTO {
    appliedAmount?: PriceDto
    amountRepaid?: PriceDto
    amountRemaining?: PriceDto
    paidOffPercentage?: {
        value: number
        precision: number
    }
    status: CashAdvanceStatus
}

export interface GetMerchantcashAdvancesDTO {
    cashAdvances: MerchantCashAdvanceDTO[]
}

export type RepaymentActivityType =
    | 'cashAdvanceRepayment'
    | 'cashAdvanceRepaymentRefund'

export type RepaymentActivityStatus = 'success' | 'refund'

export interface RepaymentActivityOffset {
    id: string
    type: RepaymentActivityType
}

export interface RepaymentActivityItem {
    id: string
    type: RepaymentActivityType
    status: RepaymentActivityStatus
    title: string
    amount: PriceDto
    createdAt: Date
    paidAt: Date
}

export interface RepaymentActivityResponseDto {
    nextOffset: RepaymentActivityOffset | null
    items: RepaymentActivityItem[]
    pageSize: number
}