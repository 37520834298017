import { KYBStatus } from '../../../../FraudPrevention/kyb-threshold.type'
import React, { useState } from 'react'
import { Alert, Col, Input, Label, Row } from 'reactstrap'
import { useUpdateKYBStatusMutation } from '../../hooks/kyb-settings.hook'

type KYBStatusSelectorProps = {
    merchantId: string
    kybStatus: KYBStatus
    refetchFn: () => void
}

const KYBStatusSelector = (props: KYBStatusSelectorProps) => {
    const { merchantId, kybStatus, refetchFn } = props

    const mutation = useUpdateKYBStatusMutation(merchantId, refetchFn)

    const [status, setStatus] = useState<KYBStatus>(kybStatus)
    const [notes, setNotes] = useState<string>('')

    const kybChoices: Array<{ value: KYBStatus, label: string }> = [
        { value: 'approved', label: 'Approved' },
        { value: 'unrestrictedOther', label: 'Unrestricted Other' },
        { value: 'unrestrictedNew', label: 'Unrestricted New' },
        { value: 'restricted', label: 'Restricted' },
        { value: 'blocked', label: 'Blocked' },
    ]

    const handleSubmit = () => {
        mutation.mutate({
            kybStatus: status,
            note: notes !== '' ? notes : undefined,
        })

        setNotes('')
    }

    return (
        <div>
            <Row>
                <Label>Internal KYB Status</Label>
                <Col sm={12} lg={4}>
                    <select
                        className="form-select"
                        value={status}
                        disabled={mutation.isLoading}
                        onChange={(e) => {
                            setStatus(e.target.value as KYBStatus)
                        }}
                    >
                        {
                            kybChoices.map(o => (
                                <option value={o.value}>
                                    { o.label }
                                </option>
                            ))
                        }
                    </select>
                </Col>
                <Col sm={12} lg={6}>
                    <Input
                        type="text"
                        value={notes}
                        placeholder="Optional reason for change"
                        onChange={(e) => {
                            setNotes(e.target.value)
                        }}
                        disabled={mutation.isLoading || kybStatus === status}
                    />
                </Col>
                <Col sm={12} lg={2}>
                    <button
                        className="btn btn-primary py-0 pe-2 px-1 d-flex align-items-center justify-content-center"
                        onClick={handleSubmit}
                        disabled={mutation.isLoading || kybStatus === status}
                    >
                        {
                            mutation.isLoading
                                ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                : <i className="mdi mdi-content-save font-size-24"></i>
                        }
                        <span className="ms-1">Save</span>
                    </button>
                </Col>
            </Row>
            <Row className="mt-4">
                {
                    kybStatus === 'blocked' && (
                        <Col sm={12}>
                            <Alert color="danger">
                                Merchant is <b>blocked</b>, meaning that they do not have access to the main app functionality.
                                This means that the <b>settings and flags</b> do not currently apply.
                            </Alert>
                        </Col>
                    )
                }
                {
                    kybStatus === 'restricted' && (
                        <Col sm={12}>
                            <Alert color="warning">
                                Merchant is <b>restricted</b>, meaning that they triggered a fraud rule that indicates suspicious behaviour
                                and now have reduced limits and settings.
                                They should be reviewed as a priority, and the next fraud rule trigger will likely cause a block.
                            </Alert>
                        </Col>
                    )
                }
                {
                    kybStatus === 'approved' && (
                        <Col sm={12}>
                            <Alert color="success">
                                Merchant is <b>approved</b>, meaning that they are considered a trusted merchant and have generously
                                increased limits and settings.
                                In most cases, any fraud rule triggers will only create alerts.
                            </Alert>
                        </Col>
                    )
                }
                <Col sm={12}>
                    <Alert color="info">
                        To see the applicable limits and thresholds, or to configure them, go to the <b>Fraud Prevention</b> {'>'} <b>KYB Thresholds</b> tab.
                        <br/>
                        Updating KYB status will override the <b>settings and flags</b> that were previously set for this merchant to the defaults for the given status.
                    </Alert>
                </Col>
            </Row>
        </div>
    )
}

export default KYBStatusSelector