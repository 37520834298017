import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//redux
import { useDispatch } from "react-redux";

import {
    Container,
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Button,
    Label,
    Input
} from "reactstrap";
import CountryAPIResponse from "../../api-dtos/country/countries-response.dto.interface";
import ExternalAccountDto from "../../api-dtos/external-account/external-account.dto.interface";
import UserProfileResponse from "../../api-dtos/profile/user-profile-response.dto.interface";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createExternalAccount, fetchCountries, getUserProfile } from "../../helpers/lopay_api_helper";
import { fetchProfile } from "../../store/actions";

interface AddPayoutPageProps {
    history: any
}

const AddPayoutPage  = ({ history }: AddPayoutPageProps) => {

    const dispatch = useDispatch();

    const [countries, setCountries] = useState<CountryAPIResponse[]>()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    // New bank account form state:
    const [country, setCountry] = useState<CountryAPIResponse>()
    const [accountName, setAccountName] = useState<string>("")
    const [accountNumber, setAccountNumber] = useState<string>("")
    const [sortCode, setSortCode] = useState<string>("")
    const [accountHolderName, setAccountHolderName] = useState<string>("")
    const [accountType, setAccountType] = useState<string>("")

    const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())
    const merchant = userProfile?.user.merchants[0]

    const handleFetchCountries = async () => {

        const countries = await fetchCountries()
        setCountries(countries)
        if (countries.length) {
            setCountry(countries[0])
        }
    }

    useEffect(() => {
        handleFetchCountries()
        dispatch(fetchProfile())
        // eslint-disable-next-line
    }, [])

    if (!merchant) {
        return <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Your profile</title>
                </MetaTags>
                <Container fluid>
                    <Alert color="danger">
                        Sorry there was a problem loading your profile. Please refresh to try again.
                    </Alert>
                </Container>
            </div>
        </React.Fragment>
    }

    const handleSaveBankAccount = async () => {

        if (!country) {
            return
        }

        const newAccount: ExternalAccountDto = {
            name: accountName,
            type: 'bank_account',
            country: country.code,
            currencyCode: country.defaultCurrency.code,
            accountHolderName: accountHolderName,
            accountHolderType: (accountType as "individual" | "company"),
            isDefaultForCurrency: true,
            routingNumber: sortCode,           
            accountNumber: accountNumber,
        }

        console.log('creating external account:', newAccount)

        setIsLoading(true)
        try {
            const externalAccount = await createExternalAccount(
                merchant?.id,
                newAccount,
            )
    
            console.log('external account:', externalAccount)

            setIsLoading(false)
    
            history.push("/external-accounts");
        } catch (e) {
            setIsLoading(false)
            alert(e)
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Add payout information</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Setup" breadcrumbItem="Add a bank account" />

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Payout information</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Please link a bank account which will receive your funds
                                    </CardSubtitle>
                                </CardHeader>
                                <CardBody>

                                    <Col lg={6}>
                                        <div>

                                            <div className="mb-3">
                                                <Label className="form-Label">Country</Label>
                                                <select
                                                    className="form-select"
                                                    onChange={e => countries && setCountry(countries[parseInt(e.target.value)])}
                                                >
                                                    {countries && countries.map(
                                                        (country, i) => <option
                                                            key={country.code}
                                                            value={i}>{country.flag} {country.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="example-text-input" className="form-Label">Bank account name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={accountName}
                                                    onChange={e => setAccountName(e.target.value)}
                                                    placeholder="E.G `Business account`"
                                                    id="example-text-input" />
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="example-text-input" className="form-Label">Bank account number</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={accountNumber}
                                                    onChange={e => setAccountNumber(e.target.value)}
                                                    placeholder="12345678"
                                                    id="example-text-input" />
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="example-text-input" className="form-Label">Sort code or routing number</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={sortCode}
                                                    onChange={e => setSortCode(e.target.value)}
                                                    placeholder="123456"
                                                    id="example-text-input" />
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="example-text-input" className="form-Label">Account holder name</Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    value={accountHolderName}
                                                    onChange={e => setAccountHolderName(e.target.value)}
                                                    placeholder="Joe Blogs or Company name"
                                                    id="example-text-input" />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-Label">Bank account type</Label>
                                                <select
                                                    className="form-select"
                                                    onChange={e => setAccountType(e.target.value)}
                                                >
                                                    <option>Select</option>
                                                    <option value='company'>Business</option>
                                                    <option value='individual'>Individual</option>
                                                </select>
                                            </div>

                                            <div className="d-flex flex-wrap gap-2">
                                                <Button type="button" color="primary" onClick={() => handleSaveBankAccount()}>
                                                    { isLoading ? <>Loading...</> : <>Add bank account</>}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>


                </div>
            </div>
        </React.Fragment>
    );
};

export default AddPayoutPage;
