import GoogleMapReact from 'google-map-react'
import React from 'react'
import { Badge } from 'reactstrap'
import { usePayments } from 'src/pages/Merchant/support-merchant-payments/support-merchant-payments.hook'

type IComponentProps = {
    isActive: boolean
    merchantId: string
}

const PaymentMap: React.FC<IComponentProps> = ({ isActive, merchantId }) => {
    const paymentResults = usePayments({
        isActive,
        merchantId,
        pageSize: 25,
    })

    const payments = React.useMemo(
        () =>
            (paymentResults.data?.pages ?? []).flatMap(
                (result) => result.transactions
            ),
        [paymentResults.data]
    )

    const centerPaymentMap = (): { lat: number; lng: number } => {
        const geoPayments = payments.filter((t) => Boolean(t.location))
        if (geoPayments?.length) {
            const payment = payments.filter((t) => Boolean(t.location))[0]

            return {
                lat: payment?.location?.lat || 0,
                lng: payment?.location?.lng || 0,
            }
        } else {
            return {
                lat: 0,
                lng: 0,
            }
        }
    }

    return (
        <div
            id="gmaps-markers"
            className="gmaps"
            style={{
                position: 'relative',
            }}
        >
            {payments.filter((t) => Boolean(t.location)).length && (
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyBC3mxVrhHJ1_kHol05VliqIgXtglDGnVM',
                    }}
                    zoom={12}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    center={centerPaymentMap()}
                >
                    {payments
                        ?.filter((t) => Boolean(t.location))
                        .map((t) => {
                            return (
                                <Badge
                                    style={{
                                        width: '36px',
                                        height: '46px',
                                        marginLeft: '-18px',
                                        marginTop: '-23px',
                                    }}
                                    lat={t.location?.lat || 0}
                                    lng={t.location?.lng || 0}
                                    text="Terminal Payment"
                                >
                                    <h2>💰</h2>
                                </Badge>
                            )
                        })}
                </GoogleMapReact>
            )}
        </div>
    )
}

export default PaymentMap
