/* eslint-disable jsx-a11y/anchor-is-valid */
import MetaTags from "react-meta-tags";
import React from "react";
import styled from 'styled-components'
import { Row, Col, Alert, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { ReactComponent as XeroSignInButtonImage } from 'src/assets/images/xero/signin-xero-blue.svg'
import logo from "../../assets/images/logo-text.png";

import { loginUser, sendMagicLink } from "../../store/actions";

interface LoginProps {
  history: object;
}

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error, otpSent } = useSelector((state: any) => ({
    error: state.login.error,
    otpSent: state.login.otpSent,
  }));

  const queryParams = new URLSearchParams(window.location.search)
  const userId = queryParams.get("id")
  const otp = queryParams.get("otp")

  const handleXeroLogin = async () => {
    const API_URL = process.env.REACT_APP_API_HOST
    const consentUrl = `${API_URL}merchant/x/xero/auth-signup`
    window.location.href = consentUrl
  };

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: { otp: string }) => {

    console.log('SUBMITTING FORM WITH VALUES: ', values, otpSent || userId)

    dispatch(loginUser(otpSent || userId, values.otp, history));
  };

  const handleSendMagicLink = (event: any, values: { email: string }) => {
    dispatch(sendMagicLink(values));
  };

  const defaultValues = {
    otp,
  }

  if (userId && otp) {
    handleValidSubmit(undefined, {
      otp,
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Lopay</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="35" />{" "}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to Lopay
                        </p>
                      </div>
                      <AvForm
                        className="custom-form mt-4 pt-2"
                        model={defaultValues}
                        onValidSubmit={(e: any, v: any) => {
                          otpSent
                            ? handleValidSubmit(e, v)
                            : handleSendMagicLink(e, v);
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        {otpSent ? <div className="mb-3">
                          <Alert color="info">
                            A code has been emailed to you
                          </Alert>
                          <AvField
                            name="otp"
                            label="Enter Code"
                            value=""
                            className="form-control"
                            placeholder="Enter code"
                            type="text"
                            required
                          />
                        </div> : <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>}

                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {otpSent ? "Verify code" : "Send magic link"}
                          </button>
                        </div>
                      </AvForm>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Don't have an account ?{" "}
                          <Link
                            to="/register"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            Signup now{" "}
                          </Link>{" "}
                        </p>
                      </div>

                      <div className="mt-5 text-center">
                      <CenterContainer>
                          <a
                            href="#"
                            onClick={() => {
                              handleXeroLogin()
                            }}
                          >
                            <XeroSignInButtonImage />
                          </a>
                        </CenterContainer>
                      </div>


                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} lopay . Made with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
