import { useQuery } from 'react-query'

import { getMerchantNotes } from './merchant-notes.api'

export function useGetMerchantNotes({
    merchantId,
}: {
    merchantId: string,
}) {
    return useQuery({
        queryKey: ['getMerchantNotes', { merchantId }],
        queryFn: () => getMerchantNotes({ merchantId })
    })
}