import moment from 'moment'
import TransactionApiDto, { CustomerDataField } from '../../api-dtos/transaction/transaction.dto.interface'
import { priceFormat } from '../utils'
import CardBrand from '../../api-dtos/types/card-brand.type'
import Country from '../../api-dtos/types/country.type'

/**
 * Any price related data is formatted into £XX.XX format and hence stored as
 * a string.
 */
export type FormattedPayment = {
    id: string
    key: string
    total: string
    description: string
    card: string
    cardBrand?: CardBrand
    cardCountry?: Country
    cardLastFour?: string
    fee: string
    netFee: string
    tip: string
    discount: string
    net: string
    schedule?: string
    createdAt: string
    lat?: number
    long?: number
    stripeTerminalDetailsURL?: string
    canRefund: boolean
    associatedRefunds?: TransactionApiDto[]
    providerId?: string | null
    customer?: CustomerDataField[] | null
    hardwareName?: 's700' | 'wisepad_three'
}

const formatSchedule = (schedule?: string) => {
    const scheduleNameMap = new Map<string,string>([
        ['nextDay', 'Next day (0.99%)'],
        ['weekly', 'Weekly (0.79%)'],
        ['instant', 'Instant (1.79%)'],
        ['cardIssuing', 'Rewards (0.99%)']
    ])

    if (schedule && scheduleNameMap.has(schedule)) {
        return scheduleNameMap.get(schedule)
    }

    return ''
}

const canRefund = (payment: TransactionApiDto) => {
    return payment.tenderType.name !== 'BANK_TRANSFER' && payment.total.units > 0
}

/**
 * Converts raw transaction data returned from the Lopay API into a format that can be directly displayed to the
 * user.
 *
 * @param payment - Payment transaction data returned from the Lopay API.
 */
const formatPayment = (payment: TransactionApiDto): FormattedPayment => ({
    id: payment.id,
    key: payment.id,
    total: priceFormat(payment.total),
    description: payment.description,
    card: payment.card
        ? `${payment.card?.type} ${payment.card?.country} ${payment.card?.lastFour}`
        : payment.type,
    cardBrand: payment.card?.type,
    cardCountry: payment.card?.country,
    cardLastFour: payment.card?.lastFour,
    fee: priceFormat(payment.fee),
    netFee: payment.processingFeeDiscounts?.length
        ? priceFormat({
            units: payment.fee.units - payment.processingFeeDiscounts[0].feeDiscounted.units,
            currencyCode: payment.fee.currencyCode,
        })
        : priceFormat(payment.fee),
    tip: priceFormat(payment.tip),
    discount: payment.processingFeeDiscounts?.length
        ? priceFormat(
            payment.processingFeeDiscounts[0].feeDiscounted
        )
        : '--',
    net: priceFormat(payment.net),
    schedule: formatSchedule(payment.schedule),
    createdAt: moment(payment.createdAt)
        .local()
        .format('DD-MM-YY HH:mm [UTC]Z'),
    stripeTerminalDetailsURL: payment.target.url,
    canRefund: canRefund(payment),
    associatedRefunds: payment.associatedRefunds,
    providerId: payment.providerId,
    customer: payment.customer?.data,
    hardwareName: payment.target.hardwareName
})

export default formatPayment
