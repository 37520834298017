import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import {
    bulkUpdateMerchantGrantAccessOnWaitingList,
    getMerchantsOnWaitingList,
} from './waiting-list-merchants.api'

export function useWaitingListMerchants({
    waitingListId,
    pageSize,
    filters,
}: {
    waitingListId: string
    pageSize: number
    filters: {
        grantAccessAt?: Date | null
        search?: string
    }
}) {
    return useInfiniteQuery(
        ['waitingListMerchants', { waitingListId, pageSize, filters }],
        getMerchantsOnWaitingList({ waitingListId, pageSize, filters }),
        {
            getNextPageParam: (lastPage) =>
                lastPage.data[lastPage.data.length - 1]?.id,
        }
    )
}

export function useBulkUpdateGrantAccessOnMutation({
    waitingListId,
    merchantIds,
    grantAccessAt,
    setSelectedMerchantIds,
}: {
    waitingListId: string
    merchantIds: string[]
    grantAccessAt: Date | null
    setSelectedMerchantIds: (merchantIds: string[]) => void
}) {
    const queryClient = useQueryClient()

    return useMutation(
        () => {
            return bulkUpdateMerchantGrantAccessOnWaitingList(waitingListId, {
                merchantIds,
                grantAccessAt,
            })
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries('waitingListMerchants')
                setSelectedMerchantIds([])
            },
        }
    )
}
