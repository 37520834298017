import { LoginTypes } from "./actionTypes";

// Send magic link (secret) to email

export const sendMagicLink = (payload: { email: string}) => {
  return {
    type: LoginTypes.SEND_MAGIC_LINK,
    payload: { email: payload.email, method: 'code' },
  };
};

export const sendMagicLinkSuccess = (otpSent: string) => {
  return {
    type: LoginTypes.SEND_MAGIC_LINK_SUCCESS,
    payload: otpSent,
  };
};

// Login with magic link secret (sent to email)
export const loginUser = (userId: string, otp: string, history: any) => {
  return {
    type: LoginTypes.LOGIN_USER,
    payload: { userId, otp, history },
  };
};

export const loginSuccess = (user: any) => {
  return {
    type: LoginTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history: any) => {
  return {
    type: LoginTypes.LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = (response: any) => {
  return {
    type: LoginTypes.LOGOUT_USER_SUCCESS,
    payload: response,
  };
};

export const apiError = (error: any) => {
  return {
    type: LoginTypes.API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data: any, history: any, type: any) => {
  return {
    type: LoginTypes.SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
