import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SupportMerchantApiDto from "src/api-dtos/support/merchant/support-merchant-api.dto.interface";
import SupportUserApiDto from "src/store/types/user.type";
import { SupportMerchantHardwareTable } from "./components/support-merchant-hardware-table.component";
import { SupportMerchantHardwareCustomPricing } from "./components/support-merchant-hardware-custom-pricing.component";
import SupportNewTerminalOrderRequest from "../new-terminal-order-request";
import { supportCreateMerchantTerminalOrder } from "src/helpers/lopay_api_helper";
import { Link } from "react-router-dom";

type IComponentProps = {
  merchant: SupportMerchantApiDto;
  accountOwner: SupportUserApiDto | undefined;
}

const SupportMerchantHardware: React.FC<IComponentProps> = ({
  merchant,
  accountOwner
}) => {
  const [activeTab, setActiveTab] = useState('orders');
  const [ordering, setOrdering] = useState(false);
  const [showNewOrderModal, setShowNewOrderModal] = useState(false);
  const [newTerminalOrder, setNewTerminalOrder] = useState<SupportNewTerminalOrderRequest | null>(null);
  const [orderTerminalError, setOrderTerminalError] = useState('');

   const supplierOptions = [
    {
      value: "stripe",
      label: "Stripe",
    },
    {
      value: "mintsoft",
      label: "TuPack (MintSoft)",
    },
  ];

  const toggleShowNewOrderModal = () => {
    setOrdering(false);
    setOrderTerminalError('')
    setNewTerminalOrder({
      deliveryAddress: {
        phone: merchant.phone,
        line1: merchant.address.street,
        line2: merchant.address.line2,
        town: merchant.address.town,
        postcode: merchant.address.postcode,
        deliveryNotes: '',
      },
      contact: {
        firstName: accountOwner?.firstName || '',
        lastName: accountOwner?.lastName || '',
        email: accountOwner?.email || '',
      },
      supplier: 'mintsoft',
    });
    setShowNewOrderModal(!showNewOrderModal)
  }
  
  const onSubmitOrderTerminal = async () => {
    setOrdering(true);
    setOrderTerminalError('');
    try {
      await supportCreateMerchantTerminalOrder(
        merchant.id,
        newTerminalOrder!,
      )
      toggleShowNewOrderModal();
    } catch (e) {
      setOrdering(false);
      setOrderTerminalError(
        (e as string) || "Could not place terminal order"
      )
    }
  }

  function toggleHeader(arg0: string) {
    setActiveTab(arg0);
  }

  return (
    <>
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1" style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
          <span>Hardware</span>
          <Link
              onClick={(e) => {
                  e.preventDefault();
                  toggleShowNewOrderModal();
              }}
              to="#"
              className="btn btn-sm btn-primary"
          >
              New Hardware Order
          </Link>
        </h4>
        <div className="flex-shrink-0">
          <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === 'orders',
                })}
                onClick={() => {
                  toggleHeader('orders');
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Orders</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === 'custom-pricing',
                })}
                onClick={() => {
                  toggleHeader('custom-pricing');
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Custom Pricing</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </CardHeader>
      <CardBody>
        <TabContent className="text-muted" activeTab={activeTab}>
          <TabPane tabId="orders">
            <SupportMerchantHardwareTable merchantId={merchant.id} accountOwner={accountOwner}/>
          </TabPane>
          <TabPane tabId="custom-pricing">
            <SupportMerchantHardwareCustomPricing merchantId={merchant.id} />
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>

    <Modal isOpen={showNewOrderModal} toggle={toggleShowNewOrderModal}>
        <ModalHeader toggle={toggleShowNewOrderModal}>Order Hardware</ModalHeader>
        <ModalBody>

          <Card>
            <CardBody>
              <h5 className="card-title">
                <span>Recipient Details</span>
              </h5>
                <div className="mb-3">
                  <Label htmlFor="firstName-input" className="form-Label">
                    First Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.contact?.firstName}
                    placeholder="Joe"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                        },
                        contact: {
                          ...newTerminalOrder!.contact,
                          firstName: e.target.value,
                        }
                      })
                    }
                    id="firstName-input"
                  />

                  <br />

                  <Label htmlFor="lastName-input" className="form-Label">
                    Last Name
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.contact?.lastName}
                    placeholder="Bloggs"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                        },
                        contact: {
                          ...newTerminalOrder!.contact,
                          lastName: e.target.value,
                        }
                      })
                    }
                    id="lastName-input"
                  />

                  <br />

                  <Label htmlFor="email-input" className="form-Label">
                    Email
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.contact.email}
                    placeholder="joe.bloggs@email.com"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        contact: {
                          ...newTerminalOrder!.contact,
                          email: e.target.value,
                        }
                      })
                    }
                    id="email-input"
                  />

                  <br />

                  <Label htmlFor="phone-input" className="form-Label">
                    Phone Number
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.deliveryAddress!.phone}
                    placeholder="+447123456789"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                          phone: e.target.value,
                        }
                      })
                    }
                    id="phone-input"
                  />
                </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="card-title">
                <span>Recipient Address</span>
              </h5>
                <div className='mb-3'>
                  <Label htmlFor="street-input" className="form-Label">
                    Street
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.deliveryAddress?.line1}
                    placeholder="Apartment 202"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                          line1: e.target.value,
                        }
                      })
                    }
                    id="street-input"
                  />

                  <Label htmlFor="line2-input" className="form-Label">
                    Line 2
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.deliveryAddress?.line2}
                    placeholder="123 Fake Street"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                          line2: e.target.value,
                        }
                      })
                    }
                    id="line2-input"
                  />

                  <Label htmlFor="town-input" className="form-Label">
                    Town
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.deliveryAddress?.town}
                    placeholder="London"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                          town: e.target.value,
                        }
                      })
                    }
                    id="town-input"
                  />

                  <Label htmlFor="postcode-input" className="form-Label">
                    Postcode
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    value={newTerminalOrder?.deliveryAddress?.postcode}
                    placeholder="AB12 3CD"
                    onChange={(e) =>
                      setNewTerminalOrder({
                        ...newTerminalOrder!,
                        deliveryAddress: {
                          ...newTerminalOrder!.deliveryAddress!,
                          postcode: e.target.value,
                        }
                      })
                    }
                    id="postcode-input"
                  />
                </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h5 className="card-title">Supplier</h5>
              <select
                className="form-select"
                value={newTerminalOrder?.supplier}
                onChange={(e) => {
                  console.log("e.target:", e.target.value);
                  setNewTerminalOrder({
                    ...newTerminalOrder!,
                    supplier: e.target.value as "stripe" | "mintsoft"
                  });
                }}
              >
                {supplierOptions.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </CardBody>
          </Card>

          <strong className='text-danger'>{orderTerminalError}</strong>

        </ModalBody>
        <ModalFooter>
            <Button onClick={toggleShowNewOrderModal}>Cancel</Button>
            <Button color="primary" disabled={ordering} onClick={onSubmitOrderTerminal}>{ordering ? 'Ordering...' : 'Order'}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
};

export default SupportMerchantHardware;
