import styled from 'styled-components'

const TimelineVerticalSegment = styled.span`
    position: absolute;
    left: 1rem;
    top: 1rem;
    height: 100%;
    width: 0.125rem;
    background-color: rgb(229, 231, 235);
`

export default TimelineVerticalSegment