import moment from "moment";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Collapse, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import TerminalOrderDto from "src/api-dtos/terminal-order/terminal-order.dto.interface";
import { validateAgainstSchema } from "src/helpers/validation";
import SupportUserApiDto from "src/store/types/user.type";
import { z } from "zod";
import classnames from "classnames";
import { supportCreateMerchantTerminalOrder } from "src/helpers/lopay_api_helper";

interface SupportMerchantHardwareOrderModalProps {
    isOpen: boolean;
    toggle: (newOrder?: TerminalOrderDto) => void;
    merchantId: string;
    terminalOrder: TerminalOrderDto | null;
    accountOwner: SupportUserApiDto | undefined;
}

interface OrderForm {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    addressCity: string,
    addressPostcode: string,
    supplier: 'mintsoft' | 'stripe'
}

const ukPostcodeRegex = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][ABD-HJLNP-UW-Z]{2}$/i;
const schema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().min(11).max(13), //allow for 0xx... and +44xx...
    addressLine1: z.string().min(1),
    addressLine2: z.string().optional(),
    addressCity: z.string().min(1),
    addressPostcode: z.string().regex(ukPostcodeRegex),
    supplier: z.enum(['mintsoft', 'stripe']),
});

export const SupportMerchantHardwareOrderModal = ({isOpen, toggle, merchantId, terminalOrder, accountOwner}: SupportMerchantHardwareOrderModalProps) => {
    const initialOrderFormState: OrderForm = {
        firstName: accountOwner?.firstName || '',
        lastName: accountOwner?.lastName || '',
        email: accountOwner?.email || '',
        phone: accountOwner?.phone || '',
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressPostcode: '',
        supplier: 'mintsoft'
    }
    const [orderForm, setOrderForm] = useState<OrderForm>(initialOrderFormState)
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [erroredFields, setErroredFields] = useState<{field: string, error?: string}[]>([])
    const [copyTerminalOrderIdText, setCopyTerminalOrderIdText] = useState('Copy')
    const [merchantName, setMerchantName] = useState('');
    const [openTabs, setOpenTabs] = useState([1,2,3,4]);
    const [ordering, setOrdering] = useState(false);
    const [error, setError] = useState('');
    

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const field = event.target.name as keyof OrderForm;
        if (event.target.type === 'radio') {
            const supplier = field as 'mintsoft' | 'stripe';
            setOrderForm({
                ...orderForm,
                supplier,
            })
        } else {
            const value = event.target.value;
            const updatedOrderForm = {
                ...orderForm,
                [field]: value
            }
            setOrderForm(updatedOrderForm)
            if (submitAttempted){
                setErroredFields(validateAgainstSchema(schema, updatedOrderForm));
            }
        }
    }

    const onSubmit = async () => {
        setOrdering(true);
        setError('');
        setSubmitAttempted(true);
        setErroredFields(validateAgainstSchema(schema, orderForm));
        console.log({orderForm})
        console.log({erroredFields})
        try {
            const newOrder = await supportCreateMerchantTerminalOrder(
                merchantId,
                {
                    deliveryAddress: {
                        line1: orderForm.addressLine1,
                        line2: orderForm.addressLine2,
                        town: orderForm.addressCity,
                        postcode: orderForm.addressPostcode,
                        deliveryNotes: ''
                    },
                    contact: {
                        firstName: orderForm.firstName,
                        lastName: orderForm.lastName,
                        email: orderForm.email
                    },
                    supplier: orderForm.supplier,
                    originalTerminalOrderId: terminalOrder?.id || undefined
                }
            )
            handleToggle(newOrder);
        } catch (e: any) {
            setOrdering(false);
            setError(`Failed to order: ${e.message ? e.message : 'something went wrong'}`)
        }
    }

    const setExistingOrderForm = () => {
        const phone = terminalOrder?.merchant?.phone || orderForm.phone;
        const addressLine1 = terminalOrder?.delivery.address.street || '';
        const addressLine2 = terminalOrder?.delivery.address.line2 || '';
        const addressCity = terminalOrder?.delivery.address.town || '';
        const addressPostcode = terminalOrder?.delivery.address.postcode || '';

        const supplier = terminalOrder?.delivery.source !== 'mintsoft' && terminalOrder?.delivery.source !== 'stripe' ? 'mintsoft' : terminalOrder.delivery.source;
        setOrderForm({
            ...orderForm,
            phone,
            addressLine1,
            addressLine2,
            addressCity,
            addressPostcode,
            supplier
        })
    }

    const isFieldInvalid = (field: string) => {
        const invalidField = erroredFields.find(ef => ef.field === field);
        return !!invalidField
    }

    const getFieldError = (field: string) => {
        const invalidField = erroredFields.find(ef => ef.field === field);
        return invalidField?.error;
    }

    const copyTerminalOrderId = (id: string) => {
        navigator.clipboard.writeText(id).then(
        function () {
            setCopyTerminalOrderIdText("Copied 🎉");
        },
        function (err) {
            setCopyTerminalOrderIdText("Could not copy ID 😭");
        }
        );
    }

    const getMerchantName = () => {
        const fullName = `${accountOwner?.firstName} ${accountOwner?.lastName}`.trim();
        const name = terminalOrder?.merchant?.name.trim();
        if (fullName !== name) {
            return name;
        }
    }

    const handleTabToggle = (tabToToggle: number) => {
        const tabs = [...openTabs];
        if (tabs.includes(tabToToggle)) {
            setOpenTabs(tabs.filter(t => t !== tabToToggle));
        } else {
            setOpenTabs([...tabs, tabToToggle])
        }
    }

    const isTabOpen = (tabNum: number) => {
        return openTabs.includes(tabNum);
    }

    useEffect(()=> {
        if(isOpen) {
            setExistingOrderForm();
            setMerchantName(getMerchantName() || '');
        }
        // eslint-disable-next-line
    }, [isOpen])

    const handleToggle = (newOrder?: TerminalOrderDto) => {
        toggle(newOrder)
        setCopyTerminalOrderIdText('Copy')
        setError('');
        setOrdering(false);
        setOpenTabs([1,2,3,4])
        setOrderForm(initialOrderFormState)
        setSubmitAttempted(false)
        setErroredFields([])
    }

    

    return (
        <Modal isOpen={isOpen} toggle={() => handleToggle()}>
            <ModalHeader toggle={() => handleToggle()}>Reorder Hardware</ModalHeader>
            <Form>
                <ModalBody>
                    <div className="accordion accordion-flush"style={{marginBottom: '1em'}}>
                        { terminalOrder ?
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            { collapsed: !isTabOpen(1) }
                                        )}
                                        type="button"
                                        onClick={() => handleTabToggle(1)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Original Terminal Order
                                    </button>
                                </h2>

                                <Collapse isOpen={isTabOpen(1)} className="accordion-collapse">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled mb-0 text-muted">
                                            <li>
                                                {/* eslint-disable-next-line */}
                                                <b>ID:</b> {terminalOrder.id} <b><a href='#' onClick={() => copyTerminalOrderId(terminalOrder.id)}>[{copyTerminalOrderIdText}]</a></b>
                                            </li>
                                            <li>
                                                <b>Ordered At:</b> {moment(new Date(terminalOrder.createdAt)).format('Do MMM YYYY h:mm:ss a')}
                                            </li>
                                            <li>
                                                <b>Ordered With:</b> {terminalOrder.delivery.source}
                                            </li>
                                            <li>
                                                <b>Delivery Address:</b>
                                                <ul className="list-unstyled mb-0 text-muted font-size-13">
                                                    <li>{accountOwner?.firstName} {accountOwner?.lastName} {merchantName ? `(${merchantName})` : null}</li>
                                                    <li>{terminalOrder.delivery.address.street}</li>
                                                    { terminalOrder.delivery.address.line2 ?
                                                        <li>{terminalOrder.delivery.address.line2}</li>
                                                    : null }
                                                    <li>{terminalOrder.delivery.address.town}</li>
                                                    <li>{terminalOrder.delivery.address.postcode}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </Collapse>
                            </div>
                        : null }

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !isTabOpen(2) }
                                    )}
                                    type="button"
                                    onClick={() => handleTabToggle(2)}
                                    style={{ cursor: "pointer" }}
                                >
                                    Recipient Details
                                </button>
                            </h2>

                            <Collapse isOpen={isTabOpen(2)} className="accordion-collapse">
                                <div className="accordion-body">
                                    <Row style={{marginBottom: '0.5em'}}>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="firstName">
                                            First Name*
                                            </Label>
                                            <Input
                                            id="firstName"
                                            name="firstName"
                                            placeholder="John"
                                            type="text"
                                            invalid={isFieldInvalid('firstName')}
                                            value={orderForm.firstName}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('firstName')}</p>
                                        </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="lastName">
                                            Last Name*
                                            </Label>
                                            <Input
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Smith"
                                            type="text"
                                            invalid={isFieldInvalid('lastName')}
                                            value={orderForm.lastName}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('lastName')}</p>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="email">
                                            Email*
                                            </Label>
                                            <Input
                                            id="email"
                                            name="email"
                                            placeholder="john.smith@email.com"
                                            type="email"
                                            invalid={isFieldInvalid('email')}
                                            value={orderForm.email}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('email')}</p>
                                        </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="phone">
                                            Phone*
                                            </Label>
                                            <Input
                                            id="phone"
                                            name="phone"
                                            placeholder="07123456789"
                                            type="text"
                                            invalid={isFieldInvalid('phone')}
                                            value={orderForm.phone}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('phone')}</p>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !isTabOpen(3) }
                                    )}
                                    type="button"
                                    onClick={() => handleTabToggle(3)}
                                    style={{ cursor: "pointer" }}
                                >
                                    Delivery Address
                                </button>
                            </h2>

                            <Collapse isOpen={isTabOpen(3)} className="accordion-collapse">
                                <div className="accordion-body">
                                    <FormGroup style={{marginBottom: '0.5em'}}>
                                        <Label for="addressLine1">
                                        Address Line 1*
                                        </Label>
                                        <Input
                                        id="addressLine1"
                                        name="addressLine1"
                                        placeholder="24 Card Reader Avenue"
                                        invalid={isFieldInvalid('addressLine1')}
                                        value={orderForm.addressLine1}
                                        onChange={onInputChange}
                                        disabled={ordering}
                                        />
                                        <p className="small text-danger">{getFieldError('addressLine1')}</p>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: '0.5em'}}>
                                        <Label for="addressLine2">
                                        Address Line 2
                                        </Label>
                                        <Input
                                        id="addressLine2"
                                        name="addressLine2"
                                        placeholder="Apartment 20"
                                        invalid={isFieldInvalid('addressLine2')}
                                        value={orderForm.addressLine2}
                                        onChange={onInputChange}
                                        disabled={ordering}
                                        />
                                        <p className="small text-danger">{getFieldError('addressLine2')}</p>
                                    </FormGroup>
                                    <Row>
                                        <Col md={7}>
                                        <FormGroup>
                                            <Label for="addressCity">
                                            City*
                                            </Label>
                                            <Input
                                            id="addressCity"
                                            name="addressCity"
                                            placeholder="London"
                                            invalid={isFieldInvalid('addressCity')}
                                            value={orderForm.addressCity}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('addressCity')}</p>
                                        </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                        <FormGroup>
                                            <Label for="addressPostcode">
                                            Postcode*
                                            </Label>
                                            <Input
                                            id="addressPostcode"
                                            name="addressPostcode"
                                            placeholder="E1 6AN"
                                            invalid={isFieldInvalid('addressPostcode')}
                                            value={orderForm.addressPostcode}
                                            onChange={onInputChange}
                                            disabled={ordering}
                                            />
                                            <p className="small text-danger">{getFieldError('addressPostcode')}</p>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !isTabOpen(4) }
                                    )}
                                    type="button"
                                    onClick={() => handleTabToggle(4)}
                                    style={{ cursor: "pointer" }}
                                >
                                    Supplier
                                </button>
                            </h2>

                            <Collapse isOpen={isTabOpen(4)} className="accordion-collapse">
                                <div className="accordion-body">
                                    <FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                name="mintsoft"
                                                type="radio"
                                                checked={orderForm.supplier === 'mintsoft'}
                                                onChange={onInputChange}
                                                disabled={ordering}
                                                />
                                                Mintsoft
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                name="stripe"
                                                type="radio"
                                                checked={orderForm.supplier === 'stripe'}
                                                onChange={onInputChange}
                                                disabled={ordering}
                                                />
                                                Stripe
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={() => handleToggle()}>Cancel</Button>
                    <Button color="primary" disabled={erroredFields.length > 0} onClick={onSubmit}>Place order</Button>
                    {erroredFields.length ?
                        <p className="small text-danger">Order not placed. Please check the form for invalid inputs.</p>
                    : ''}
                    {error.length ? error : null}
                </ModalFooter>

            </Form>
        </Modal>
    )
}