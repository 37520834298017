import React, { useState } from 'react';

const CardDetailsVerification = ({
    updateOtp,
    otpError
}: {
    updateOtp: (value: string) => void,
    otpError: string
}) => {
    const [otp, setOtp] = useState('');

    const onOtpChange = (value: string) => {
        value = value.trim().toUpperCase().replace(/[^a-zA-Z0-9-]/g, '');
        
        if (value.length < 4 && value.includes('-')) {
            value = value.replace('-', '');
        }

        if (!value.includes('-') && value.length > 3) {
            value = value.slice(0, 3) + '-' + value.slice(3);
        }

        if (value.length > 4) {
            let [firstThree, lastThree] = value.split('-');
            if (lastThree.includes('-')){
                lastThree = lastThree.replace('-', '');
            }
            value = `${firstThree}-${lastThree}`;
        }

        setOtp(value);
        updateOtp(value);
    }


    return (
        <>
            <div className="card-details-info" style={{ marginBottom: '1rem' }}>
                <div>
                    <i className="fas fa-info"></i>
                </div>
                <p>We have sent you a code via email. Please enter it below.</p>
            </div>

            <div className="card-details-verification-input">
                <div className="input-title">
                    <p>Code*</p>
                    <span>{otpError}</span>
                </div>
                <input type="text" placeholder="Enter" maxLength={7} value={otp} onChange={(e) => onOtpChange(e.target.value)} />
            </div>
            <p className="card-details-asterix">*Mandatory fields</p>
        </>
    );
}

export default CardDetailsVerification;