import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import TimeAgo from 'javascript-time-ago'

import { Link } from "react-router-dom";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Table,
    Container,
    Badge,
    Modal,
    Label,
    Input,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    supportCreateMarketingDeepLink,
    supportListMarketingDeepLinks,
} from "../../helpers/lopay_api_helper";

// Register timezone 
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from "react-time-ago";
import MarketingCampaignDeepLinkDto from "src/api-dtos/marketing/marketing-deep-link.dto";

try {
    TimeAgo.addDefaultLocale(en)
} catch (e) {
    console.error(e)
}

interface MarketingDeepLinksPageProps {
    history: any
}

const MarketingDeepLinksPage = ({ history }: MarketingDeepLinksPageProps) => {

    const [deepLinks, setDeepLinks] = useState<MarketingCampaignDeepLinkDto[]>([])
    const [newDeepLink, setNewDeepLink] = useState<{
        name: string,
        urlSuffix: string,
        desktopFallback?: string,
    } | undefined>(undefined)

    const loadDeepLinks = async () => {
        const deepLinksResponse = await supportListMarketingDeepLinks()
        setDeepLinks(deepLinksResponse.deepLinks)
    }

    const createMarketingLink = async () => {
        if (!newDeepLink) {
            return
        }

        if (!newDeepLink.name.trim().length) {
            alert('Please enter a name')
            return
        }

        if (!newDeepLink.urlSuffix.trim().length) {
            alert('Please enter a URL format')
            return
        }

        if (newDeepLink && (newDeepLink.desktopFallback?.length ?? 0) > 0 && !newDeepLink.desktopFallback?.trim().startsWith('http')) {
            alert('Please enter a desktop fallback in a full URL format (EG with https://www.google.com)')
            return
        }

        const deepLinkResponse = await supportCreateMarketingDeepLink(newDeepLink)
        setDeepLinks((deepLinks) => [deepLinkResponse.deepLink, ...deepLinks])
        setNewDeepLink(undefined)
    }

    useEffect(() => {
        loadDeepLinks()
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Marketing Deep Links</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Marketing" breadcrumbItem="Marketing Deep links" />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <div className="row align-ite  ms-center">
                                        <div className="col-md-6">
                                            <p className="card-title-desc">
                                                Deep links which are used to attribute registrations to marketing campaigns, such as digital ads.
                                            </p>
                                        </div>

                                        <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setNewDeepLink({
                                name: '',
                                urlSuffix: '',
                              });
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i> New Deep Link
                          </Link>
                        </div>
                      </div>
                    </div>

                                    </div>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <div className="table-responsive">
                                        <Table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Link</th>
                                                    <th>Created by</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deepLinks.map((deepLink) => {
                                                        return (<tr onClick={() => {
                                                            // open payout details (included payments etc.)
                                                        }} key={deepLink.id}>
                                                            <td>
                                                                <ReactTimeAgo date={new Date(deepLink.createdAt)} locale="en-US" />
                                                            </td>
                                                            <td>
                                                                {deepLink.name}
                                                            </td>
                                                            <td>
                                                                <a href={deepLink.url} target="_blank" rel="noreferrer">{deepLink.url}</a>

                                                                {
                                                                    deepLink.desktopFallback && (
                                                                        <Badge className="me-2 bg-warning">
                                                                        Desktop fallback: {deepLink.desktopFallback}
                                                                    </Badge>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {deepLink.createdBy.firstName} {deepLink.createdBy.lastName}
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Modal
                        isOpen={!!newDeepLink}
                        toggle={() => {
                            setNewDeepLink(undefined)
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Create new deep link
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setNewDeepLink(undefined)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="mb-3">
                                <Label
                                    htmlFor="example-text-input"
                                    className="form-Label"
                                >
                                    Name
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Unique name for the link, such as the campaign name"
                                    onChange={(e) => {
                                        if (newDeepLink) {
                                            setNewDeepLink({
                                                ...newDeepLink,
                                                name: e.target.value,
                                            })
                                        }
                                    }}
                                    defaultValue=""
                                    id="example-text-input"
                                />
                            </div>

                            <div className="mb-3">
                                <Label
                                    htmlFor="example-text-input"
                                    className="form-Label"
                                >
                                    URL Suffix
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Suffix of the URL, EG: link.lopay.com/your-suffix"
                                    onChange={(e) => {
                                        if (newDeepLink) {
                                            setNewDeepLink({
                                                ...newDeepLink,
                                                urlSuffix: e.target.value,
                                            })
                                        }
                                    }}
                                    defaultValue=""
                                    id="example-text-input"
                                />
                            </div>

                            <div className="mb-3">
                                <Label
                                    htmlFor="example-text-input"
                                    className="form-Label"
                                >
                                    Desktop fallback URL (Optional)
                                </Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Desktop fallback URL"
                                    onChange={(e) => {
                                        if (newDeepLink) {
                                            setNewDeepLink({
                                                ...newDeepLink,
                                                desktopFallback: e.target.value,
                                            })
                                        }
                                    }}
                                    defaultValue=""
                                    id="example-text-input"
                                />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    setNewDeepLink(undefined)
                                }}
                                className="btn btn-secondary "
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button onClick={() => {
                                createMarketingLink()
                            }}
                                type="button" className="btn btn-primary" >
                                Create marketing link
                            </button>
                        </div>
                    </Modal>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default MarketingDeepLinksPage;
