import React, { useEffect } from 'react';
import { useElements } from '@stripe/react-stripe-js';
import { hideAndShowCopySuccess } from '../card-details.hook';

const CardDetailsNumber = ({ issuingCard, ephemeralKeySecret, nonce }: {issuingCard: string, ephemeralKeySecret: string, nonce?: string}) => {
    const elements = useElements();

    useEffect(() => {
        if (elements) {
            elements.create('issuingCardNumberDisplay', {
                issuingCard,
                ephemeralKeySecret,
                nonce,
                style: {
                    base: {
                        color: '#007AFF',
                        fontWeight: '300'
                    },
                },
            }).mount('#issuing-card-number-container');

            const cardNumberCopyElement = elements.create('issuingCardCopyButton', {
                toCopy: 'number'
            });
            cardNumberCopyElement.mount('#card-number-copy');

            cardNumberCopyElement.on('click', () => hideAndShowCopySuccess('card-number-copy', 'card-number-copy-success'));
        }
    }, [elements, issuingCard, ephemeralKeySecret, nonce]);

    return (
        <div className="card-details-row">
            <div className="card-details-row-text">
                <strong>Card number</strong>
                <div id="issuing-card-number-container"></div>
            </div>
            <div id="card-number-copy"></div>
            <div id="card-number-copy-success"></div>
        </div>
    )
};

export default CardDetailsNumber;
