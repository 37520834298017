import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

//redux
import { useDispatch } from "react-redux";

import {
    Container,
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Button,
} from "reactstrap";
import UserProfileResponse from "../../api-dtos/profile/user-profile-response.dto.interface";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLoggedInUser, getUserProfile } from "../../helpers/lopay_api_helper";
import { fetchProfile } from "../../store/actions";

const KycPage = () => {

    const dispatch = useDispatch();

    const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())

    const merchant = userProfile?.user.merchants[0]

    const handleFetchProfile = () => {

        const getAuthUser = getLoggedInUser();

        if (!getAuthUser) {
            console.log('user not signed in..')
            return false
        }

        // return false if user has finished KYC

        dispatch(fetchProfile())

        return true
    }

    const handleOpenStripeKYC = () => {


        window.open(merchant?.kycStatus.onboardingLink.url, "_blank")
    }

    useEffect(() => {
        const polling = setInterval(handleFetchProfile, 2000)        
        return () => {
           clearInterval(polling)
          }
    // eslint-disable-next-line
    }, [])

    if (!merchant) {
        return <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Your profile</title>
                </MetaTags>
                <Container fluid>
                    <Alert color="danger">
                        Sorry there was a problem loading your profile. Please refresh to try again.
                    </Alert>
                </Container>
            </div>
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Complete your profile</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Setup" breadcrumbItem="Complete your profile" />

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Information needed</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Please complete the below information
                                    </CardSubtitle>
                                </CardHeader>
                                <CardBody>
                                    <div className="d-flex flex-wrap gap-2">
                                        <Button type="button" color="primary" onClick={() => handleOpenStripeKYC()}>
                                            Complete profile...
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>


                    </Row>


                </div>
            </div>
        </React.Fragment>
    );
};

export default KycPage;
