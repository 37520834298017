import {RegisterTypes} from "./actionTypes"
import { UnregisteredUser } from "./types"

export const registerUser = (user : UnregisteredUser, history: any) => {
  return {
    type: RegisterTypes.REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = (user : any) => {
  return {
    type: RegisterTypes.REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = (user : any) => {
  return {
    type: RegisterTypes.REGISTER_USER_FAILED,
    payload: user,
  }
}
