import { GetRequest } from '../../../../helpers/lopay_api_helper'
import { GetMerchantcashAdvancesDTO, RepaymentActivityResponseDto } from '../dtos/merchant-cash-advance.dto'

export const getMerchantCashAdvances = async (merchantId: string) => {
    return GetRequest<GetMerchantcashAdvancesDTO>(`/support/merchant/${merchantId}/cash-advances`)
}

export function getRepaymentActivity({
    merchantId,
    pageSize,
}: {
    merchantId: string
    pageSize?: number
}) {
    return function ({
        pageParam,
    }: {
        pageParam?: string
    }): Promise<RepaymentActivityResponseDto> {
        return GetRequest(`support/merchant/${merchantId}/cash-advances/repayments`, {
            params: {
                ...(pageParam && { nextOffset: pageParam }),
                ...(pageSize && { pageSize }),
            },
        })
    }
}

