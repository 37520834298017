import React, { useState } from 'react'
import {
    Badge,
    ButtonDropdown,
    Card,
    CardHeader,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap'

import SupportUserApiDto from 'src/api-dtos/support/user/support-user-api.dto.interface'
import UserSessionModal from './components/user-sessions-modal/user-sessions-modal.component'

type IComponentProps = {
    merchantName: string
    users: SupportUserApiDto[]
}

const SupportMerchantTeam: React.FC<IComponentProps> = ({
    users,
    merchantName,
}) => {
    const isProduction = Boolean(process.env.REACT_APP_API_HOST?.startsWith('https://api.lopay.com'))

    const [selectedUser, setSelectedUser] = React.useState<
        SupportUserApiDto | undefined
    >(undefined)

    const [isUserDropdownActive, setIsUserDropdownActive] = useState(
        users.reduce<Record<string,boolean>>(
            (hash, u) => ({ ...hash, [u.id]: false }),
            {}
        )
    )

    const closeModal = React.useCallback(() => {
        setSelectedUser(undefined)
    }, [setSelectedUser])

    return (
        <Card>
            <CardHeader>
                <CardTitle className="h4">Team Members</CardTitle>
                <p className="card-title-desc">
                    These team members belong to {merchantName}
                </p>
            </CardHeader>

            <ul className="list-group list-group-flush">
                {users.map((user) => {
                    return (
                        <li key={user.id} className="list-group-item">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    {user.firstName} {user.lastName} (
                                    {user.email})
                                    {user.phone && `(${user.phone})`}
                                    <div className="mt-1">
                                        <Badge className="me-2 bg-success">
                                            Verification code:{' '}
                                            {user.otp || 'Unknown'}
                                        </Badge>

                                        {user.isOwner && (
                                            <Badge className="me-2 bg-primary">
                                                Account owner
                                            </Badge>
                                        )}
                                        {user.isAdmin && (
                                            <Badge className="me-2 bg-primary">
                                                Admin
                                            </Badge>
                                        )}

                                        {user.permissions
                                            .permissionPaymentLink ? (
                                            <Badge className="me-2 bg-success">
                                                Create &amp; view payment links
                                            </Badge>
                                        ) : (
                                            <Badge className="me-2 bg-danger">
                                                Create &amp; view payment links
                                            </Badge>
                                        )}
                                        {user.permissions
                                            .permissionViewAllPaymentLinks ? (
                                            <Badge className="me-2 bg-success">
                                                View all payment links
                                            </Badge>
                                        ) : (
                                            <Badge className="me-2 bg-danger">
                                                View all payment links
                                            </Badge>
                                        )}
                                        {user.permissions
                                            .permissionRefundPayments ? (
                                            <Badge className="me-2 bg-success">
                                                Refund payments
                                            </Badge>
                                        ) : (
                                            <Badge className="me-2 bg-danger">
                                                Refund payments
                                            </Badge>
                                        )}
                                        {user.permissions
                                            .permissionViewAllPayments ? (
                                            <Badge className="me-2 bg-success">
                                                View all payment history
                                            </Badge>
                                        ) : (
                                            <Badge className="me-2 bg-danger">
                                                View all payment history
                                            </Badge>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <ButtonDropdown
                                        isOpen={isUserDropdownActive[user.id]}
                                        toggle={() => setIsUserDropdownActive(prevState => ({
                                            ...prevState,
                                            [user.id]: !isUserDropdownActive[user.id]
                                        }))}
                                    >
                                        <DropdownToggle
                                            caret
                                            color="primary"
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="mdi mdi-menu" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setSelectedUser(user)
                                                }}
                                            >
                                                View sessions
                                            </DropdownItem>
                                            {
                                                user.intercomId &&
                                                <DropdownItem
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        const appId = isProduction ? 'vxtjlrxw' : 'etulmunl'
                                                        const url = `https://app.intercom.com/a/apps/${appId}/users/${user.intercomId}`
                                                        window
                                                            .open(url, '_blank')
                                                            ?.focus()
                                                    }}
                                                >
                                                    Intercom page
                                                </DropdownItem>
                                            }
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>

            <UserSessionModal user={selectedUser} toggle={closeModal} />
        </Card>
    )
}

export default SupportMerchantTeam
