import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { ReactComponent as Checkbox } from 'src/assets/images/checkbox.svg'
import { ReactComponent as EllipsisHorizontal } from 'src/assets/images/ellipsis-horizontal.svg'
import { ReactComponent as XMark } from 'src/assets/images/x-mark.svg'
import { PayoutAPIDto } from '../../support-merchant-payouts.type'
import {
    TimelineContainer,
    TimelineItemContainer,
    TimelineItemContent,
    TimelineItemContentContainer,
    TimelineItemError,
    TimelineItemIcon,
    TimelineItemSubcontent,
    TimelineList,
    TimelineListItem,
    TimelineListItemContainer,
    TimelineVerticalSegment,
} from 'src/components/Timeline'

const StyledTitle = styled.h6`
    margin-bottom: 1rem;
`

type IComponentProps = {
    payout: PayoutAPIDto
}

const PayoutTimeline: React.FC<IComponentProps> = ({ payout }) => {
    return (
        <React.Fragment>
            <StyledTitle>Timeline</StyledTitle>
            <TimelineContainer>
                <TimelineList role="list">
                    <TimelineListItem>
                        <TimelineListItemContainer>
                            <TimelineVerticalSegment aria-hidden={true} />
                            <TimelineItemContainer>
                                <div>
                                    <TimelineItemIcon iconColor="green">
                                        <Checkbox />
                                    </TimelineItemIcon>
                                </div>
                                <TimelineItemContentContainer>
                                    <TimelineItemContent>
                                        Payout initiated
                                    </TimelineItemContent>
                                    <TimelineItemSubcontent>
                                        {moment(payout.createdAt)
                                            .local()
                                            .format('DD MMM, yyyy - HH:mm')}
                                    </TimelineItemSubcontent>
                                </TimelineItemContentContainer>
                            </TimelineItemContainer>
                        </TimelineListItemContainer>
                    </TimelineListItem>
                    {(payout.status === 'in_transit' ||
                        payout.status === 'paid') && (
                        <TimelineListItem>
                            <TimelineListItemContainer>
                                <TimelineVerticalSegment aria-hidden={true} />
                                <TimelineItemContainer>
                                    <div>
                                        <TimelineItemIcon iconColor="green">
                                            <Checkbox />
                                        </TimelineItemIcon>
                                    </div>
                                    <TimelineItemContentContainer>
                                        <TimelineItemContent>
                                            Payout in transit
                                        </TimelineItemContent>
                                        <TimelineItemSubcontent>
                                            {moment(payout.createdAt)
                                                .local()
                                                .format('DD MMM, yyyy - HH:mm')}
                                        </TimelineItemSubcontent>
                                    </TimelineItemContentContainer>
                                </TimelineItemContainer>
                            </TimelineListItemContainer>
                        </TimelineListItem>
                    )}
                    {payout.status === 'in_transit' && (
                        <TimelineListItem>
                            <TimelineListItemContainer>
                                <TimelineItemContainer>
                                    <div>
                                        <TimelineItemIcon iconColor="grey">
                                            <EllipsisHorizontal />
                                        </TimelineItemIcon>
                                    </div>
                                    <TimelineItemContentContainer>
                                        <TimelineItemContent>
                                            Pending payout expected to arrive at
                                            account ending in{' '}
                                            {payout.account.accountEnding}
                                        </TimelineItemContent>
                                        <TimelineItemSubcontent>
                                            {moment(payout.estArrivalDate)
                                                .local()
                                                .format('DD MMM, yyyy - HH:mm')}
                                        </TimelineItemSubcontent>
                                    </TimelineItemContentContainer>
                                </TimelineItemContainer>
                            </TimelineListItemContainer>
                        </TimelineListItem>
                    )}
                    {payout.status === 'paid' && (
                        <TimelineListItem>
                            <TimelineListItemContainer>
                                <TimelineItemContainer>
                                    <div>
                                        <TimelineItemIcon iconColor="green">
                                            <Checkbox />
                                        </TimelineItemIcon>
                                    </div>
                                    <TimelineItemContentContainer>
                                        <TimelineItemContent>
                                            Payout expected to have arrived at
                                            account ending in{' '}
                                            {payout.account.accountEnding}
                                        </TimelineItemContent>
                                        <TimelineItemSubcontent>
                                            {moment(
                                                payout.statusUpdatedAt ??
                                                    payout.createdAt
                                            )
                                                .local()
                                                .format('DD MMM, yyyy - HH:mm')}
                                        </TimelineItemSubcontent>
                                    </TimelineItemContentContainer>
                                </TimelineItemContainer>
                            </TimelineListItemContainer>
                        </TimelineListItem>
                    )}
                    {(payout.status === 'failed' ||
                        payout.status === 'canceled') && (
                        <TimelineListItem>
                            <TimelineListItemContainer>
                                <TimelineItemContainer>
                                    <div>
                                        <TimelineItemIcon iconColor="red">
                                            <XMark />
                                        </TimelineItemIcon>
                                    </div>
                                    <TimelineItemContentContainer>
                                        <TimelineItemContent>
                                            Payout has failed or has been
                                            cancelled
                                        </TimelineItemContent>
                                        <TimelineItemSubcontent>
                                            {moment(
                                                payout.statusUpdatedAt ??
                                                    payout.createdAt
                                            )
                                                .local()
                                                .format('DD MMM, yyyy - HH:mm')}
                                        </TimelineItemSubcontent>
                                        {payout.failure && (
                                            <>
                                                <TimelineItemError>
                                                    {`Error code: ${payout.failure.code}`}
                                                </TimelineItemError>
                                            </>
                                        )}
                                    </TimelineItemContentContainer>
                                </TimelineItemContainer>
                            </TimelineListItemContainer>
                        </TimelineListItem>
                    )}
                </TimelineList>
            </TimelineContainer>
        </React.Fragment>
    )
}

export default PayoutTimeline
