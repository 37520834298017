import React from 'react'
import { Modal, Spinner, Table } from 'reactstrap'
import styled from 'styled-components'

import SupportUserApiDto from 'src/api-dtos/support/user/support-user-api.dto.interface'
import {
    useDeactiveUserSessionMutation,
    useUserSessionsQuery,
} from '../../support-merchant-team.hook'
import moment from 'moment'

const StyledCenteredModal = styled.div`
    display: flex;
    justify-content: center;
`

type IComponentProps = {
    user: SupportUserApiDto | undefined
    toggle: () => void
}

const UserSessionModal: React.FC<IComponentProps> = ({ user, toggle }) => {
    const { data, isLoading, isError } = useUserSessionsQuery({
        userId: user?.id,
        pageSize: 500,
    })

    const { mutate, isLoading: isLoadingDeactivateSession } =
        useDeactiveUserSessionMutation({
            userId: user?.id!,
        })

    const sessions = React.useMemo(
        () => (data?.pages ?? []).flatMap((page) => page?.data!),
        [data]
    )

    const noUserSesions = () => (
        <React.Fragment>
            <div
                className="table-responsive"
                style={{
                    paddingBottom: 550,
                    marginBottom: -550,
                    msOverflowStyle: 'none',
                }}
            >
                <StyledCenteredModal>No user sessions</StyledCenteredModal>
            </div>
        </React.Fragment>
    )

    const userSessionsTable = () => (
        <Table responsive striped className="mb-0">
            <thead>
                <tr>
                    <th>Created</th>
                    <th>Device</th>
                    <th>App Version</th>
                    <th>Authorised by</th>
                    <th>IP address</th>
                    <th>Location</th>
                    <th>Last seen</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {sessions.map((session) => {
                    return (
                        <tr key={session.id}>
                            <td>
                                {moment(session.createdAt)
                                    .local()
                                    .format('DD-MM-YY HH:mm')}
                            </td>
                            <td>
                                {session.device ? (
                                    <div>
                                        <div>
                                            {session.device.manufacturer ??
                                                '---'}
                                        </div>
                                        <div>
                                            {session.device.model ?? '---'}
                                        </div>
                                    </div>
                                ) : (
                                    '---'
                                )}
                            </td>
                            <td>{session.device?.latestAppVersion ?? '---'}</td>
                            <td>{session.authorisedBy}</td>
                            <td>{session.ipAddress ?? '---'}</td>
                            <td>
                                {session.latitude && session.longitude ? (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.google.com/maps/@${session.latitude},${session.longitude},16z?entry=ttu`}
                                    >
                                        View
                                    </a>
                                ) : (
                                    '---'
                                )}
                            </td>
                            <td>
                                {session.lastSeen
                                    ? moment(session.lastSeen)
                                          .local()
                                          .format('DD-MM-YY HH:mm')
                                    : '---'}
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    disabled={isLoadingDeactivateSession}
                                    onClick={() =>
                                        mutate({ sessionId: session.id })
                                    }
                                >
                                    Deactivate
                                </button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )

    return (
        <Modal size="xl" isOpen={!!user} toggle={toggle}>
            <div className="modal-header">
                <div>
                    <h5 className="modal-title mt-0">
                        User Sessions for{' '}
                        {`${user?.firstName} ${user?.lastName} (${user?.email})`}
                    </h5>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        toggle()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {isLoading && (
                    <StyledCenteredModal>
                        <Spinner className="ms-2" color="primary" />
                    </StyledCenteredModal>
                )}
                {isError && (
                    <div>Error loading user sessions. Please try again.</div>
                )}
                {!isLoading &&
                    !isError &&
                    (sessions.length === 0
                        ? noUserSesions()
                        : userSessionsTable())}
            </div>
        </Modal>
    )
}

export default UserSessionModal
