import React from 'react'
import { Badge } from 'reactstrap'
import { isNil } from 'lodash'

type KYCBadgeProps = {
    blockMaintained?: boolean
    className?: string,
    disabledCharges?: boolean | null
    disabledReason?: string
    isUnderReview?: boolean
    messagePrefix?: string
}

/**
 * Component handling the display of the merchant KYC status pill.
 *
 * @param blockMaintained - The block maintained flag, indicating if certainty of bad actor.
 * @param className - Any additional CSS classes to apply to component.
 * @param disabledCharges - Whether the charges have been disabled (ban status).
 * @param disabledReason - The reason, if provided, for disabling charges.
 * @param isUnderReview - Whether the account is under review.
 * @param messagePrefix - An optional prefix to add to the pill content.
 * @constructor
 */
const KYCBadge = ({
    blockMaintained,
    className,
    disabledCharges,
    disabledReason,
    isUnderReview,
    messagePrefix,
}: KYCBadgeProps) => {
    const badgeWrapper = (classProperty: string, message: string) =>
        <Badge className={`me-2 ${classProperty} ${className}`}>
            {messagePrefix && `${messagePrefix} `}{ message }
        </Badge>

    if (isUnderReview) {
        return badgeWrapper('bg-warning', 'Under review')
    }

    const notReviewed = isNil(disabledCharges)
    if (notReviewed) {
        return badgeWrapper('bg-light', 'Not reviewed')
    }

    if (disabledCharges) {
        return (
            <>
                {
                    badgeWrapper(
                    'bg-danger',
                    blockMaintained ? 'Block maintained' : 'Rejected'
                    )
                }
                {
                    disabledReason &&
                    <p className="card-title-desc">{disabledReason}</p>
                }
            </>
        )
    } else {
        return badgeWrapper('bg-success', 'Approved')
    }
}

export default KYCBadge;