import { render } from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from './store/index'

const queryClient = new QueryClient()

render(
    <QueryClientProvider client={queryClient}>
        <Provider store={configureStore({})}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    document.getElementById('root')
)
