import { DayName } from "../payout/day-name.type"

export type WeeklyAnchor = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday'

export type MerchantAuditAction = 'schedule_updated' | 'vat_registration_updated' | 'account_type_updated'

export type InstantScheduledPayoutConfig = {
    type: 'instant',
}

export type DailyScheduledPayoutConfig = {
    type: 'daily',
}

export type WeeklyScheduledPayoutConfig = {
    type: 'weekly'
    weeklyAnchor: WeeklyAnchor
}

export type MonthlyScheduledPayoutConfig = {
    type: 'monthly'
    monthlyAnchor: number
}

export type ScheduledPayoutConfig =
    | InstantScheduledPayoutConfig
    | DailyScheduledPayoutConfig
    | WeeklyScheduledPayoutConfig
    | MonthlyScheduledPayoutConfig

export interface MerchantScheduleAuditDTO {
    id?: string
    associatedMerchantId: string
    action: 'schedule_updated'
    data: {
        previousState: {
            schedule: 'instant' | 't1' | 't2' | 'cardIssuing'
            weeklyAnchorDay?: DayName
            config?: ScheduledPayoutConfig | null
        }
        newState: {
            schedule: 'instant' | 't1' | 't2' | 'cardIssuing'
            weeklyAnchorDay?: DayName
            config?: ScheduledPayoutConfig | null
        }
    }
    note?: string
    createdAt: Date
}

export enum AccountType {
    Essential = 'essential',
    Standard = 'standard',
    Premium = 'premium',
}

export interface MerchantAccountTypeDTO {
    id?: string
    associatedMerchantId: string
    action: 'account_type_updated'
    data: {
        previousState: {
            accountType: AccountType | null | undefined
            paused: boolean
            config?: ScheduledPayoutConfig | null
        }
        newState: {
            accountType: AccountType | null | undefined
            paused: boolean
            config?: ScheduledPayoutConfig | null
        }
    }
    note?: string
    createdAt: Date
}

export interface MerchantVATAuditDTO {
    id?: string
    associatedMerchantId: string
    action: 'vat_registration_updated'
    data: {
        previousState: {
            isVATRegistered: boolean
            taxIdentifier: string | null
        }
        newState: {
            isVATRegistered: boolean
            taxIdentifier: string | null
        }
    }
    note?: string
    createdAt: Date
}

export type MerchantAuditLogDto = MerchantScheduleAuditDTO | MerchantVATAuditDTO | MerchantAccountTypeDTO
