import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import KYCBadge from '../../support-merchant-kyc/components/KYCBadge/KYCBadge'
import React from 'react'
import SupportMerchantApiDto from '../../../../api-dtos/support/merchant/support-merchant-api.dto.interface'
import MerchantKYCStatusApiDto from '../../../../api-dtos/support/merchant/kyc-status-api.dto.interface'
import KYBStatusPill from '../../support-merchant-kyb/components/kyb-status-pill/kyb-status-pill.component'

export type MerchantTab =
    | 'kyc'
    | 'kyb'
    | 'recentActivity'
    | 'onboarding'
    | 'profile'
    | 'schedules'
    | 'hardware'
    | 'payments'
    | 'paymentLinks'
    | 'rewards'
    | 'lopayCard'
    | 'payouts'
    | 'referrals'
    | 'charges'
    | 'workingCapital'
    | 'cashAdvances'
    | 'team'
    /** Tab names below here are disabled */
    | 'fees'
    | 'sales'
    | 'bankAccounts'
    | 'products'
    | 'openBankingVrpConsents'


type MerchantPageNavbarProps = {
    activeTab: MerchantTab
    toggleTab: (tab: MerchantTab) => void
    merchant: SupportMerchantApiDto,
    kycStatus?: MerchantKYCStatusApiDto
}

/**
 * Component handling the rending and display of the merchant page vertical navigation bar.
 *
 * @param activeTab - The tab that should be highlighted.
 * @param toggleTab - The function called when toggling/selecting a new tab.
 * @param merchant - The merchant whose page this navbar is on.
 * @param kycStatus - The KYC status of the merchant.
 */
const MerchantPageNavbar = ({
    activeTab,
    toggleTab,
    merchant,
    kycStatus,
}: MerchantPageNavbarProps) => {
    return (
        <Nav pills className="flex-column">
            {
                merchant.useFraudFramework
                ? <NavItem>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                                active: activeTab === 'kyb',
                            })}
                            onClick={() => { toggleTab('kyb') }}
                        >
                            KYB{' '}
                            <div className="float-end"><KYBStatusPill kybStatus={merchant.kybStatus} /></div>
                        </NavLink>
                    </NavItem>
                : <NavItem>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                                active: activeTab === 'kyc',
                            })}
                            onClick={() => { toggleTab('kyc') }}
                        >
                            KYC{' '}
                            <KYCBadge
                                blockMaintained={kycStatus?.blockMaintained}
                                className={'float-end'}
                                disabledCharges={merchant.kybDisabledCharges}
                                disabledReason={merchant.disabledReason}
                                isUnderReview={kycStatus?.isUnderReview}
                            />
                        </NavLink>
                    </NavItem>
            }
            <NavItem>
                <NavLink
                    className={classnames({
                        active: activeTab === 'recentActivity',
                    })}
                    onClick={() => { toggleTab('recentActivity') }}
                >
                    KYB Activity & Notes
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: activeTab === 'onboarding',
                    })}
                    onClick={() => {
                        toggleTab('onboarding')
                    }}>
                    Onboarding progress
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: activeTab === 'profile',
                    })}
                    onClick={() => { toggleTab('profile') }}
                >
                    Profile
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({
                        active: activeTab === 'schedules',
                    })}
                    onClick={() => { toggleTab('schedules') }}
                >
                    Manage Schedule & Audit Log
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'hardware' }}
                    className={classnames({
                        active: activeTab === 'hardware',
                    })}
                    onClick={() => { toggleTab('hardware') }}
                >
                    Hardware
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: activeTab === 'payments',
                    })}
                    onClick={() => { toggleTab('payments') }}
                >
                    Payments
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: activeTab === 'paymentLinks',
                    })}
                    onClick={() => { toggleTab('paymentLinks') }}
                >
                    Payment Links
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                        active: activeTab === 'rewards',
                    })}
                    onClick={() => { toggleTab('rewards') }}>
                    Rewards
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({
                        active: activeTab === 'lopayCard',
                    })}
                    onClick={() => { toggleTab('lopayCard') }}
                >
                    Lopay Card
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'payouts' }}
                    className={classnames({
                        active: activeTab === 'payouts',
                    })}
                    onClick={() => { toggleTab('payouts') }}
                >
                    Payouts
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'referrals' }}
                    className={classnames({
                        active: activeTab === 'referrals',
                    })}
                    onClick={() => { toggleTab('referrals') }}
                >
                    Referrals &amp; Discounts
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'charges' }}
                    className={classnames({
                        active: activeTab === 'charges',
                    })}
                    onClick={() => { toggleTab('charges') }}
                >
                    Disputes &amp; Extra Charges
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{
                        cursor: 'workingCapital',
                    }}
                    className={classnames({
                        active: activeTab === 'workingCapital',
                    })}
                    onClick={() => { toggleTab('workingCapital') }}
                >
                    Working Capital
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'cashAdvances' }}
                    className={classnames({
                        active: activeTab === 'cashAdvances',
                    })}
                    onClick={() => { toggleTab('cashAdvances') }}
                >
                    Cash Advances
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    style={{ cursor: 'team' }}
                    className={classnames({
                        active: activeTab === 'team',
                    })}
                    onClick={() => { toggleTab('team') }}
                >
                    Team
                </NavLink>
            </NavItem>
            {1 + 1 === 1 && (
                <>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'fees' }}
                            className={classnames({
                                active: activeTab === 'fees',
                            })}
                            onClick={() => { toggleTab('fees') }}
                        >
                            Fees
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'sales' }}
                            className={classnames({
                                active: activeTab === 'sales',
                            })}
                            onClick={() => { toggleTab('sales') }}
                        >
                            Sales
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'bankAccounts' }}
                            className={classnames({
                                active: activeTab === 'bankAccounts',
                            })}
                            onClick={() => { toggleTab('bankAccounts') }}
                        >
                            Bank Accounts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'products' }}
                            className={classnames({
                                active: activeTab === 'products',
                            })}
                            onClick={() => { toggleTab('products') }}
                        >
                            POS Products
                        </NavLink>
                    </NavItem>
                </>
            )}
            {/* Hide in production until ready */}
            {1 + 1 === 1 && (
                <React.Fragment>
                    <NavItem>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                                active: activeTab === 'openBankingVrpConsents',
                            })}
                            onClick={() => { toggleTab('openBankingVrpConsents') }}
                        >
                            VRP Consents
                        </NavLink>
                    </NavItem>
                </React.Fragment>
            )}
        </Nav>
    )
}

export default MerchantPageNavbar
