import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import regions from "../../../common/regions";

const RegionDropdown = () => {
  const [selectedRegion, setSelectedRegion] = useState<string>("");
  const [menu, setMenu] = useState<boolean>(false);

  useEffect(() => {
    const currentRegion: any = localStorage.getItem("REGION") || "region_eu";
    setSelectedRegion(currentRegion);
  }, []);

  const changeRegionAction = (lang: string) => {
    localStorage.setItem("REGION", lang);
    setSelectedRegion(lang);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <img
            src={get(regions, `${selectedRegion}.flag`)}
            alt="Change Region"
            height="16"
            className="me-1"
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(regions), key => (
            <DropdownItem
              key={key}
              onClick={() => changeRegionAction(key)}
              className={`notify-item ${
                selectedRegion === key ? "active" : "none"
              }`}
            >
              <img
                src={get(regions, `${key}.flag`)}
                alt="Skote"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(regions, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default RegionDropdown