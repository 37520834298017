import React from 'react'
import classNames from 'classnames'
import { Card, CardBody } from 'reactstrap'
import styled from 'styled-components'

const StyledLine = styled.li`
    margin-bottom: 10px;
`

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const ItemCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`

type IComponentProps = {
    title: string
    items: {
        title: string
        subtitle: string
        checked: boolean
    }[]
}

const AccountSetupSection: React.FC<IComponentProps> = ({ title, items }) => {
    const isCompleted = React.useMemo(
        () => items.reduce((acc, item) => acc && item.checked, true),
        [items]
    )

    const titleColour = classNames({
        'text-info': !isCompleted,
        'text-success': isCompleted,
    })

    const borderColour = classNames({
        'border-info': !isCompleted,
        'border-success': isCompleted,
    })

    return (
        <Card className={`border w-50 ${borderColour}`}>
            <div className={`card-header bg-transparent ${borderColour}`}>
                <h5 className={`my-0 ${titleColour}`}>{title}</h5>
            </div>
            <CardBody>
                <ol>
                    {items.map((item) => (
                        <StyledLine key={item.title}>
                            <ItemContainer>
                                <div>
                                    <div>{item.title}</div>
                                    <div className="text-primary">
                                        {item.subtitle}
                                    </div>
                                </div>
                                <ItemCheckboxContainer>
                                    {item.checked && (
                                        <i className="fas fa-check-circle text-success"></i>
                                    )}
                                </ItemCheckboxContainer>
                            </ItemContainer>
                        </StyledLine>
                    ))}
                </ol>
            </CardBody>
        </Card>
    )
}

export default AccountSetupSection
