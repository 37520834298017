import { useMutation } from 'react-query'

import MerchantKYCStatusApiDto from 'src/api-dtos/support/merchant/kyc-status-api.dto.interface'
import {
    updateAdditionalPayoutDelay,
    updateAllowForeignCardPayments,
    updateAllowForeignEcommercePayments, updateAllowTTPPayments,
    updateInstantPayoutsEnabled,
} from './support-merchant-kyb.api'

export function useUpdateInstantPayoutsEnabledMutation({
    merchantId,
    setKycStatus,
}: {
    merchantId: string
    setKycStatus: (
        value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>
    ) => void
}) {
    return useMutation(
        (instantPayoutsEnabled: boolean) => {
            return updateInstantPayoutsEnabled({
                merchantId,
                instantPayoutsEnabled,
            })
        },
        {
            onSuccess: async (_, instantPayoutsEnabled) => {
                setKycStatus((prev) =>
                    prev
                        ? {
                              ...prev,
                              instantPayoutsEnabled,
                          }
                        : undefined
                )
            },
        }
    )
}

export function useUpdateAllowForeignEcommercePaymentsMutation({
    merchantId,
    setKycStatus,
}: {
    merchantId: string
    setKycStatus: (
        value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>
    ) => void
}) {
    return useMutation(
        (allowForeignEcommercePayments: boolean) => {
            return updateAllowForeignEcommercePayments({
                merchantId,
                allowForeignEcommercePayments,
            })
        },
        {
            onSuccess: async (_, allowForeignEcommercePayments) => {
                setKycStatus((prev) =>
                    prev
                        ? {
                              ...prev,
                              allowForeignEcommercePayments,
                          }
                        : undefined
                )
            },
        }
    )
}

export function useUpdateAllowForeignCardPaymentsMutation({
    merchantId,
    setKycStatus,
}: {
    merchantId: string
    setKycStatus: (
        value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>
    ) => void
}) {
    return useMutation(
        (allowForeignCardPayments: boolean) => {
            return updateAllowForeignCardPayments({
                merchantId,
                allowForeignCardPayments,
            })
        },
        {
            onSuccess: async (_, allowForeignCardPayments) => {
                setKycStatus((prev) =>
                    prev
                        ? {
                              ...prev,
                              allowForeignCardPayments,
                          }
                        : undefined
                )
            },
        }
    )
}

export function useUpdateAllowTTPPaymentsMutation({
    merchantId,
    setKycStatus,
}: {
    merchantId: string
    setKycStatus: (
        value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>
    ) => void
}) {
    return useMutation(
        (allowTTPPayments: boolean) => {
            return updateAllowTTPPayments({
                merchantId,
                allowTTPPayments,
            })
        },
        {
            onSuccess: async (_, allowTTPPayments) => {
                setKycStatus((prev) =>
                    prev
                        ? {
                              ...prev,
                              allowTTPPayments,
                          }
                        : undefined
                )
            },
        }
    )
}

export function useUpdateAdditionalPayoutDelayMutation({
    merchantId,
    setKycStatus,
}: {
    merchantId: string
    setKycStatus: (
        value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>
    ) => void
}) {
    return useMutation(
        (additionalPayoutDelayEnabled: boolean) => {
            return updateAdditionalPayoutDelay({
                merchantId,
                additionalPayoutDelayEnabled,
            })
        },
        {
            onSuccess: async (_, additionalPayoutDelayEnabled) => {
                setKycStatus((prev) =>
                    prev
                        ? {
                              ...prev,
                              additionalPayoutDelayEnabled,
                          }
                        : undefined
                )
            },
        }
    )
}
