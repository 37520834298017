import {
    DeleteRequest,
    GetRequest,
    PostRequest,
    PutRequest,
} from 'src/helpers/lopay_api_helper'
import { HardwareDto } from './hardware.type'

export async function listHardware(): Promise<HardwareDto[]> {
    return GetRequest<HardwareDto[]>('support/hardware')
}

export async function updateHardware(
    id: string,
    hardware: Partial<HardwareDto>,
    updateExistingMerchants: boolean,
    overwriteDefaultTerminals: boolean
): Promise<HardwareDto> {
    return PutRequest<HardwareDto>(`support/hardware/${id}`, {
        hardware,
        updateExistingMerchants,
        overwriteDefaultTerminals,
    })
}

export async function createHardware(
    newHardware: Partial<HardwareDto>,
    newMerchantsOnly: boolean,
    overwriteDefaultTerminals: boolean,
): Promise<HardwareDto> {
    return PostRequest<HardwareDto>('support/hardware', {
        hardware: newHardware,
        newMerchantsOnly,
        overwriteDefaultTerminals
    })
}

export async function deleteHardware(
    id: string
): Promise<void> {
    await DeleteRequest(`support/hardware/${id}`);
}
