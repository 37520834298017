import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { authoriseVrpConsent } from '../../../helpers/lopay_api_helper'

interface IComponentProps {}

const ConsentStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
`;

const ResultMessage = styled.div`
    margin-top: 20px;
`

const OpenBankingObieRedirect: React.FC<IComponentProps> = () => {
    const { hash } = useLocation()
    const [consentApprovalSuccess, setConsentApprovalSuccess] = useState<boolean | undefined>()

    useEffect(() => {
        if (hash.length <= 1) {
            setConsentApprovalSuccess(false)
            return
        }

        const { code, id_token } = Object.fromEntries(
            new URLSearchParams(hash.substring(1)).entries(),
        )

        authoriseVrpConsent({
            consentIdToken: id_token,
            authorisationCode: code,
        }).then(() => {
            setConsentApprovalSuccess(true)
        }).catch(() => {
            setConsentApprovalSuccess(false)
        })
    }, [hash])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Open Banking VRP consent approval</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={"LoPay Support"} breadcrumbItem="Open Banking VRP consent approval" />
                    {consentApprovalSuccess && (
                        <ConsentStatusContainer>
                            <iframe
                                title="Success"
                                src="https://giphy.com/embed/Od0QRnzwRBYmDU3eEO"
                                width="250"
                                height="250"
                                frameBorder="0"
                                className="giphy-embed">
                            </iframe>
                            <ResultMessage>
                                VRP consent approved
                            </ResultMessage>
                        </ConsentStatusContainer>
                    )}
                    {consentApprovalSuccess === false && (
                        <ConsentStatusContainer>
                            <iframe
                                title="Failure"
                                src="https://giphy.com/embed/JtLrtaN4VPoKXJRKGB"
                                width="250"
                                height="250"
                                frameBorder="0"
                                className="giphy-embed">
                            </iframe>
                            <ResultMessage>
                                Unable to approve VRP consent. Please contact a backend developer.
                            </ResultMessage>
                        </ConsentStatusContainer>
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OpenBankingObieRedirect