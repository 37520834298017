import React from 'react'
import { Alert, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import {
    CustomerDataFieldType,
    PaymentFeeType,
} from '../../../../../api-dtos/transaction/transaction.dto.interface'
import { FormattedPayment } from '../../../../../helpers/transformers/paymentFormatter'
import SupportMerchantApiDto from '../../../../../api-dtos/support/merchant/support-merchant-api.dto.interface'
import { usePaymentFees } from '../../support-merchant-payments.hook'
import { priceFormat } from '../../../../../helpers/utils'

type PaymentFeesModalProps = {
    isOpen: boolean
    merchant: SupportMerchantApiDto
    payment?: FormattedPayment
    toggle: () => void
}

const feeTypeMap: Readonly<Record<PaymentFeeType, string>> = {
    processingFees: 'Processing fee',
    posFees: 'Point of Sale fee',
    subscriptionLinkFees: 'Subscription fee',
    xeroInvoicePaymentFees: 'Xero invoice fee',
    paymentLinkFees: 'Payment link fee',
    tapToPayFees: 'Tap to Pay fee',
}

const customerDetailTypeMap: Readonly<Record<CustomerDataFieldType, string>> = {
    name: 'Name',
    businessName: 'Business name',
    phoneNumber: 'Phone number',
    email: 'Email',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    addressTown: 'Town',
    addressState: 'State',
    addressPostCode: 'Post code',
}

/**
 * Toggleable popup modal that displays a fee breakdown for a given payment.
 *
 * @param isOpen - Indicates if the modal should be shown.
 * @param merchant - The merchant whose payment this is.
 * @param payment - The payment to break down the fees of.
 * @param toggle - Function that triggers the opening/closing of the modal.
 *
 * @constructor
 */
const PaymentFeesModal = ({
    isOpen,
    merchant,
    payment,
    toggle,
}: PaymentFeesModalProps) => {
    const paymentFeesColumns = [
        { dataField: 'type', text: 'Type' },
        { dataField: 'value', text: 'Amount' },
    ]

    const result = usePaymentFees({
        paymentId: payment?.id,
    })

    const hasExternalURLButtons = Boolean(
        payment?.stripeTerminalDetailsURL || payment?.providerId
    )

    if (result.isLoading) {
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Payment details</ModalHeader>
                <ModalBody>
                    <Row>
                        <p>
                            <b>Loading...</b>
                        </p>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    if (result.error) {
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Payment details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Alert color={'danger'}>
                            Something went wrong whilst loading payment fees.
                            Please refresh the page and try again.
                        </Alert>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    if (payment?.card === 'cash') {
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Payment details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Alert color={'info'}>
                                This is a cash payment, therefore there are no
                                fees.
                            </Alert>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    if (payment?.card !== 'cash' && !payment?.schedule) {
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Payment details</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Alert color={'info'}>
                                This is a refund, therefore there are no fees.
                            </Alert>
                        </Col>
                    </Row>
                    {payment?.providerId && (
                        <Row>
                            <Col>
                                <hr />
                                <h5>Related external links</h5>
                                {payment?.providerId && (
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={() => {
                                            const isProduction =
                                                process.env.REACT_APP_API_HOST?.startsWith(
                                                    'https://api.lopay.com'
                                                )
                                            const url = `https://dashboard.stripe.com/${
                                                isProduction ? '' : 'test/'
                                            }refunds/${payment.providerId}`
                                            window.open(url, '_blank')?.focus()
                                        }}
                                    >
                                        <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                        Original payment details
                                    </button>
                                )}
                            </Col>
                        </Row>
                    )}
                </ModalBody>
            </Modal>
        )
    }

    const { data: fees } = result

    const feeData =
        fees?.lineItems.map((fee) => ({
            type: feeTypeMap[fee.type],
            value: priceFormat(fee.value),
        })) ?? []

    const cardDetailsColumns = [
        { dataField: 'cardBrand', text: 'Brand' },
        { dataField: 'cardCountry', text: 'Country' },
        { dataField: 'cardLastFour', text: 'Last four' },
        { dataField: 'isInternational', text: 'International *' },
    ]

    const cardDetails = [
        {
            cardBrand: payment?.cardBrand?.toUpperCase(),
            cardCountry: payment?.cardCountry,
            isInternational:
                payment?.cardCountry !== merchant.country ? '✅' : '❌',
            cardLastFour: payment?.cardLastFour,
        },
    ]

    const customerDetailsColumns = [
        { dataField: 'type', text: 'Type' },
        { dataField: 'value', text: 'Value' },
    ]

    const customerDetails = (payment?.customer || []).map((c) => ({
        type: customerDetailTypeMap[c.type],
        value: c.value,
    }))

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Payment details</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <p>
                            <b>Date:</b> {payment?.createdAt || '--'}
                        </p>
                        <p>
                            <b>Description:</b> {payment?.description || '--'}
                        </p>
                        <p>
                            <b>Amount:</b> {payment?.total || '--'}
                        </p>
                        <p>
                            <b>Tip: </b> {payment?.tip}
                        </p>
                    </Col>
                    <Col>
                        <p>
                            <b>Schedule:</b> {payment?.schedule}
                        </p>
                        <p>
                            <b>Total fee:</b>{' '}
                            {fees ? priceFormat(fees.total) : '--'}
                        </p>
                        <p>
                            <b>Total discounts:</b> {payment?.discount || '--'}
                        </p>
                        <p>
                            <b>Net total fee:</b> {payment?.netFee || '--'}
                        </p>
                    </Col>
                </Row>
                {Boolean(customerDetails.length) && (
                    <Row>
                        <Col>
                            <hr />
                            <h5>Recorded customer details</h5>
                            <ToolkitProvider
                                keyField="cardDetails"
                                data={customerDetails}
                                columns={customerDetailsColumns}
                            >
                                {(toolkitProps) => (
                                    <BootstrapTable
                                        bordered
                                        striped={false}
                                        classes="table align-middle table-nowrap"
                                        headerWrapperClasses="thead-light"
                                        {...toolkitProps.baseProps}
                                    />
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                )}
                {hasExternalURLButtons && (
                    <Row>
                        <Col>
                            <hr />
                            <h5>Related external links</h5>
                            {payment?.stripeTerminalDetailsURL && (
                                <button
                                    type="button"
                                    className="btn btn-light btn-sm me-2"
                                    onClick={() => {
                                        window
                                            .open(
                                                payment?.stripeTerminalDetailsURL,
                                                '_blank'
                                            )
                                            ?.focus()
                                    }}
                                >
                                    <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                    Terminal reader details
                                </button>
                            )}
                            {payment?.providerId && (
                                <button
                                    type="button"
                                    className="btn btn-light btn-sm"
                                    onClick={() => {
                                        const isProduction =
                                            process.env.REACT_APP_API_HOST?.startsWith(
                                                'https://api.lopay.com'
                                            )
                                        const url = `https://dashboard.stripe.com/${
                                            isProduction ? '' : 'test/'
                                        }payments/${payment.providerId}`
                                        window.open(url, '_blank')?.focus()
                                    }}
                                >
                                    <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                    Payment details
                                </button>
                            )}
                        </Col>
                    </Row>
                )}
                {payment?.cardBrand && (
                    <Row>
                        <Col>
                            <hr />
                            <h5>Card details</h5>
                            <ToolkitProvider
                                keyField="cardDetails"
                                data={cardDetails}
                                columns={cardDetailsColumns}
                            >
                                {(toolkitProps) => (
                                    <BootstrapTable
                                        bordered
                                        striped={false}
                                        classes="table align-middle table-nowrap"
                                        headerWrapperClasses="thead-light"
                                        {...toolkitProps.baseProps}
                                    />
                                )}
                            </ToolkitProvider>
                            <div style={{ fontSize: 10 }}>
                                * International payments add an additional 1% to
                                the processing fee. E.g. taking an international
                                payment on a weekly schedule (0.79%) will change
                                the processing fee to 1.79%. This will be
                                automatically reflected in the "
                                <b>processing fee</b>" breakdown.
                            </div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <hr />
                        {!!feeData.length ? (
                            <>
                                <h5>Fees before discounts breakdown</h5>
                                <ToolkitProvider
                                    keyField="feeType"
                                    data={feeData}
                                    columns={paymentFeesColumns}
                                >
                                    {(toolkitProps) => (
                                        <BootstrapTable
                                            bordered
                                            striped={false}
                                            classes="table align-middle table-nowrap"
                                            headerWrapperClasses="thead-light"
                                            {...toolkitProps.baseProps}
                                        />
                                    )}
                                </ToolkitProvider>
                            </>
                        ) : (
                            <Alert color={'warning'}>
                                No fee breakdown could be retrieved for this
                                payment. <br />
                                <br />
                                Some older payments have not recorded their
                                pre-discount fees at the time of creation. They
                                may be retroactively added in the future.
                            </Alert>
                        )}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default PaymentFeesModal
