import { GetRequest } from 'src/helpers/lopay_api_helper'
import { GetRewardsResponseDto } from './support-merchant-rewards.type'

export function getRewards({
    userId,
}: {
    userId: string
}): Promise<GetRewardsResponseDto> {
    return GetRequest<GetRewardsResponseDto>(`support/users/${userId}/rewards`)
}
