import { GetRequest, PostRequest, PutRequest } from 'src/helpers/lopay_api_helper'
import {
    QuickBooksSyncingConfigDto,
    QuickbooksStatusResponse,
    UpdateQuickBooksSyncingConfig,
} from './quickbooks.type'

export function getQuickbooksStatus({
    merchantId,
    key,
}: {
    merchantId: string
    key: string
}): Promise<QuickbooksStatusResponse> {
    return GetRequest<QuickbooksStatusResponse>(
        `merchant/${merchantId}/quickbooks/status`,
        {
            headers: {
                'x-api-key': key,
            },
        }
    )
}

export function disconnectQuickbooks({
    merchantId,
    key,
}: {
    merchantId: string
    key: string
}): Promise<void> {
    return PostRequest<void>(
        `merchant/${merchantId}/quickbooks/disconnect`,
        {},
        {
            headers: {
                'x-api-key': key,
            },
        }
    )
}

export function getQuickBooksSyncConfig({
    merchantId,
    key,
    refreshAccountsList,
}: {
    merchantId: string
    key: string
    refreshAccountsList?: boolean
}): Promise<QuickBooksSyncingConfigDto> {
    return GetRequest<QuickBooksSyncingConfigDto>(
        `merchant/${merchantId}/quickbooks/sync-config`,
        {
            params: {
                refreshAccountsList,
            },
            headers: {
                'x-api-key': key,
            },
        }
    )
}

export function updateQuickBooksSyncConfig({
    merchantId,
    key,
    syncConfig,
}: {
    merchantId: string
    key: string
    syncConfig: UpdateQuickBooksSyncingConfig
}): Promise<void> {
    return PutRequest<void>(
        `merchant/${merchantId}/quickbooks/sync-config`,
        syncConfig,
        {
            headers: {
                'x-api-key': key,
            },
        }
    )
}
