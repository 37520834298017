import usFlag from "../assets/images/flags/us.jpg"
import ukFlag from "../assets/images/flags/uk.png"

const regions : Object = {
  region_us: {
    label: "United States",
    flag: usFlag,
  },
  region_eu: {
    label: "Europe",
    flag: ukFlag,
  },
}

export default regions
