/**
 *  interface for Login Type
*/
export enum ProfileTypes {
    FETCH_PROFILE = '@@profile/FETCH_PROFILE',
    FETCH_PROFILE_SUCCESS = '@@profile/FETCH_PROFILE_SUCCESS',
    
    UPDATE_EDIT_PROFILE = '@@profile/UPDATE_EDIT_PROFILE',
    UPDATE_PROFILE_SUCCESS = '@@profile/UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_ERROR = '@@profile/UPDATE_PROFILE_ERROR',
    UPDATE_RESET_PROFILE_FLAG = '@@profile/UPDATE_RESET_PROFILE_FLAG',
}
