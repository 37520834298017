import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Modal, ModalBody, ModalHeader, Table } from "reactstrap"
import TerminalOrderDto from 'src/api-dtos/terminal-order/terminal-order.dto.interface';
import { supportListMerchantTerminals } from 'src/helpers/lopay_api_helper';
import SupportMerchantHardwareStatusButton from './support-merchant-hardware-status.component';
import { Link } from 'react-router-dom';
import SupportUserApiDto from 'src/store/types/user.type';
import { SupportMerchantHardwareOrderModal } from './support-merchant-hardware-order-modal.component';

interface IComponentProps {
  merchantId: string
  accountOwner: SupportUserApiDto | undefined;
}

export const SupportMerchantHardwareTable: React.FC<IComponentProps> = ({
  merchantId,
  accountOwner
}) => {
  const [listIsLoading, setListIsLoading] = useState(true);
  const [terminalOrders, setTerminalOrders] = useState<TerminalOrderDto[]>([])
  const [selectedStatusDropdown, setSelectedStatusDropdown] = useState<{
    terminalOrderId: string | null
    statusType: 'delivery' | 'return' | 'issue'
  }>({
    terminalOrderId: null,
    statusType: 'delivery'
  })
  const [selectedTerminalOrder, setSelectedTerminalOrder] = useState<
    TerminalOrderDto | undefined
  >();
  const [orderModalTerminalOrder, setOrderModalTerminalOrder] = useState<TerminalOrderDto | null>(null)
  const [issueDetails, setIssueDetails] = useState('');
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);


  const loadOrders = async () => {
    if (!merchantId) return
    setListIsLoading(true);
    const { terminalOrders } = await supportListMerchantTerminals(
      merchantId
    );
    setTerminalOrders(terminalOrders);
    setListIsLoading(false);
  };

  const onClickReorder = (terminalOrder: TerminalOrderDto) => {
    setOrderModalTerminalOrder(terminalOrder);
    setIsOrderModalOpen(true)
  }

  const openTerminalOrderInStripe = (providerId: string) => {
    const url = `https://dashboard.stripe.com/terminal/hardware_orders/${providerId}`;
    window.open(url, "_blank")?.focus();
  };

  const openTerminalOrderInMintSoft = (providerId: string) => {
    const url = `https://om.mintsoft.co.uk/Order/Details/${providerId}`;
    window.open(url, "_blank")?.focus();
  };

  const handleOrderToggle = (newOrder?: TerminalOrderDto) => {
    setIsOrderModalOpen(!isOrderModalOpen)
    if (newOrder){
      setTerminalOrders([newOrder, ...terminalOrders])
    }
  }

  useEffect(() => {
    loadOrders();
    // eslint-disable-next-line
  }, []);

    return (
      <>
      <Table className="table table-striped mb-0">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Date Ordered</th>
            <th>Hardware Type</th>
            <th>Delivery Address</th>
            <th>Delivery Status</th>
            <th>Fault Status</th>
            <th>Return Status</th>
            <th>Reorder</th>
          </tr>
        </thead>
        <tbody>
          {listIsLoading && !terminalOrders.length ? 
            <tr>
              <td><strong>Loading...</strong></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          : 
            terminalOrders.map(terminalOrder => {
              const createdDate = new Date(terminalOrder.createdAt)
              const dateOrdered = moment(createdDate).format('Do MMM YYYY')
              const timeOrdered = moment(createdDate).format('h:mm:ss a')

              const deliveryAddress = {
                name: terminalOrder.merchant?.name,
                street: terminalOrder.delivery.address.street,
                line2: terminalOrder.delivery.address.line2,
                town: terminalOrder.delivery.address.town,
                postcode: terminalOrder.delivery.address.postcode,
              }
              
              return (
                <tr key={terminalOrder.id}>
                  <td style={{ width: 150, maxWidth: 150 }}>{terminalOrder.providerId ?? '-'}</td>
                  <td>
                    {dateOrdered}
                    <br />
                    {timeOrdered}
                    <br/>
                    {terminalOrder.originalTerminalOrderId ?
                      <Badge className="me-2 bg-info">Reordered</Badge>
                    : null}
                  </td>
                  <td>{terminalOrder.type}</td>
                  <td>
                      {deliveryAddress.name ? <>{deliveryAddress.name}<br/></> : null}
                      {deliveryAddress.street}<br/>
                      {deliveryAddress.line2 ? <>{deliveryAddress.line2}<br/></> : null}
                      {deliveryAddress.town}<br/>
                      {deliveryAddress.postcode}<br/>
                  </td>
                  <td style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                    <SupportMerchantHardwareStatusButton
                      merchantId={merchantId}
                      type='delivery'
                      terminalOrder={terminalOrder}
                      selectedStatusDropdown={selectedStatusDropdown}
                      onToggleDropdown={setSelectedStatusDropdown}
                    />
                    <Link
                        onClick={(e) => {
                          setSelectedTerminalOrder(terminalOrder);
                          e.preventDefault();
                        }}
                        to="#"
                        className="btn btn-secondary"
                      >
                        {!terminalOrder.serialNumber?.trim()?.length
                          ? "Not yet paired"
                          : `Paired as ${terminalOrder.serialNumber}`}
                      </Link>

                      <Link
                        onClick={(e) => {
                          if (!terminalOrder.providerId) {
                            alert("This order has no provider id");
                            return;
                          }

                          if (terminalOrder.delivery.source === 'mintsoft') {
                            openTerminalOrderInMintSoft(terminalOrder.providerId);
                          } else {
                            openTerminalOrderInStripe(terminalOrder.providerId);
                          }
                          e.preventDefault();
                        }}
                        to="#"
                        className="btn btn-primary"
                      >

                        View in {terminalOrder.delivery.source}
                      </Link>
                  </td>
                  <td>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                    <SupportMerchantHardwareStatusButton
                      merchantId={merchantId}
                      type='issue'
                      terminalOrder={terminalOrder}
                      selectedStatusDropdown={selectedStatusDropdown}
                      onToggleDropdown={setSelectedStatusDropdown}
                    />
                    {terminalOrder.delivery.issueStatus && terminalOrder.delivery.issueDescription ?
                      <Link
                        onClick={(e) => {
                            e.preventDefault();
                            setIssueDetails(terminalOrder.delivery.issueDescription)
                        }}
                        to="#"
                        className="btn btn-outline-danger"
                      >
                        See Fault Details
                      </Link>
                    : null}
                    </div>
                  </td>
                  <td>
                    <SupportMerchantHardwareStatusButton
                      merchantId={merchantId}
                      type='return'
                      terminalOrder={terminalOrder}
                      selectedStatusDropdown={selectedStatusDropdown}
                      onToggleDropdown={setSelectedStatusDropdown}
                    />
                  </td>
                  <td>
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            onClickReorder(terminalOrder)
                        }}
                        to="#"
                        className="btn btn-primary"
                    >
                        <i className="mdi mdi-repeat"></i>
                    </Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>

      <Modal isOpen={Boolean(issueDetails)} toggle={() => setIssueDetails('')}>
        <ModalHeader toggle={() => setIssueDetails('')}>Fault Details</ModalHeader>
        <ModalBody>
          {issueDetails}
        </ModalBody>
      </Modal>

      <Modal isOpen={Boolean(selectedTerminalOrder)} scrollable={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Terminal Order</h5>
          <button
            type="button"
            onClick={() => setSelectedTerminalOrder(undefined)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </Modal>

      <SupportMerchantHardwareOrderModal
        isOpen={isOrderModalOpen}
        toggle={(newOrder?: TerminalOrderDto) => handleOrderToggle(newOrder)}
        merchantId={merchantId}
        terminalOrder={orderModalTerminalOrder}
        accountOwner={accountOwner}
      />
      </>
    )
}