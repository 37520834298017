import moment from 'moment'
import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import currency from 'currency.js'
import styled from 'styled-components'
import {
    Alert,
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap'

import SupportMerchantApiDto from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'
import { FormattedPayment } from 'src/helpers/transformers/paymentFormatter'
import { priceFormat } from 'src/helpers/utils'
import { usePaymentRefund } from '../../support-merchant-payments.hook'

const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`

type IComponentProps = {
    isOpen: boolean
    merchant: SupportMerchantApiDto
    payment?: FormattedPayment
    toggle: () => void
    loadMerchant: (reloadSummary?: boolean) => Promise<void>
}

const refundPaymentColumns = [
    { dataField: 'date', text: 'Date' },
    { dataField: 'amount', text: 'Amount' },
]

const RefundPaymentModal: React.FC<IComponentProps> = ({
    isOpen,
    payment,
    toggle,
    loadMerchant,
}) => {
    const [error, setError] = React.useState('')
    const [refundAmount, setRefundAmount] = React.useState<string>('')
    const { isLoading, mutateAsync: paymentRefundMutate } = usePaymentRefund()

    const refundPayment = React.useCallback(async () => {
        if (!payment) {
            return
        }

        const amountString = refundAmount === '' ? '0' : refundAmount
        const amountUnits = currency(amountString).intValue

        if (amountUnits <= 0) {
            setError('Please enter an amount above zero')
            return
        }

        try {
            setError('')

            await paymentRefundMutate({
                paymentId: payment.id,
                amountUnits: -amountUnits,
            })

            toggle()
            await loadMerchant(true)
        } catch (error) {
            setError('Unable to refund payment. Please try again')
            console.error(error)
        }
    }, [paymentRefundMutate, refundAmount, payment, toggle, loadMerchant])

    const associatedRefunds = React.useMemo(
        () =>
            (payment?.associatedRefunds ?? []).map((refund) => ({
                date: moment(refund.createdAt)
                    .local()
                    .format('DD-MM-YY HH:mm [UTC]Z'),
                amount: priceFormat(refund.total),
            })),
        [payment]
    )

    useEffect(() => {
        if (!isOpen) {
            setRefundAmount('')
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Refund payment</ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    Amount available to refund: {payment?.net}
                </div>
                <div className="mb-3">
                    <Label htmlFor="refund-amount">Refund amount</Label>
                    <div className="mb-3">
                        <div className="input-group">
                            <div className="input-group-text">£</div>
                            <Input
                                type="number"
                                className="form-control"
                                id="refund-amount"
                                placeholder="0.00"
                                value={refundAmount}
                                onChange={(e) =>
                                    setRefundAmount(e.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div>
                        {error.length > 0 && (
                            <Alert color="danger">{error}</Alert>
                        )}
                    </div>
                    <ButtonContainer>
                        <Button
                            className="btn btn-primary"
                            disabled={isLoading}
                            onClick={refundPayment}
                        >
                            {isLoading ? 'Processing refund...' : 'Refund'}
                        </Button>
                    </ButtonContainer>
                </div>
                {associatedRefunds.length > 0 && (
                    <>
                        <hr />
                        <h5>Associated refunds</h5>
                        <ToolkitProvider
                            keyField="id"
                            data={associatedRefunds}
                            columns={refundPaymentColumns}
                        >
                            {(toolkitProps) => (
                                <BootstrapTable
                                    bordered
                                    striped={false}
                                    classes="table align-middle table-nowrap"
                                    headerWrapperClasses="thead-light"
                                    {...toolkitProps.baseProps}
                                />
                            )}
                        </ToolkitProvider>
                    </>
                )}
            </ModalBody>
        </Modal>
    )
}

export default RefundPaymentModal
