import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Table,
  Col,
  Card,
  Input,
  CardBody,
  CardTitle,
  Row,
  Form,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import MerchantApiDto from "../../api-dtos/merchant/merchant.dto.interface";
import UserReferralsDto from "../../api-dtos/support/user/user-referrals.dto.interface";
import PriceDto from "../../api-dtos/types/price.dto";
import {
  supportListReferralsForUser,
  supportNewReward,
  supportRedeemReferralCode,
} from "../../helpers/lopay_api_helper";
import { priceFormat } from "../../helpers/utils";
import SupportUserApiDto from "../../store/types/user.type";

interface NewRewardDto {
  amountString: string;
  type: "gift";
  reason: string;
}

interface RedeemCodeDto {
  code: string;
}

const MerchantDiscountReferrals = (props: {
  merchant: MerchantApiDto;
  owner: SupportUserApiDto;
}) => {
  useEffect(() => {
    if (props.owner) {
      loadReferralsForUser(props.owner.id);
    }
    // eslint-disable-next-line
  }, [props.merchant]);

  // Referrals
  const [LoadingReferralsError, setLoadingReferralsError] = useState<
    string | null
  >();

  const [applicableReferralCode, setApplicableReferralCode] =
    useState<string>("");
  const [loadingUserReferrals, setLoadingUserReferrals] =
    useState<boolean>(false);
  const [userReferrals, setUserReferrals] = useState<UserReferralsDto>();
  const [loadingRedeemUserReferral, setLoadingRedeemUserReferral] =
    useState<boolean>(false);

  // Redeeming a new code
  const [acknowledgedNewDiscount, setAcknowledgedNewDiscount] =
  useState<boolean>(false);

  const [redeemNewCodeDetails, setRedeemNewCodeDetails] = useState<
    RedeemCodeDto | undefined
  >(undefined);
  const [redeemNewCodeError, setRedeemNewCodeError] = useState<
    string | undefined
  >(undefined);
  const [addDiscountError, setAddDiscountError] = useState<
    string | undefined
  >(undefined);

  // Adding a new reward
  const [loadingNewReward, setLoadingNewReward] = useState<boolean>(false);
  const [newRewardDetails, setNewRewardDetails] = useState<
    NewRewardDto | undefined
  >(undefined);

  const newDiscountFormIsValid = (): boolean => {
    const goodValue = parseFloat(newRewardDetails?.amountString || '0') > 0
    const goodReason = (newRewardDetails?.reason?.length || 0) > 2
    return goodValue && goodReason
  }

  const loadReferralsForUser = async (userId: string) => {
    setLoadingUserReferrals(true);
    setLoadingReferralsError(undefined)

    try {
      setUserReferrals(
        await supportListReferralsForUser(props.owner.id, props.merchant.id)
      );
    } catch (e: any) {
      setLoadingReferralsError(
        (e?.message as string) || `Could not create discount code ${e}`.trim()
      );
    }
    setLoadingUserReferrals(false);
  };

  const redeemACode = async () => {
    setLoadingRedeemUserReferral(true);

    let didError = false

    try {
      await supportRedeemReferralCode(props.owner.id, applicableReferralCode);

      await loadReferralsForUser(props.owner.id);
    } catch (error: any) {
      didError = true
      setRedeemNewCodeError(error?.message || error);
    }

    if (!didError) {
      // Close the popup
      setRedeemNewCodeDetails(undefined)
    }

    setLoadingRedeemUserReferral(false);
    setAcknowledgedNewDiscount(false);
  };

  const addNewReward = async () => {
    setLoadingNewReward(true);
    setAddDiscountError(undefined)

    const payload: {
      amount: PriceDto
      reason: string
    } = {
      amount: {
        currencyCode: props.merchant.currencyCode,
        units: parseFloat(newRewardDetails?.amountString || '0') * 100
      },
      reason: newRewardDetails?.reason || '',
    }

    console.log('payload:', payload)

    let didError = false

    const result = await supportNewReward(
      props.owner.id,
      props.merchant.id,
      payload,
    ).catch((error: any) => {
      didError = true
      setAddDiscountError(error?.message || error);
    })

    console.log('result:', result)

    if (!didError) {
      // Close the popup
      setNewRewardDetails(undefined)
      setAcknowledgedNewDiscount(false)
      // Refresh the list
      await loadReferralsForUser(props.owner.id);
    }

    setLoadingNewReward(false);
  };

  if (loadingUserReferrals) {
    return (
      <React.Fragment>
        <>loading...</>
      </React.Fragment>
    );
  }

  if (LoadingReferralsError) {
    return (
      <React.Fragment>
         <Alert color="danger">{LoadingReferralsError}</Alert>
      </React.Fragment>
    );
  }

  if (!userReferrals) {
    return (
      <React.Fragment>
        <>Could not load referrals</>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>
            <b>
              Reward balance: {priceFormat(userReferrals!.reward.remaining)}
            </b>{" "}
            (of {priceFormat(userReferrals!.reward.earned)} earned)
          </CardTitle>

          <Form>
            <div>
              <button
                type="button"
                className="btn btn-primary w-md"
                onClick={async (e) => {
                  setRedeemNewCodeDetails({ code: "" });
                }}
              >
                {loadingRedeemUserReferral ? "Applying" : "Apply code"}
              </button>

              <button
                type="button"
                className="btn btn-primary w-md m-1"
                onClick={async (e) => {
                  setNewRewardDetails({
                    amountString: "",
                    type: "gift",
                    reason: "",
                  });
                }}
              >
                {loadingNewReward ? "Applying..." : "Add Free Processing"}
              </button>
            </div>
          </Form>
        </CardBody>
      </Card>

      <div className="table-responsive">
        <Table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>Date applied</th>
              <th>Owner</th>
              <th>Type</th>
              <th>Amount</th>
              <th>From</th>
            </tr>
          </thead>
          {!userReferrals ? (
            <tbody>
              <tr>
                <th>Loading...</th>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {userReferrals.rewards?.map((reward) => {
                return (
                  <tr key={reward.id}>
                    <td>
                      <span className="time">
                        {moment(new Date(reward.date)).format(
                          "MMM Do YYYY h:mm:ss a"
                        )}
                      </span>
                    </td>
                    <th scope="row">
                      {props.owner.firstName} {props.owner.lastName}
                    </th>
                    <th scope="row">
                      <Badge className="me-2 bg-primary">{reward.type}</Badge>

                      <Badge className="me-2 bg-success">
                        {reward.reward.tender}
                      </Badge>
                    </th>
                    <td>{priceFormat(reward.reward.value)}</td>
                    <td>{reward.from}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </div>

      <Modal isOpen={Boolean(redeemNewCodeDetails)} scrollable={true}>
        <ModalHeader tag="h4">Redeem a code</ModalHeader>

        <ModalBody>
          <Row className="mb-4">
            <Col sm={9}>
              <Input
                type="text"
                placeholder="Enter code here"
                className="form-control"
                id="redeem-referral-code"
                value={applicableReferralCode}
                onChange={(e) =>
                  setApplicableReferralCode(
                    e.target.value.toLocaleUpperCase().trim()
                  )
                }
              />
            </Col>
          </Row>

          {redeemNewCodeError && (
            <Alert color="danger">{redeemNewCodeError}</Alert>
          )}

          <button
            type="button"
            className="btn btn-primary w-md"
            onClick={() => redeemACode()}
          >
            Redeem a code
          </button>

          <button
            type="button"
            className="btn btn-secondary w-md m-1"
            onClick={() => {
              setRedeemNewCodeError(undefined);
              setRedeemNewCodeDetails(undefined);
            }}
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>

      <Modal isOpen={Boolean(newRewardDetails)} scrollable={true}>
        <ModalHeader tag="h4">Apply free processing</ModalHeader>

        <ModalBody>
          <Row className="mb-4">
            <Col>
              <Input
                type="text"
                placeholder="Reason (Description)"
                className="form-control"
                id="free-processing-reason"
                value={newRewardDetails?.reason}
                onChange={(e) => {
                  const details = newRewardDetails;
                  if (!details) {
                    return;
                  }
                  setNewRewardDetails({
                    ...details,
                    reason: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <div className="input-group">
                <div className="input-group-text">£</div>
                <Input
                  type="text"
                  className="form-control"
                  id="specificSizeInputGroupUsername"
                  placeholder="0.00"
                  value={newRewardDetails?.amountString}
                  onChange={(e) => {
                    const details = newRewardDetails;
                    if (!details) {
                      return;
                    }
                    setNewRewardDetails({
                      ...details,
                      amountString: e.target.value,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <div className="form-check mb-3">
                <input
                disabled={!newDiscountFormIsValid()}
                  className="form-check-input"
                  type="checkbox"
                  id="formCheck1"
                  checked={acknowledgedNewDiscount}
                  onChange={() => setAcknowledgedNewDiscount(!acknowledgedNewDiscount)}
                />
                <label className="form-check-label" htmlFor="formCheck1">
                  {
                    newDiscountFormIsValid() ? `Confirm the application of £${newRewardDetails?.amountString} of free processing to ${props.merchant.name}` : "Confirm application of discount"
                  }
                </label>
              </div>
            </Col>
          </Row>
          
          {addDiscountError && (
            <Alert color="danger">{addDiscountError}</Alert>
          )}

          <button
            type="button"
            className="btn btn-primary w-md"
            disabled={!acknowledgedNewDiscount}
            onClick={() => addNewReward()}
          >
            Apply processing
          </button>

          <button
            type="button"
            className="btn btn-secondary w-md m-1"
            onClick={() => setNewRewardDetails(undefined)}
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default MerchantDiscountReferrals;
