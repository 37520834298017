import React from 'react'

export type ErrorCodes =
    | 'missing_parameters'
    | 'server_error'
    | 'invalid_csrf_token'
    | 'unsupported_quickbooks_version'
    | 'oauth2_error'
    | 'unknown_error'

interface IComponentProps {
    errorCode: ErrorCodes
}

const QuickBooksError: React.FC<IComponentProps> = ({
    errorCode,
}) => {
    let errorMessageFromCode =
        'An unknown error has occurred. Please contact our support team.'

    switch (errorCode) {
        case 'missing_parameters':
            errorMessageFromCode =
                'Please configure the Lopay QuickBooks integration from the Lopay mobile app.'
            break
        case 'unknown_error':
        case 'server_error':
            errorMessageFromCode =
                'A server error has occurred. Please try again in a few minutes. If the issue persists, please contact the Lopay support team.'
            break
        case 'unsupported_quickbooks_version':
            errorMessageFromCode =
                'Lopay only supports QuickBooks Online Essentials and QuickBooks Online Plus. Please upgrade your QuickBooks subscription.'
            break
        case 'oauth2_error':
        case 'invalid_csrf_token':
            errorMessageFromCode =
                'There was an issue connecting to QuickBooks. Close this window and try to connect again from the Lopay app.'
            break
        default:
            break
    }

    return (
        <div className="text-center">
            <div className="mb-4">{errorMessageFromCode}</div>
            {errorCode === 'missing_parameters' && (
                <div className="mb-2">
                    Download the <a href="https://lopay.com/download">Lopay app</a>.
                </div>
            )}
        </div>
    )
}

export default QuickBooksError
