import { Badge, Card, CardBody, CardHeader, CardTitle, Form, Input, Label } from 'reactstrap'
import ReactTimeAgo from 'react-time-ago'
import SupportMerchantApiDto from '../../../../../api-dtos/support/merchant/support-merchant-api.dto.interface'
import React from 'react'

type MerchantBusinessProfileProps = {
    merchant: SupportMerchantApiDto
    syncWithStripeFn: () => Promise<void>
    isSyncingWithStripe: boolean
}

const MerchantBusinessProfile = (props: MerchantBusinessProfileProps) => {
    const { merchant, syncWithStripeFn, isSyncingWithStripe } = props

    return (
        <Card>
            <CardHeader>
                <CardTitle className="h4">
                    Business details
                </CardTitle>
                <div className="mb-1">
                    {merchant.category && (
                        <Badge className="me-2 bg-success">
                            {
                                merchant
                                    .category
                                    .name
                            }{' '}
                            (Code:{' '}
                            {
                                merchant
                                    .category
                                    .code
                            }
                            )
                        </Badge>
                    )}

                    {merchant.kycStatus
                        .restrictions ? (
                        <Badge className="me-2 bg-danger">
                            Restricted
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-success">
                            Unrestricted by
                            Stripe
                        </Badge>
                    )}
                    {merchant.kycStatus
                        .payouts
                        .disabled ? (
                        <Badge className="me-2 bg-danger">
                            Disabled payouts
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-success">
                            Payouts enabled
                        </Badge>
                    )}
                    {merchant.kycStatus
                        .hasPayoutMethod ? (
                        <Badge className="me-2 bg-success">
                            Has bank account
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            No bank account
                        </Badge>
                    )}
                    {merchant.kycStatus
                        .eventuallyDue && (
                        <Badge className="me-2 bg-danger">
                            ID Verification
                            eventually due
                        </Badge>
                    )}
                    {merchant.terminals
                        .isSetup ? (
                        <Badge className="me-2 bg-success">
                            Terminals
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            No terminals
                        </Badge>
                    )}
                    {merchant.pos
                            .termsAcceptedDate &&
                        merchant.pos
                            .freeTrialActive && (
                            <Badge className="me-2 bg-success">
                                POS Trial
                                Active:
                                Expires{' '}
                                <ReactTimeAgo
                                    date={
                                        new Date(
                                            merchant.pos.freeTrialEnds!
                                        )
                                    }
                                    locale="en-US"
                                />
                            </Badge>
                        )}
                </div>
            </CardHeader>
            <CardBody>
                <Form>
                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-name-text-input"
                        >
                            Business name
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant.name
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-name-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder=".form-control-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-website-text-input"
                        >
                            Business website
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant.website
                            }
                            onChange={(e) =>
                                (merchant.website =
                                    e.target.value)
                            }
                            id="company-profile-website-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder=".form-control-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-name-text-input"
                        >
                            Address Name or
                            number
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .nameOrNumber
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-name-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="Address name or number"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-street-text-input"
                        >
                            Line 1 (Street)
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .street
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-street-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="Line 1 (Street)"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-line2-text-input"
                        >
                            Line 2
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .line2
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-line2-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="Line 2"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-state-text-input"
                        >
                            County (State)
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .state
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-state-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="County (State)"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-city-text-input"
                        >
                            Town or City
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .town
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-city-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="Town or city"
                        />
                    </div>

                    <div className="mb-4">
                        <Label
                            className="form-label"
                            htmlFor="company-profile-address-postcode-text-input"
                        >
                            Postcode
                        </Label>
                        <Input
                            className="form-control form-control-sm"
                            type="text"
                            value={
                                merchant
                                    .address
                                    .postcode
                            }
                            onChange={(e) =>
                                (merchant.name =
                                    e.target.value)
                            }
                            id="company-profile-address-postcode-text-input"
                            validate={{
                                required: {
                                    value: true,
                                },
                            }}
                            placeholder="Postcode (zip code)"
                        />
                    </div>

                    <div>
                        {isSyncingWithStripe ? (
                            <button
                                type="button"
                                className="btn btn-dark waves-effect waves-light"
                            >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
                                Synchronizing...
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={async () => {
                                    await syncWithStripeFn()
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                Sync with
                                Stripe
                            </button>
                        )}
                    </div>
                </Form>
            </CardBody>
        </Card>
    )
}

export default MerchantBusinessProfile
