/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useInfiniteQuery } from "react-query"


import React, { useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Input,
    CardHeader,
    Table,
    Container,
    Badge,
    Button,
    Alert,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import styled from 'styled-components'
import { SupportOfferDto } from 'src/api-dtos/offers/offer.dto';
import { supportListOffers } from 'src/helpers/lopay_api_helper';

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`

const Spacer = styled.div`
    padding: 10px 0;
`

try {
    TimeAgo.addDefaultLocale(en)
} catch (e) {
    console.error(e)
}
interface PageProps {
    history: any
}

const OffersPage = ({ history }: PageProps) => {

    const pageSize = 10

    // Searching of offers
    const [searchQuery, setSearchQuery] = useState<string | undefined>()

    // If filtering by offer type:
    const [offerTypeFilter, setOfferTypeFilter] = useState<
        'giftCard' | 'affiliate' | 'all' | undefined
    >()

    // If filtering by country
    const [countryFilter, setCountryFilter] = useState<
        'GB' | 'all' | undefined
    >()

    // If filtering published state
    const [publishedStateFilter, setPublishedStateFilter] = useState<
        true | false | undefined
    >()

    const filterOptions = {
        offerTypes: [{
            value: 'all',
            label: 'All offers',
        }, {
            value: 'giftCard',
            label: 'Vouchers (Runa)',
        }, {
            value: 'affiliate',
            label: 'Affiliated purchases (FinFare)',
        }],
        countries: [{
            value: 'all',
            label: '🌍 All',
        }, {
            value: 'GB',
            label: '🇬🇧 United Kingdom',
        }],
        publishedStates: [{
            value: undefined,
            label: 'All offers',
        }, {
            value: 'true',
            label: 'Published',
        }, {
            value: 'false',
            label: 'Unpublished',
        }],
    }

    /*
    useEffect(() => {
        refetch()
        // eslint-disable-next-line
    }, [
        offerTypeFilter,
        countryFilter,
        publishedStateFilter,
    ])
    */

    const {
        data: offersData,
        error,
        isLoading,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<
        {
            data: SupportOfferDto[];
        },
        Error
    >({
        queryKey: [
            `offers-cache`,
            offerTypeFilter,
            countryFilter,
            publishedStateFilter,
            searchQuery,
        ],
        queryFn: ({ pageParam }) => supportListOffers({
            offsetId: pageParam as any,
            search: searchQuery,
            type: offerTypeFilter as any,
            country: countryFilter as any,
            enabled: publishedStateFilter,
        }),

        getNextPageParam: (lastPage, pages) => lastPage.data.length ? lastPage.data[lastPage.data.length - 1].id : undefined,
    });

    const showLoadMoreButton = (): boolean => {

        const lastPageIsFull = offersData?.pages && offersData.pages[offersData.pages.length - 1].data.length === pageSize

        return hasNextPage || lastPageIsFull || isFetchingNextPage
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Offers</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs
                        title={'LoPay Support'}
                        breadcrumbItem="Offers"
                    />
                    <Row>
                        <Col xs={12}>

                            {error ? <Alert color="danger">{error}</Alert> : null}

                            <Card>
                                <CardHeader>
                                    <div className="row align-ite  ms-center">
                                        <form
                                            onSubmit={(e) => {
                                                refetch()
                                                e.preventDefault()
                                            }}
                                            className="row gx-3 gy-2 align-items-center"
                                        >
                                            <Col sm={4}>
                                                <Label
                                                    className="visually-hidden"
                                                    htmlFor="specificSizeInputName"
                                                >
                                                    Search
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="search input"
                                                    placeholder="Offer name or brand"
                                                    onChange={(e) =>
                                                        setSearchQuery(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Col>

                                            <Col sm={2}>
                                                <select
                                                    className="form-select"
                                                    value={offerTypeFilter}
                                                    onChange={(e) => {
                                                        if (e.target.value === 'all') {
                                                            setOfferTypeFilter(undefined)
                                                        } else {
                                                            setOfferTypeFilter(e.target.value as any)
                                                        }
                                                    }}
                                                >
                                                    {filterOptions.offerTypes.map(
                                                        (option) => (
                                                            <option
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </Col>

                                            <Col sm={2}>
                                                <select
                                                    className="form-select"
                                                    value={`${publishedStateFilter}`}
                                                    onChange={(e) => {
                                                        setPublishedStateFilter(e.target.value as any)
                                                    }}
                                                >
                                                    {filterOptions.publishedStates.map(
                                                        (option) => (
                                                            <option
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </Col>

                                            <Col sm={2}>
                                                <select
                                                    className="form-select"
                                                    value={offerTypeFilter}
                                                    onChange={(e) => {
                                                        if (e.target.value === 'all') {
                                                            setCountryFilter(undefined)
                                                        } else {
                                                            setCountryFilter(e.target.value as any)
                                                        }
                                                    }}
                                                >
                                                    {filterOptions.countries.map(
                                                        (option) => (
                                                            <option
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </Col>

                                            <div className="col-auto">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        refetch()
                                                        e.preventDefault()
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div
                                        className="table-responsive"
                                        style={{
                                            paddingBottom: 550,
                                            marginBottom: -550,
                                            msOverflowStyle: 'none',
                                        }}
                                    >
                                        <Table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <td>Brand</td>
                                                    <th></th>
                                                    <th>Expires</th>
                                                    <th>Redemption</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    offersData?.pages.map((page) => {
                                                        return page?.data?.map((offer) => {
                                                            return (
                                                                <tr key={offer.id} onClick={async () => {
                                                                    // Open the merchant details

                                                                    history.push(
                                                                        '/offers/' + offer?.id
                                                                    )

                                                                }}>
                                                                    <td>
                                                                        <img
                                                                            className="img-thumbnail avatar-xl"
                                                                            alt={'Thumbnail'}
                                                                            src={offer?.imageUrl}
                                                                            data-holder-rendered="true"
                                                                        />
                                                                    </td>
                                                                    <td

                                                                    >
                                                                        <b>
                                                                            {
                                                                                offer.brand
                                                                            }
                                                                        </b>
                                                                        <div>
                                                                        {
                                                                            offer.categories.map((category) => {
                                                                                return (
                                                                                    <Badge pill className="badge-soft-primary">
                                                                                        {category}
                                                                                    </Badge>
                                                                                )
                                                                            })
                                                                        }

                                                                            </div>

                                                                        
                                                                        <div>
                                                                        {
                                                                            offer.enabled
                                                                                ? (<Badge className="bg-success">Published</Badge>)
                                                                                : (<Badge className="bg-danger">Unpublished</Badge>)
                                                                        }
                                                                        </div>
                                                                        

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            Boolean(offer.expiresAt) ?
                                                                                <ReactTimeAgo
                                                                                    date={
                                                                                        new Date(
                                                                                            offer.expiresAt!
                                                                                        )
                                                                                    }
                                                                                    locale="en-US"
                                                                                />
                                                                                : 'Never'
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            offer.providerUrl
                                                                                ? offer.providerUrl
                                                                                : 'Purchase'
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                    })
                                                }

                                            </tbody>
                                        </Table>
                                    </div>

                                    <Spacer />
                                    {
                                        isLoading && <CenterContainer>Loading...</CenterContainer>
                                    }

                                    {showLoadMoreButton() ? (
                                        <CenterContainer>
                                            <Button
                                                color="secondary"
                                                onClick={() =>
                                                    // Use last offer ID as offset
                                                    fetchNextPage()
                                                }
                                            >
                                                Load more
                                            </Button>
                                        </CenterContainer>
                                    ) : <CenterContainer>
                                        <p>
                                            No more offers to load
                                        </p>
                                    </CenterContainer>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OffersPage
