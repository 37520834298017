import { HardwareDto, HardwarePriceDto, HardwarePriceInterval } from '../../hardware.type'
import React, { useState } from 'react'
import { Badge, Label, Table } from 'reactstrap'
import { convertToUnits, currencyFormat } from '../../../../helpers/utils'
import getSymbolFromCurrency from 'currency-symbol-map'
import CurrencyType from '../../../../api-dtos/types/currency.type'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { debounce } from 'lodash'

type NewHardwarePrice = Omit<HardwarePriceDto, 'id'>

type HardwareSubscriptionComponentProps = {
    hardware: HardwareDto
    deleteHardwarePrices: (prices: HardwarePriceDto[]) => void
    addHardwarePrices: (prices: NewHardwarePrice[]) => void
}

/**
 * Component handling the state management of creation and deletion of hardware subscriptions.
 *
 * @param hardware - The hardware entity being modified.
 * @param deleteHardwarePrices - The function called when the prices scheduled for deletion are updated.
 * @param addHardwarePrices - The function called when prices scheduled for creation are updated.
 * @constructor
 */
const HardwareSubscriptionComponent = ({
    hardware,
    deleteHardwarePrices,
    addHardwarePrices,
}: HardwareSubscriptionComponentProps) => {
    const hardwarePrices = hardware.hardwarePrices || []
    const [newHardwarePrices, setNewHardwarePrices] = useState<NewHardwarePrice[]>([])
    const [deletedHardwarePrices, setDeletedHardwarePrices] = useState<HardwarePriceDto[]>([])

    const [newSubscriptionInterval, setNewSubscriptionInterval] = useState<HardwarePriceInterval>('day')
    const [newSubscriptionIntervalCount, setNewSubscriptionIntervalCount] = useState<number>()
    const [newSubscriptionTotalIntervals, setNewSubscriptionTotalIntervals] = useState<number>()
    const [newSubscriptionCurrencyCode, setNewSubscriptionCurrencyCode] = useState<CurrencyType>('GBP')
    const [newSubscriptionDecimalUnits, setNewSubscriptionDecimalUnits] = useState<number>()
    const [newSubscriptionProviderId, setNewSubscriptionProviderId] = useState<string>()

    /**
     * Validation function to ensure that the price ID is not reused across different price entities
     * within the same hardware.
     */
    const checkRepeatedPriceId = debounce((value, ctx, input, cb) => {
        const alreadyExists = [...newHardwarePrices, ...hardwarePrices].some(
            p => p.providerPriceId === value
        )

        // The docs are not very clear on the validation, but essentially if a string returned then it will
        // be used as the error message, but if `true` is returned then the validation passes.
        cb(alreadyExists ? 'Given price ID already in use' : true)
    }, 200)

    const processNewSubscription = () => {
        if (!newSubscriptionProviderId) return

        const newHardwarePrice: NewHardwarePrice = {
            currencyCode: newSubscriptionCurrencyCode,
            units: convertToUnits(String(newSubscriptionDecimalUnits)),
            interval: newSubscriptionInterval,
            intervalCount: newSubscriptionIntervalCount,
            totalIntervals: newSubscriptionTotalIntervals,
            providerPriceId: newSubscriptionProviderId,
        }

        addHardwarePrices([...newHardwarePrices, newHardwarePrice])
        setNewHardwarePrices(prev => [...prev, newHardwarePrice])
    }

    const handleDeleteEntry = (hardwarePrice: HardwarePriceDto | NewHardwarePrice) => {
        if ("id" in hardwarePrice) {
            deleteHardwarePrices([...deletedHardwarePrices, hardwarePrice])
            setDeletedHardwarePrices(prev => [...prev, hardwarePrice])
            return
        }
        addHardwarePrices(newHardwarePrices.filter(p => p.providerPriceId !== hardwarePrice.providerPriceId))
        setNewHardwarePrices(prev => prev.filter(p => p.providerPriceId !== hardwarePrice.providerPriceId))
    }

    const handleUndoDelete = (hardwarePrice: HardwarePriceDto | NewHardwarePrice) => {
        if ("id" in hardwarePrice) {
            deleteHardwarePrices(deletedHardwarePrices.filter(p => p.id !== hardwarePrice.id))
            setDeletedHardwarePrices(prev => prev.filter(p => p.id !== hardwarePrice.id))
            return
        }
    }

    const isProduction = Boolean(process.env.REACT_APP_API_HOST?.startsWith('https://api.lopay.com'))

    const mapPriceEntries = (hardwarePrice: HardwarePriceDto | NewHardwarePrice) => {
        if (!hardwarePrice.interval) return undefined

        const isToBeDeleted = "id" in hardwarePrice && deletedHardwarePrices.some(dp => dp.id === hardwarePrice.id)

        return (
            <tr key={"id" in hardwarePrice ? hardwarePrice.id : hardwarePrice.providerPriceId}>
                <td>{hardwarePrice.intervalCount}</td>
                <td>{hardwarePrice.interval}</td>
                <td>{hardwarePrice.totalIntervals || `∞`}</td>
                <td>{getSymbolFromCurrency(hardwarePrice.currencyCode)}</td>
                <td>{currencyFormat(hardwarePrice.units)}</td>
                <td style={{ maxWidth: 100 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {!("id" in hardwarePrice) &&
                            <div>
                                <Badge className="bg-light">NEW</Badge>
                            </div>
                        }
                        {isToBeDeleted &&
                            <div>
                                <Badge className="bg-danger">TO DELETE</Badge>
                            </div>
                        }
                        <button
                            type="button"
                            className="btn btn-primary btn-sm ms-4"
                            onClick={() => {
                                window
                                    .open(`https://dashboard.stripe.com/${isProduction ? '' : 'test/'}prices/${hardwarePrice.providerPriceId}`, '_blank')
                                    ?.focus()
                            }}
                        >
                            <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                            See details
                        </button>
                    </div>
                </td>
                <td>
                    {
                        isToBeDeleted
                            ?
                            <button
                                type="button"
                                className="btn btn-warning btn-sm"
                                onClick={() => handleUndoDelete(hardwarePrice)}
                            >
                                <i className="mdi mdi-undo font-size-16 align-middle"></i>
                            </button>
                            :
                            <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDeleteEntry(hardwarePrice)}
                            >
                                <i className="mdi mdi-trash-can font-size-16 align-middle"></i>
                            </button>
                    }
                </td>
            </tr>
        )
    }

    return (
        <>
            <Label>Subscriptions</Label>
            <div
                className="table-responsive"
            >
                <AvForm onValidSubmit={() => processNewSubscription()}>
                    <Table className="table table-striped mb-0">
                        <thead>
                            <tr>
                                <th style={{width: 90}}>Count</th>
                                <th style={{width: 110}}>Interval</th>
                                <th style={{width: 90}}>x</th>
                                <th style={{width: 80}}>Cur.</th>
                                <th style={{width: 110}}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hardwarePrices.map(mapPriceEntries).filter(Boolean)}
                            {newHardwarePrices.map(mapPriceEntries)}
                            <tr>
                                <td style={{ maxWidth: 30 }}>
                                    <AvField
                                        name="test"
                                        id="subscription-interval-count"
                                        className="form-control"
                                        type="number"
                                        validate={{
                                            min: {
                                                value: 1,
                                                errorMessage: 'Min 1'
                                            },
                                            required: {
                                                value: true,
                                                errorMessage: 'Required'
                                            }
                                        }}
                                        style={{
                                            backgroundImage: 'none',
                                            paddingRight: 12
                                        }}
                                        placeholder="1"
                                        value={newSubscriptionIntervalCount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.value) {
                                                setNewSubscriptionIntervalCount(parseInt(e.target.value, 10))
                                            } else {
                                                setNewSubscriptionIntervalCount(undefined)
                                            }
                                        }}
                                    />
                                </td>
                                <td style={{ maxWidth: 50 }}>
                                    <select
                                        id="subscription-interval"
                                        className="form-select"
                                        value={newSubscriptionInterval}
                                        onChange={(e) => {
                                            setNewSubscriptionInterval(e.target.value as HardwarePriceInterval)
                                        }}
                                    >
                                        <option value="day">day</option>
                                        <option value="week">week</option>
                                        <option value="month">month</option>
                                        <option value="year">year</option>
                                    </select>
                                </td>
                                <td style={{ maxWidth: 30 }}>
                                    <AvField
                                        name="subscription-total-intervals"
                                        id="subscription-total-intervals"
                                        className="form-control"
                                        type="number"
                                        validate={{
                                            min: {
                                                value: 1,
                                                errorMessage: 'Min 1'
                                            },
                                        }}
                                        style={{
                                            backgroundImage: 'none',
                                            paddingRight: 12
                                        }}
                                        placeholder="∞"
                                        value={newSubscriptionTotalIntervals}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.value) {
                                                setNewSubscriptionTotalIntervals(parseInt(e.target.value, 10))
                                            } else {
                                                setNewSubscriptionTotalIntervals(undefined)
                                            }
                                        }}
                                    />
                                </td>
                                <td style={{ maxWidth: 50 }}>
                                    <select
                                        id="subscription-currency"
                                        className="form-select"
                                        value={newSubscriptionCurrencyCode}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setNewSubscriptionCurrencyCode(e.target.value as CurrencyType)
                                        }}
                                    >
                                        <option value="GBP">£</option>
                                        <option value="USD">$</option>
                                        <option value="EUR">€</option>
                                    </select>
                                </td>
                                <td style={{ maxWidth: 50 }}>
                                    <AvField
                                        name="subscription-units"
                                        id="subscription-units"
                                        className="form-control"
                                        type="number"
                                        validate={{
                                            min: {
                                                value: 1,
                                                errorMessage: 'Min 1'
                                            },
                                            required: {
                                                value: true,
                                                errorMessage: 'Required'
                                            }
                                        }}
                                        style={{
                                            backgroundImage: 'none',
                                            paddingRight: 12
                                        }}
                                        placeholder="1.00"
                                        value={newSubscriptionDecimalUnits}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.value) {
                                                setNewSubscriptionDecimalUnits(parseFloat(e.target.value))
                                            } else {
                                                setNewSubscriptionDecimalUnits(undefined)
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <AvField
                                            name="subscription-provider-id"
                                            id="subscription-provider-id"
                                            className="form-control"
                                            type="text"
                                            validate={{
                                                pattern: {
                                                    value: '^price_.*$',
                                                    errorMessage: 'Must follow price_xxx format'
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Required'
                                                },
                                                repeatedPriceId: checkRepeatedPriceId,
                                            }}
                                            style={{
                                                backgroundImage: 'none',
                                                paddingRight: 12,
                                                maxWidth: '100%',
                                            }}
                                            groupAttrs={{
                                                style: { flexGrow: 1 }
                                            }}
                                            placeholder="Stripe price ID"
                                            value={newSubscriptionProviderId}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setNewSubscriptionProviderId(e.target.value)
                                            }}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <button
                                        type="submit"
                                        className="btn btn-success btn-sm"
                                    >
                                        <i className="mdi mdi-plus font-size-16 align-middle"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </AvForm>
            </div>
        </>
    )
}

export default HardwareSubscriptionComponent