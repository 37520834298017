import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useCardDetailsToCreateEphemeralKey } from '../card-details.hook';
import CardDetailsNumber from './card-details-number.component';
import CardDetailsExpiry from './card-details-expiry.component';
import CardDetailsCVC from './card-details-cvc.component';

const CardDetails = ({
    merchantId,
    cardId,
    apiKey
}: {
    merchantId: string,
    cardId: string,
    apiKey: string
}) => {
    const stripe = useStripe();
    const { data, isLoading, isError } = useCardDetailsToCreateEphemeralKey({ merchantId, cardId, key: apiKey, stripe });
    const [chipAndPinRequired, setChipAndPinRequired] = useState(false);
    const [reveal, setReveal] = useState(() => <></>);

    useEffect(() => {
        if (isError) {
            setReveal(<p className="error-or-loading">Something went wrong. Please contact Lopay support for help.</p>);
        } else if (isLoading) {
            setReveal(<p className="error-or-loading">Loading card details...</p>);
        } else if (data) {
            setChipAndPinRequired(data.chipAndPinRequired);
            setReveal(
                <>
                    <CardDetailsNumber issuingCard={data.providerId} ephemeralKeySecret={data.secret} nonce={data.nonce}/>
                    <CardDetailsExpiry issuingCard={data.providerId} ephemeralKeySecret={data.secret} nonce={data.nonce} />
                    <CardDetailsCVC issuingCard={data.providerId} ephemeralKeySecret={data.secret} nonce={data.nonce} />
                </>
            );
        }
    }, [isError, isLoading, data]);

    return <div>
        <div className="card-details-container">
            {reveal}
        </div>

        {
            chipAndPinRequired &&
            <div className="card-details-info" style={{ marginTop: '1rem' }}>
                <div>
                    <i className="fas fa-info"></i>
                </div>
                <p>You will need to use the chip and PIN before the card is enabled for contactless payments.</p>
            </div>
        }
    </div>
}

export default CardDetails;