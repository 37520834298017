import { KYBStatus } from '../../../../FraudPrevention/kyb-threshold.type'
import { Badge } from 'reactstrap'
import React from 'react'

type KYBStatusPillProps = {
    /** The merchant's KYB status. */
    kybStatus: KYBStatus
    /** [OPTIONAL] Whether to include KYB prefix within the pill. Defaults to false. */
    includePrefix?: boolean
}

/**
 * Pill indicating the set KYB status for the merchant.
 *
 * @param props - Props relating to the component.
 * @constructor
 */
const KYBStatusPill = (props: KYBStatusPillProps) => {
    const { kybStatus, includePrefix = false } = props

    const kybStatusClassPropertyMap: Record<KYBStatus, string> = {
        approved: 'bg-success',
        unrestrictedOther: 'bg-light',
        unrestrictedNew: 'bg-light',
        restricted: 'bg-warning',
        blocked: 'bg-danger',
    }

    const kybStatusNameMap: Record<KYBStatus, string> = {
        approved: 'Approved',
        unrestrictedOther: 'Unrestricted Other',
        unrestrictedNew: 'Unrestricted New',
        restricted: 'Restricted',
        blocked: 'Blocked',
    }

    return (
        <Badge className={`me-2 ${kybStatusClassPropertyMap[kybStatus]}`}>
            {includePrefix && 'KYB: '}{kybStatusNameMap[kybStatus]}
        </Badge>
    )
}

export default KYBStatusPill