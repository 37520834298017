import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import UserProfileResponse from "../../api-dtos/profile/user-profile-response.dto.interface";
import { fetchProfile } from "../../store/actions";
import { fetchSupportProfile } from "../../store/auth/profile-support/actions";
import { isSupportUserAuthenticated, isUserAuthenticated } from "../../helpers/lopay_api_helper";
import config from "../../config";

const SidebarContent = (props: any) => {
  const dispatch = useDispatch();
  const ref = useRef<any>();

  const { profile } = useSelector((state: { profile: { profile: UserProfileResponse } }) => ({
    profile: state.profile.profile,
  }))

  useEffect(() => {
    if (isUserAuthenticated()) {
      dispatch(fetchProfile())
    }
    if (isSupportUserAuthenticated()) {
      dispatch(fetchSupportProfile())
    }
    // eslint-disable-next-line
  }, [])

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method simultaneously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const hasMerchant: boolean = (!!profile?.user?.merchants?.length && !!profile.user.merchants[0].id)

  const hasCompletedKYC: boolean = hasMerchant && profile.user.merchants[0].kycStatus.restrictions === undefined

  const hasCompletedPayouts: boolean = hasMerchant && profile.user.merchants[0].kycStatus.hasPayoutMethod

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">

          <ul className="metismenu list-unstyled" id="side-menu">

            {profile &&
              <>
                <li>
                  {hasMerchant ?
                    <Link to="/dashboard" className="">
                      <Icon name="home" />
                      <span>{props.t(profile.user.merchants[0].name)}</span>
                    </Link>
                    :
                    <Link to="/setup-merchant" className="">
                      <Icon name="home" />
                      <span>{props.t("Setup")}</span>
                    </Link>
                  }
                </li>

                <li className="menu-title">{props.t("Payments")}</li>

                <li>
                  <Link to="/payment-links" className="">
                    <Icon name="zap" />
                    <span>{props.t("Payment links")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/transactions" className="">
                    <Icon name="list" />
                    <span>{props.t("Payment history")}</span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("Configuration")}</li>

                <li>
                  <Link to="/pay-points" className="">
                    <Icon name="smartphone" />
                    <span>{props.t("Pay points")}</span>
                  </Link>
                </li>

                {!hasCompletedKYC &&
                  <li>
                    <Link to="/kyc" className="text-danger">
                      <Icon name="alert-triangle" color="#e83e8c" />
                      <span>{props.t("Verify business")}</span>
                    </Link>
                  </li>
                }

                {!hasCompletedPayouts &&
                  <li>
                    <Link to="/add-external-account" className="text-danger">
                      <Icon name="alert-triangle" color="#e83e8c" />
                      <span>{props.t("Add bank account")}</span>
                    </Link>
                  </li>
                }

                {hasCompletedPayouts &&
                  <li>
                    <Link to="/external-accounts" className="">
                      <Icon name="dollar-sign" />
                      <span>{props.t("Banking & Payouts")}</span>
                    </Link>
                  </li>
                }
              </>
            }

{ isSupportUserAuthenticated() &&
  <>
            <li className="menu-title">{props.t("Admin & Support")}</li>

            <li>
              <Link to="/registered-merchants" className="">
                <Icon name="home" />
                <span>{props.t("Merchants")}</span>
              </Link>
            </li>

            <li>
              <Link to="/users" className="">
                <Icon name="user" />
                <span>{props.t("Users")}</span>
              </Link>
            </li>

            <li>
              <Link to="/offers" className="">
                <Icon name="award" />
                <span>{props.t("Offers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/discount-codes" className="">
                <Icon name="gift" />
                <span>{props.t("Discount codes")}</span>
              </Link>
            </li>

            <li>
              <Link to="/terminal-shipping" className="">
                <Icon name="smartphone" />
                <span>{props.t("Card Readers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/app-builds" className="">
                <Icon name="box" />
                <span>{props.t("App builds")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="shield" />
                <span>{props.t("Fraud Prevention")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/fraud-prevention/fraud-rules">{props.t("Fraud Rules")}</Link>
                </li>
                <li>
                  <Link to="/fraud-prevention/kyb-thresholds">{props.t("KYB Thresholds")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/marketing-deep-links" className="">
                <Icon name="list" />
                <span>{props.t("Marketing deep links")}</span>
              </Link>
            </li>

            <li>
              <Link to="/waiting-lists" className="">
                <Icon name="list" />
                <span>{props.t("Waiting Lists")}</span>
              </Link>
            </li>

            <li>
              <Link to="/hardware" className="">
                <Icon name="smartphone" />
                <span>{props.t("Hardware")}</span>
              </Link>
            </li>
            {/* Hide in production until ready */}
            {false && (
              <li>
                <Link to="/external-accounts/open-banking-vrps" className="">
                  <Icon name="dollar-sign" />
                  <span>{props.t("Manage VRP Consents")}</span>
                </Link>
              </li>
            )}
          </>
}
{ config.stripe.publicKey.includes('test') && <>
            <li className="menu-title">{props.t("Development")}</li>

            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="grid" />
                <span>{props.t("Apps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/apps-calendar">{props.t("Calendar")}</Link>
                </li>
                <li>
                  <Link to="/apps-chat">{props.t("Chat")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Email")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/email-inbox">{props.t("Inbox")}</Link>
                    </li>
                    <li>
                      <Link to="/email-read">{props.t("Read Email")} </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    <span>{props.t("Invoices")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/invoices-list">{props.t("Invoice List")}</Link>
                    </li>
                    <li>
                      <Link to="/invoices-detail">
                        {props.t("Invoice Detail")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <span>{props.t("Contacts")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/contacts-grid">{props.t("User Grid")}</Link>
                    </li>
                    <li>
                      <Link to="/contacts-list">{props.t("User List")}</Link>
                    </li>
                    <li>
                      <Link to="/contacts-profile">{props.t("Profile")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <Icon name="users" />
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/page-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/page-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/page-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="/page-confirm-mail">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="/page-email-verification">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="/page-two-step-verification">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="file-text" />
                <span>{props.t("Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-starter">{props.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">{props.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">{props.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Elements")}</li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="briefcase" />
                <span>{props.t("Components")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-drawer">{props.t("Drawer")}</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    {props.t("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="/ui-general">{props.t("General")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">{props.t("Colors")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="gift" />
                <span>{props.t("Extended")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/extended-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/extended-rangeslider">
                    {props.t("Range Slider")}
                  </Link>
                </li>
                <li>
                  <Link to="/extended-sweet-alert">
                    {props.t("Sweet Alert")}
                  </Link>
                </li>
                <li>
                  <Link to="/extended-session-timeout">
                    {props.t("Session Timeout")}
                  </Link>
                </li>
                <li>
                  <Link to="/extended-rating">{props.t("Rating")}</Link>
                </li>
                <li>
                  <Link to="/extended-notifications">
                    {props.t("Notifications")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <Icon name="box" />
                <span className="badge rounded-pill bg-soft-danger text-danger float-end">
                  7
                </span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Basic Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">{props.t("Validation")}</Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Advanced Plugins")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-wizard">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/form-mask">{props.t("Form Mask")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="sliders" />
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Bootstrap Basic")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("DataTables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">{props.t("Responsive")}</Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="pie-chart" />
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/charts-apex">{props.t("Apexcharts")}</Link>
                </li>
                <li>
                  <Link to="/charts-echart">{props.t("Echarts")}</Link>
                </li>
                <li>
                  <Link to="/charts-chartjs">{props.t("Chartjs")}</Link>
                </li>
                <li>
                  <Link to="/charts-sparkline">{props.t("Sparkline")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="cpu" />
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-boxicons">{props.t("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="map" />
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector")}</Link>
                </li>
                <li>
                  <Link to="/maps-leaflet">{props.t("Leaflet")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <Icon name="share-2" />
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            </>
}
            
          </ul>
          { (1+1) === 1 &&
          <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">
              <img src={giftBox} alt="" />
              <div className="mt-4">
                <h5 className="alertcard-title font-size-16">
                  Instant Payouts
                </h5>
                <p className="font-size-13">
                  Upgrade your plan to receive your money into your bank account instantly
                </p>
                <a href="#!" className="btn btn-primary mt-2">
                  Upgrade Now
                </a>
              </div>
            </div>
          </div>
}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
