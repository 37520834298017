import { Alert, Col, Label, Row, Spinner } from 'reactstrap'
import {
    supportForceManualPayouts,
    supportUpdateSchedule,
} from '../../../../helpers/lopay_api_helper'
import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import {
    AccountType,
    ScheduledPayoutConfig,
} from '../../../../api-dtos/merchant/merchant-audit-log.dto.interface'
import { usePayoutSchedule } from '../hooks/payout-schedule.hook'
import { GetPayoutScheduleDTO } from '../hooks/payout-schedule.api'
import CurrencyType from '../../../../api-dtos/types/currency.type'
import MinimumInstantPayoutBalanceInput from './minimum-instant-payout-balance-input'

type ScheduleType = 'instant' | 'daily' | 'weekly' | 'monthly' | 'manual'
type WeeklyAnchorType = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday'

type WeeklyAnchorProps = {
    original?: WeeklyAnchorType,
    selected: WeeklyAnchorType,
    onChange: (day: WeeklyAnchorType) => void
}

const WeeklyAnchor = (props: WeeklyAnchorProps) => {
    const { original, selected, onChange } = props

    const weeklyAnchorOptions: WeeklyAnchorType[] = [
        'monday', 'tuesday', 'wednesday', 'thursday', 'friday',
    ]

    return (
        <div>
            <Label className="form-Label">
                Weekly Anchor {original !== selected && <span className="text-primary">*</span>}
            </Label>
            <select
                className={`form-select ${original !== selected && 'border-primary'}`}
                value={selected}
                onChange={async (e) => {
                    console.log('e.target:', e.target.value)
                    const day = e.target.value as WeeklyAnchorType
                    onChange(day)
                }}
            >
                {weeklyAnchorOptions?.map(
                    (wa) => (
                        <option
                            key={wa}
                            value={wa}
                        >
                            {wa}
                        </option>
                    )
                )}
            </select>
        </div>
    )
}

type MonthlyAnchorProps = {
    original?: number,
    selected: number,
    onChange: (date: number) => void
}

const MonthlyAnchor = (props: MonthlyAnchorProps) => {
    const { original, selected, onChange } = props

    const range = (n: number) => Array.from({length: n}, (_, key) => key).slice(1)
    const monthlyAnchorOptions: number[] = range(32)

    return (
        <div>
            <Label className="form-Label">
                Monthly Anchor {original !== selected && <span className="text-primary">*</span>}
            </Label>
            <select
                className={`form-select ${original !== selected && 'border-primary'}`}
                value={selected}
                onChange={async (e) => {
                    console.log('e.target:', e.target.value)
                    const day = Number(e.target.value)
                    onChange(day)
                }}
            >
                {monthlyAnchorOptions?.map(
                    (wa) => (
                        <option
                            key={wa}
                            value={wa}
                        >
                            {wa}
                        </option>
                    )
                )}
            </select>
        </div>
    )
}

type AccountTypeSelectorProps = {
    merchantId: string
    currencyCode: CurrencyType
}

const AccountTypeSelector = (props: AccountTypeSelectorProps) => {
    const { merchantId, currencyCode } = props

    const { isError, isLoading, data, refetch } = usePayoutSchedule(merchantId)

    const [selectedAccountType, setSelectedAccountType] = useState<AccountType | null | undefined>()
    const [selectedScheduleType, setSelectedScheduleType] = useState<ScheduleType | undefined>()
    const [selectedWeeklyAnchor, setSelectedWeeklyAnchor] = useState<WeeklyAnchorType | undefined>()
    const [selectedMonthlyAnchor, setSelectedMonthlyAnchor] = useState<number | undefined>()

    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isChangingPause, setIsChangingPause] = useState<boolean>(false)
    const [isChangingForceManual, setIsChangingForceManual] = useState<boolean>(false)

    const getDestructuredData = (data: GetPayoutScheduleDTO) => {
        const {
            accountType,
            paused,
            config,
            forcedManualPayouts,
            defaultWeeklyAnchor: unprocessedDefaultWeeklyAnchor,
            minimumInstantPayoutBalance,
        } = data

        const isManual = config === null
        const schedule: ScheduleType = isManual ? 'manual' : config.type

        const weeklyAnchor = config?.type === 'weekly'
            ? config.weeklyAnchor
            : 'thursday'
        const monthlyAnchor = config?.type === 'monthly'
            ? config.monthlyAnchor
            : 1
        const defaultWeeklyAnchor: WeeklyAnchorType =
            unprocessedDefaultWeeklyAnchor === 'saturday' ||
            unprocessedDefaultWeeklyAnchor === 'sunday'
                ? 'friday'
                : unprocessedDefaultWeeklyAnchor

        return {
            accountType,
            paused,
            config,
            forcedManualPayouts,
            schedule,
            weeklyAnchor,
            monthlyAnchor,
            defaultWeeklyAnchor,
            minimumInstantPayoutBalance
        }
    }

    useEffect(() => {
        if (data) {
            const {
                accountType,
                schedule,
                weeklyAnchor,
                monthlyAnchor,
            } = getDestructuredData(data)

            setSelectedAccountType(accountType)
            setSelectedScheduleType(schedule)
            setSelectedWeeklyAnchor(weeklyAnchor)
            setSelectedMonthlyAnchor(monthlyAnchor)
        }
    }, [data])

    if (isLoading) {
        return <Spinner color="primary" />
    }

    if (isError || !data) {
        return <p>Error loading. Please refresh.</p>
    }

    const {
        accountType,
        paused,
        forcedManualPayouts,
        schedule,
        weeklyAnchor,
        monthlyAnchor,
        minimumInstantPayoutBalance
    } = getDestructuredData(data)

    // TODO: Make conditional on merchant
    const availableAccountTypes: AccountType[] = [
        AccountType.Essential,
        AccountType.Standard,
        AccountType.Premium,
    ]
    const handleSelectedAccountTypeChange = (accountType: AccountType) => {
        setSelectedAccountType(accountType)
    }

    // TODO: Make conditional on merchant
    const availableScheduleTypes: ScheduleType[] = ['instant', 'daily', 'weekly', 'monthly', 'manual']

    const handleSelectedTypeChange = (type: ScheduleType) => {
        setSelectedScheduleType(type)
    }

    const handleWeeklyAnchorChange = (day: WeeklyAnchorType) => {
        setSelectedWeeklyAnchor(day)
    }

    const handleMonthlyAnchorChange = (date: number) => {
        setSelectedMonthlyAnchor(date)
    }

    const handleMinimumInstantPayoutBalanceSave = async () => {
        await refetch()
    }

    const handleSave = async () => {
        // Ensure that we don't submit any changes if nothing has changed
        if (
            selectedAccountType === accountType &&
            selectedScheduleType === schedule
        ) {
            if (schedule === 'weekly' && selectedWeeklyAnchor === weeklyAnchor) {
                return
            }
            if (schedule === 'monthly' && selectedMonthlyAnchor === monthlyAnchor) {
                return
            }
            if (schedule === 'daily' || schedule === 'instant' || schedule === 'manual') {
                return
            }
        }

        setIsSaving(true)

        let config: ScheduledPayoutConfig | null = null
        switch (selectedScheduleType) {
            case 'manual':
                config = null
                break
            case 'instant':
                config = {
                    type: 'instant',
                }
                break
            case 'daily':
                config = {
                    type: 'daily',
                }
                break
            case 'weekly':
                config = {
                    type: 'weekly',
                    weeklyAnchor: selectedWeeklyAnchor || 'thursday',
                }
                break
            case 'monthly':
                config = {
                    type: 'monthly',
                    monthlyAnchor: selectedMonthlyAnchor || 1,
                }
                break
        }

        const payload = {
            accountType: selectedAccountType || null,
            config,
        }

        await supportUpdateSchedule(merchantId, payload)
            .catch((e) => {
                console.log(e)
                window.alert(e.message)
            })
            .finally(() => setIsSaving(false))

        await refetch()
    }

    const handlePause = async () => {
        setIsChangingPause(true)

        await supportUpdateSchedule(merchantId, {
            accountType,
            paused: !paused,
        })
            .catch((e) => {
                console.log(e)
                window.alert(e.message)
                setIsChangingPause(false)
            })

        setTimeout(async () => {
            try {
                await refetch()
            } finally {
                setIsChangingPause(false)
            }
        }, 1000)
    }

    const handleForceManual = async () => {
        setIsChangingForceManual(true)
        setIsChangingPause(true)

        await supportForceManualPayouts(merchantId, !forcedManualPayouts)
            .catch((e) => {
                console.log(e)
                window.alert(e.message)
                setIsChangingPause(false)
                setIsChangingForceManual(false)
            })

        setTimeout(async () => {
            try {
                await refetch()
            } finally {
                setIsChangingPause(false)
                setIsChangingForceManual(false)
            }
        }, 1000)
    }

    return (
        <>
            <AvForm>
                <Row>
                    <Col xs={12} sm={4} className="mb-3">
                        <Label className="form-Label">
                            Account Type {selectedAccountType !== accountType && <span className="text-primary">*</span>}
                        </Label>
                        <select
                            className={`form-select ${selectedAccountType !== accountType && 'border-primary'}`}
                            value={String(selectedAccountType)}
                            onChange={async (e) => {
                                console.log('e.target:', e.target.value)
                                const accountType = e.target.value as AccountType
                                handleSelectedAccountTypeChange(accountType)
                            }}
                        >
                            {availableAccountTypes?.map(
                                (at) => (
                                    <option
                                        key={at}
                                        value={at}
                                    >
                                        {at === 'premium' ? 'instant to bank (premium)' : at}
                                    </option>
                                )
                            )}
                        </select>
                    </Col>
                    <Col xs={12} sm={4} className="mb-3">
                        <Label className="form-Label">
                            Schedule {selectedScheduleType !== schedule && <span className="text-primary">*</span>}
                        </Label>
                        <select
                            className={`form-select ${selectedScheduleType !== schedule && 'border-primary'}`}
                            value={String(selectedScheduleType)}
                            onChange={async (e) => {
                                console.log('e.target:', e.target.value)
                                const type = e.target.value as ScheduleType
                                handleSelectedTypeChange(type)
                            }}
                        >
                            {availableScheduleTypes?.map(
                                (st) => (
                                    <option
                                        key={st}
                                        value={String(st)}
                                    >
                                        {st}
                                    </option>
                                )
                            )}
                        </select>
                    </Col>
                    {
                        (selectedScheduleType === 'weekly' || selectedScheduleType === 'monthly') &&
                        <Col xs={12} sm={4} className="mb-3">
                            {
                                selectedScheduleType === 'weekly' &&
                                <WeeklyAnchor original={weeklyAnchor} selected={selectedWeeklyAnchor || 'thursday'} onChange={handleWeeklyAnchorChange} />
                            }
                            {
                                selectedScheduleType === 'monthly' &&
                                <MonthlyAnchor original={monthlyAnchor} selected={selectedMonthlyAnchor || 1} onChange={handleMonthlyAnchorChange} />
                            }
                        </Col>
                    }
                </Row>
                <Row>
                    <Col sm={12} className="mb-3 d-inline-flex">
                        <button
                            type="button"
                            className="btn btn-primary py-0 pe-2 px-1 d-flex align-items-center"
                            disabled={isSaving}
                            onClick={handleSave}
                        >
                            { isSaving
                                ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                : <i className="mdi mdi-content-save font-size-24"></i>
                            }
                            <span className="ms-1">
                            Save
                        </span>
                        </button>
                        {
                            paused
                                ? (
                                    <button
                                        type="button"
                                        disabled={isChangingPause || forcedManualPayouts}
                                        className="btn btn-primary ms-2 py-0 pe-2 px-1 d-flex align-items-center"
                                        onClick={handlePause}
                                    >
                                        { isChangingPause
                                            ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                            : <i className="mdi mdi-play font-size-24"></i>
                                        }
                                        <span className="ms-1">
                                        Unpause
                                    </span>
                                    </button>
                                )
                                : (
                                    <button
                                        type="button"
                                        disabled={isChangingPause || forcedManualPayouts}
                                        className="btn btn-primary ms-2 py-0 pe-2 px-1 d-flex align-items-center"
                                        onClick={handlePause}
                                    >
                                        { isChangingPause
                                            ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                            : <i className="mdi mdi-pause font-size-24"></i>
                                        }
                                        <span className="ms-1">
                                        Pause
                                    </span>
                                    </button>
                                )
                        }
                        {
                            forcedManualPayouts
                                ? (
                                    <button
                                        type="button"
                                        disabled={isChangingForceManual}
                                        className="btn btn-warning ms-2 py-0 pe-2 px-1 d-flex align-items-center"
                                        onClick={handleForceManual}
                                    >
                                        { isChangingForceManual
                                            ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                            : <i className="mdi mdi-bank-check font-size-24"></i>
                                        }
                                        <span className="ms-1">
                                        Re-enable
                                    </span>
                                    </button>
                                )
                                : (
                                    <button
                                        type="button"
                                        disabled={isChangingForceManual}
                                        className="btn btn-danger ms-2 py-0 pe-2 px-1 d-flex align-items-center"
                                        onClick={handleForceManual}
                                    >
                                        { isChangingForceManual
                                            ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                                            : <i className="mdi mdi-bank-off font-size-24"></i>
                                        }
                                        <span className="ms-1">
                                        Disable
                                    </span>
                                    </button>
                                )
                        }
                    </Col>
                    {
                        forcedManualPayouts &&
                        <Alert color="danger">
                            Scheduled payouts have been <b>disabled</b> for this merchant (i.e. <b>forced to manual</b>).
                            This may have been done manually via support console, or automatically via a fraud rule trigger.
                            <br/><br/>
                            The merchant can still configure their payout schedule, but it will not automatically pay out.
                        </Alert>
                    }
                </Row>
            </AvForm>
            <Row>
                <Col xs={12} sm={6} className="mb-3">
                    <MinimumInstantPayoutBalanceInput
                        merchantId={merchantId}
                        currencyCode={currencyCode}
                        original={minimumInstantPayoutBalance}
                        onSave={handleMinimumInstantPayoutBalanceSave}
                    />
                </Col>
            </Row>
        </>
    )
}

export default AccountTypeSelector