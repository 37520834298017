import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { ProfileTypes } from "./actionTypes"
import { profileSuccess, profileError, fetchProfileSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  fetchUserProfile,
  postJwtProfile,
} from "../../../helpers/lopay_api_helper"
import UserProfileResponse from "../../../api-dtos/profile/user-profile-response.dto.interface"

function* editProfile({ payload: { user } }: any) {
  try {
    const response: Promise<any> = yield call(postJwtProfile, {
      username: user.username,
      idx: user.idx,
    })
    yield put(profileSuccess(response))

  } catch (error) {
    yield put(profileError(error))
  }
}

// eslint-disable-next-line
function* fetchProfile({ payload: { } }: { payload: { userId: string }, type: ProfileTypes }) {
  try {
    const response: Promise<UserProfileResponse> = yield call(fetchUserProfile)
    localStorage.setItem("profileUser", JSON.stringify(response));
    yield put(fetchProfileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(ProfileTypes.UPDATE_EDIT_PROFILE, editProfile)
  yield takeEvery(ProfileTypes.FETCH_PROFILE, fetchProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
