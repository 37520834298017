import { useQuery } from 'react-query'

import MerchantApiDto from 'src/api-dtos/merchant/merchant.dto.interface'
import { getRewards } from './support-merchant-rewards.api'

export function useRewards({
    merchant,
    isActive,
}: {
    merchant?: MerchantApiDto
    isActive: boolean
}) {
    const merchantId = merchant?.id
    const ownerId = (merchant?.associatedUsers ?? []).find(
        (user) => user.isOwner
    )?.id

    return useQuery({
        queryKey: ['userRewards', { merchantId, ownerId, isActive }],
        queryFn: () =>
            merchantId && ownerId && isActive
                ? getRewards({ userId: ownerId })
                : Promise.resolve(undefined),
    })
}
