import CurrencyType from '../../../../api-dtos/types/currency.type'
import { convertToUnits, currencyFormat } from '../../../../helpers/utils'
import React, { useState } from 'react'
import { supportUpdateInstantPayoutThreshold } from '../../../../helpers/lopay_api_helper'
import { Button, Input, Label } from 'reactstrap'
import getSymbolFromCurrency from 'currency-symbol-map'

type MinimumInstantPayoutBalanceInputProps = {
    original?: number
    merchantId: string
    currencyCode: CurrencyType
    onSave: () => Promise<void>
}

const MinimumInstantPayoutBalanceInput = (props: MinimumInstantPayoutBalanceInputProps) => {
    const { merchantId, currencyCode, original, onSave } = props

    const originalValue = currencyFormat(original || 100)
    const [value, setValue] = useState<string>(originalValue)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleOnClick = async () => {
        setIsLoading(true)
        const units = convertToUnits(value)
        await supportUpdateInstantPayoutThreshold(merchantId, units)
            .then(async () => {
                await onSave()
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <Label className="form-Label">
                Minimum Instant Payout Balance {originalValue !== value && <span className="text-primary">*</span>}
            </Label>
            <div className="input-group">
                <div className="input-group-text">{getSymbolFromCurrency(currencyCode)}</div>
                <Input
                    type="text"
                    className={`form-control ${originalValue !== value && 'border-primary'}`}
                    placeholder="1.00"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                />
                <Button
                    className="input-group-text py-0 px-2"
                    color="primary"
                    onClick={handleOnClick}
                    disabled={isLoading}
                >
                    {
                        isLoading
                            ? <i className="mdi mdi-loading mdi-spin font-size-24"></i>
                            : <i className="mdi mdi-content-save font-size-24"></i>
                    }
                </Button>
            </div>
        </div>
    )
}

export default MinimumInstantPayoutBalanceInput