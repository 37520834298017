import { useQuery } from 'react-query'

import { getMerchantAccountSetup } from './support-merchant-account-setup.api'

export function useMerchantAccountSetup({
    merchantId,
    isActiveTab,
}: {
    merchantId: string | undefined
    isActiveTab: boolean
}) {
    return useQuery({
        queryKey: ['merchantAccountSetup', { merchantId, isActiveTab }],
        queryFn: () =>
            merchantId && isActiveTab
                ? getMerchantAccountSetup({ merchantId })
                : Promise.resolve(undefined),
    })
}
