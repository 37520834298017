import React, { useState } from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import useDebounce from 'react-use/lib/useDebounce';
import { Container, Col, Card, CardBody, CardHeader, Input, Label, Row } from 'reactstrap'
import { OpenBankingVRPConsentStatusType } from '../../api-dtos/support/external-account/support-external-account.dto';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import ExternalAccountOpenBankingVrpTable from './external-account-open-banking-vrp-table/external-account-open-banking-vrp-table.component';

type IComponentProps = {}

const Spacer = styled.div`
    padding: 10px 0;
`

const ExternalAccountOpenBankingVRPsPage: React.FC<IComponentProps> = () => {
    const [payoutScheduleFilter, setPayoutScheduleFilter] = useState<'t2' | 't1' | 'instant' | undefined>('instant')
    const [consentStatusFilter, setConsentStatusFilter] = useState<OpenBankingVRPConsentStatusType | undefined>()
    const [kybStatusFilter, setKybStatusFilter] = useState<'not_reviewed' | 'approved' | 'rejected' | undefined>()
    const [minimumTotalPaymentUnitsSumFilter, setMinimumTotalPaymentUnitsSumFilter] = useState<number | undefined>()
    const [minimumTotalPaymentUnitsSumVal, setMinimumTotalPaymentUnitsSumVal] = useState<string | undefined>()
    const [searchFilter, setSearchFilter] = useState<string | undefined>()
    const [searchVal, setSearchVal] = useState<string | undefined>()

    useDebounce(() => {
        if (!minimumTotalPaymentUnitsSumVal) {
            setMinimumTotalPaymentUnitsSumFilter(undefined)
            return
        }

        setMinimumTotalPaymentUnitsSumFilter(parseInt(minimumTotalPaymentUnitsSumVal, 10) * 100)
    }, 300, [minimumTotalPaymentUnitsSumVal])

    useDebounce(() => {
        if (!searchVal || (searchVal && searchVal.length === 0)) {
            setSearchFilter(undefined)
            return
        }

        setSearchFilter(searchVal)
    }, 300, [searchVal])

    const consentStatusChoices: {
        label: string
        value: OpenBankingVRPConsentStatusType | undefined
    }[] = [{
        label: 'Select consent status',
        value: undefined,
    }, {
        label: 'Authorised',
        value: 'Authorised',
    }, {
        label: 'Awaiting Authorisation',
        value: 'AwaitingAuthorisation',
    }, {
        label: 'Rejected',
        value: 'Rejected',
    }, {
        label: 'Revoked',
        value: 'Revoked',
    }, {
        label: 'Expired',
        value: 'Expired',
    }]

    const payoutScheduleChoices: {
        label: string
        value: 't2' | 't1' | 'instant' | undefined
    }[] = [{
        label: 'Select payment schedule',
        value: undefined,
    }, {
        label: 'Instant',
        value: 'instant',
    }, {
        label: 'Daily',
        value: 't1',
    }, {
        label: 'Weekly',
        value: 't2',
    }]

    const kybChoices: {
        label: string
        value: 'not_reviewed' | 'approved' | 'rejected' | undefined
    }[] = [{
        label: 'Select KYB status',
        value: undefined,
    }, {
        label: 'Not reviewed',
        value: 'not_reviewed',
    }, {
        label: 'Approved',
        value: 'approved',
    }, {
        label: 'Rejected',
        value: 'rejected',
    }]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Open Banking VRP consents</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title={"LoPay Support"} breadcrumbItem="Open Banking VRP consents" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={4}>
                                            <Label className="visually-hidden" htmlFor="specificSizeInputName">
                                                Search by merchant ID
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by merchant name or ID"
                                                onChange={(e) => setSearchVal(e.target.value)}
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <Label className="visually-hidden" htmlFor="specificSizeInputName">
                                                KYB Status
                                            </Label>
                                            <select className="form-select" value={kybStatusFilter} onChange={(e) => {
                                                if (e.target.value === 'Select KYB status') {
                                                    setKybStatusFilter(undefined)
                                                } else {
                                                    setKybStatusFilter(e.target.value as 'not_reviewed' | 'approved' | 'rejected') 
                                                }                                         
                                            }}>
                                                {kybChoices.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select> 
                                        </Col>
                                        <Col sm={4}>
                                            <Label className="visually-hidden" htmlFor="specificSizeInputName">
                                                Minimum total payment volume
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Minimum total payment volume (£££)"
                                                onChange={(e) => setMinimumTotalPaymentUnitsSumVal(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Spacer />
                                    <Row>
                                        <Col sm={4}>
                                            <Label className="visually-hidden" htmlFor="specificSizeInputName">
                                                Payout schedule
                                            </Label>
                                            <select className="form-select" value={payoutScheduleFilter} onChange={(e) => {
                                                if (e.target.value === 'Select payment schedule') {
                                                    setPayoutScheduleFilter(undefined)
                                                } else {
                                                    setPayoutScheduleFilter(e.target.value as 't2' | 't1' | 'instant') 
                                                }                                          
                                            }}>
                                                {payoutScheduleChoices.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>      
                                        </Col>
                                        <Col sm={4}>
                                            <Label className="visually-hidden" htmlFor="specificSizeInputName">
                                                Consent status
                                            </Label>
                                            <select className="form-select" value={consentStatusFilter} onChange={(e) => {
                                                if (e.target.value === 'Select consent status') {
                                                    setConsentStatusFilter(undefined)
                                                } else {
                                                    setConsentStatusFilter(e.target.value as OpenBankingVRPConsentStatusType)
                                                }                                                
                                            }}>
                                                {consentStatusChoices.map((option) => (
                                                    <option value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col sm={4} />
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <ExternalAccountOpenBankingVrpTable
                                        search={searchFilter}
                                        status={consentStatusFilter}
                                        payoutSchedule={payoutScheduleFilter}
                                        minimumTotalPaymentUnitsSum={minimumTotalPaymentUnitsSumFilter}
                                        kybStatus={kybStatusFilter}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ExternalAccountOpenBankingVRPsPage