import { GetRequest, PostRequest } from 'src/helpers/lopay_api_helper'
import { PaginatedWaitingListMerchants } from './waiting-list-merchants.type'

export function getMerchantsOnWaitingList({
    waitingListId,
    pageSize,
    filters,
}: {
    waitingListId: string
    pageSize?: number
    filters: {
        grantAccessAt?: Date | null
        search?: string
    }
}) {
    const { grantAccessAt, search } = filters

    return function ({
        pageParam,
    }: {
        pageParam?: string
    }): Promise<PaginatedWaitingListMerchants> {
        return GetRequest(`support/waiting-lists/${waitingListId}/merchants`, {
            params: {
                ...(pageParam && { offsetId: pageParam }),
                ...(pageSize && { pageSize }),
                ...(search && { search }),
                ...(grantAccessAt === null && {
                    grantAccessAt: 'null',
                }),
                ...(grantAccessAt !== null && grantAccessAt !== undefined && {
                    grantAccessAt: filters.grantAccessAt,
                }),
            },
        })
    }
}

export function bulkUpdateMerchantGrantAccessOnWaitingList(
    waitingListId: string,
    data: {
        merchantIds: string[]
        grantAccessAt: Date | null
    }
): Promise<void> {
    return PostRequest<void>(
        `support/waiting-lists/${waitingListId}/merchants/grant-access-at`,
        data
    )
}
