import { PostRequest } from 'src/helpers/lopay_api_helper'

export function updateInstantPayoutsEnabled({
    merchantId,
    instantPayoutsEnabled,
}: {
    merchantId: string
    instantPayoutsEnabled: boolean
}): Promise<void> {
    return PostRequest<void>(
        `support/merchant/${merchantId}/payouts/instant-payouts/enabled`,
        {
            instantPayoutsEnabled,
        }
    )
}

export function updateAllowForeignEcommercePayments({
    merchantId,
    allowForeignEcommercePayments,
}: {
    merchantId: string
    allowForeignEcommercePayments: boolean
}): Promise<void> {
    return PostRequest<void>(
        `support/merchant/${merchantId}/kyc/allow-foreign-ecommerce-payments`,
        {
            allowForeignEcommercePayments,
        }
    )
}

export function updateAllowForeignCardPayments({
    merchantId,
    allowForeignCardPayments,
}: {
    merchantId: string
    allowForeignCardPayments: boolean
}): Promise<void> {
    return PostRequest<void>(
        `support/merchant/${merchantId}/kyc/allow-foreign-card-payments`,
        {
            allowForeignCardPayments,
        }
    )
}

export function updateAllowTTPPayments({
    merchantId,
    allowTTPPayments,
}: {
    merchantId: string
    allowTTPPayments: boolean
}): Promise<void> {
    return PostRequest<void>(
        `support/merchant/${merchantId}/kyc/allow-tap-to-pay-payments`,
        {
            allowTTPPayments,
        }
    )
}

export function updateAdditionalPayoutDelay({
    merchantId,
    additionalPayoutDelayEnabled,
}: {
    merchantId: string
    additionalPayoutDelayEnabled: boolean
}): Promise<void> {
    return PostRequest<void>(
        `support/merchant/${merchantId}/payouts/stripe-payout-schedule/additional-payout-delay`,
        {
            additionalPayoutDelayEnabled,
        }
    )
}
