import React, { useCallback, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { useParams } from 'react-router'
import useDebounce from 'react-use/lib/useDebounce'
import {
    Container,
    Col,
    Card,
    CardBody,
    CardHeader,
    Row,
    Label,
    Input,
    Button,
} from 'reactstrap'
import styled from 'styled-components'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import WaitingListMerchantsTable from './components/waiting-list-merchants-table/waiting-list-merchants-table.component'
import {
    useBulkUpdateGrantAccessOnMutation,
    useWaitingListMerchants,
} from './waiting-list-merchants.hook'

const Spacer = styled.div`
    padding: 10px 0;
`

const HorizontalSpacer = styled.div`
    padding: 0 5px;
`

const RightAlignedContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    height: fit-content;
`

type IComponentProps = {}

const WaitingListMerchants: React.FC<IComponentProps> = () => {
    const { id } = useParams<{ id: string }>()

    const [selectedMerchantIds, setSelectedMerchantIds] = useState<string[]>([])

    const [bulkGrantAccessDate, setBulkGrantAccessDate] = useState<
        string | undefined
    >(undefined)

    const [pageSize] = useState<number>(100)

    const [hasNoGrantAccessDate, setHasNoGrantAccessDate] =
        useState<boolean>(true)

    const [search, setSearch] = useState<string | undefined>(undefined)

    const [debouncedSearch, setDebouncedSearch] = useState<string | undefined>(
        undefined
    )

    // Debounce the search
    useDebounce(
        () => {
            setDebouncedSearch(search)
        },
        300,
        [search]
    )

    const { data, isLoading, isError, hasNextPage, fetchNextPage } =
        useWaitingListMerchants({
            waitingListId: id,
            pageSize,
            filters: {
                search: debouncedSearch,
                grantAccessAt: hasNoGrantAccessDate ? null : undefined,
            },
        })

    const bulkUpdateGrantAccessOnMutation = useBulkUpdateGrantAccessOnMutation({
        setSelectedMerchantIds,
        waitingListId: id,
        merchantIds: selectedMerchantIds,
        grantAccessAt: bulkGrantAccessDate
            ? new Date(bulkGrantAccessDate)
            : null,
    })

    const toggleSelectedMerchantId = useCallback(
        (merchantId: string) => {
            setSelectedMerchantIds((merchantIds) => {
                const isSelected =
                    merchantIds.find((mid) => mid === merchantId) !== undefined

                return isSelected
                    ? merchantIds.filter((mid) => mid !== merchantId)
                    : merchantIds.concat([merchantId])
            })
        },
        [setSelectedMerchantIds]
    )

    const isMerchantSelected = useCallback(
        (merchantId: string) => {
            return (
                selectedMerchantIds.find((mid) => mid === merchantId) !==
                undefined
            )
        },
        [selectedMerchantIds]
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Update waiting list</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={'LoPay Support'}
                        breadcrumbItem="Update waiting list"
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col sm={4}>
                                            <Label
                                                className="visually-hidden"
                                                htmlFor="specificSizeInputName"
                                            >
                                                Search by merchant name or ID
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by merchant name or ID"
                                                onChange={(e) =>
                                                    setSearch(e.target.value)
                                                }
                                            />
                                        </Col>
                                        <Col sm={4}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                }}
                                            >
                                                <div className="form-check">
                                                    <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="has-no-grant-access"
                                                        checked={
                                                            hasNoGrantAccessDate
                                                        }
                                                        onClick={() =>
                                                            setHasNoGrantAccessDate(
                                                                (prevValue) =>
                                                                    !prevValue
                                                            )
                                                        }
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="has-no-grant-access"
                                                    >
                                                        Only merchants with no
                                                        access date
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <div>Instructions:</div>
                                        <ol>
                                            <li>Select the merchants you want to set the access date for, or tick the 'Select all' box in the top left corner of the table below.</li>
                                            <li>Choose a date and time for the selected merchants to be granted access to the feature.</li>
                                            <li>Click the 'Bulk update' button.</li>
                                        </ol>
                                    </div>
                                    <RightAlignedContainer>
                                        <div>
                                            <Input
                                                className="form-control"
                                                type="datetime-local"
                                                id="bulk-grant-access-at"
                                                disabled={
                                                    selectedMerchantIds.length ===
                                                    0
                                                }
                                                onChange={(e) =>
                                                    setBulkGrantAccessDate(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                        <HorizontalSpacer />
                                        <div>
                                            <Button
                                                type="button"
                                                color="primary"
                                                disabled={
                                                    selectedMerchantIds.length ===
                                                        0 ||
                                                    bulkUpdateGrantAccessOnMutation.isLoading
                                                }
                                                onClick={() =>
                                                    bulkUpdateGrantAccessOnMutation.mutate()
                                                }
                                            >
                                                Bulk update
                                            </Button>
                                        </div>
                                    </RightAlignedContainer>
                                    <Spacer />
                                    <RightAlignedContainer>
                                        <div>Total visible: {data?.pages.flatMap((page) => page.data).length ?? 0}</div>
                                    </RightAlignedContainer>
                                    <Spacer />
                                    <WaitingListMerchantsTable
                                        data={data}
                                        isLoading={isLoading}
                                        isError={isError}
                                        toggleSelectedMerchantId={
                                            toggleSelectedMerchantId
                                        }
                                        isMerchantSelected={isMerchantSelected}
                                    />
                                    <Spacer />
                                    {hasNextPage && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={() => fetchNextPage()}
                                        >
                                            Load more
                                        </Button>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default WaitingListMerchants
