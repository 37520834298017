import z from 'zod'
import { isNil } from 'lodash'

export type QuickBooksSyncingConfigSectionType =
    | 'pos'
    | 'fees'
    | 'payouts'
    | 'payments'
    | 'working_capital'

export type QuickBooksSyncingConfigSubsectionType =
    | 'pos_sales_account'
    | 'pos_refunds_account'
    | 'pos_tipping_account'
    | 'fees_account'
    | 'payouts_account'
    | 'payments_clearing_account'
    | 'payments_cash_account'
    | 'payments_payment_links_account'
    | 'payments_terminal_account'
    | 'working_capital_account'

export type QuickbooksStatusResponse = {
    connected: boolean
    companyName: string
    status: string
    lastSyncDate?: string
}

export interface QuickBooksAvailableAccount {
    id: string
    name: string
}

export interface QuickBooksSyncingConfigSubsectionDto {
    type: QuickBooksSyncingConfigSubsectionType
    title?: string
    description?: string
    accountId?: string | null
    availableAccounts?: QuickBooksAvailableAccount[]
}

export interface QuickBooksSyncingConfigSectionDto {
    type: QuickBooksSyncingConfigSectionType
    title?: string
    description?: string
    enabled?: boolean
    subsections: QuickBooksSyncingConfigSubsectionDto[]
}

export interface QuickBooksSyncingConfigDto {
    importFromDate: string
    sections: QuickBooksSyncingConfigSectionDto[]
}

export const UpdateQuickBooksSyncingConfigSchema = z
    .object({
        importFromDate: z.string(),
        sections: z
            .object({
                type: z.enum([
                    'pos',
                    'fees',
                    'payouts',
                    'payments',
                    'working_capital',
                    'cash_advance',
                ]),
                enabled: z.boolean().optional(),
                subsections: z
                    .object({
                        type: z.enum([
                            'pos_sales_account',
                            'pos_refunds_account',
                            'pos_tipping_account',
                            'fees_account',
                            'payouts_account',
                            'payments_clearing_account',
                            'payments_cash_account',
                            'payments_payment_links_account',
                            'payments_terminal_account',
                            'working_capital_account',
                            'cash_advance_liability_account',
                            'cash_advance_fee_liability_account',

                        ]),
                        accountId: z.string().nonempty().nullable(),
                    })
                    .array(),
            })
            .array(),
    })
    .refine(
        (val) =>
            !isNil(
                (val.sections ?? []).find((section) => section.type === 'pos')
            ),
        {
            message: 'Missing sales section',
            path: ['sections'],
        }
    )
    .superRefine((val, ctx) => {
        const posSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'pos'
        )
        const posSection = (val.sections ?? [])[posSectionIndex]

        const subsectionIndex = (posSection?.subsections ?? []).findIndex(
            (subsection) => subsection.type === 'pos_sales_account'
        )
        const subsection = (posSection?.subsections ?? [])[subsectionIndex]

        const isValid = Boolean(subsection?.accountId)

        if (!isValid) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    posSectionIndex,
                    'subsections',
                    subsectionIndex,
                    'accountId',
                ],
                message: 'Must specify Lopay sales account',
            })
        }
    })
    .superRefine((val, ctx) => {
        const posSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'pos'
        )
        const posSection = (val.sections ?? [])[posSectionIndex]

        const subsectionIndex = (posSection?.subsections ?? []).findIndex(
            (subsection) => subsection.type === 'pos_refunds_account'
        )
        const subsection = (posSection?.subsections ?? [])[subsectionIndex]
        const isValid = Boolean(subsection?.accountId)

        if (!isValid) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    posSectionIndex,
                    'subsections',
                    subsectionIndex,
                    'accountId',
                ],
                message: 'Must specify Lopay refunds account',
            })
        }
    })
    .superRefine((val, ctx) => {
        const posSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'pos'
        )
        const posSection = (val.sections ?? [])[posSectionIndex]

        const subsectionIndex = (posSection?.subsections ?? []).findIndex(
            (subsection) => subsection.type === 'pos_tipping_account'
        )
        const subsection = (posSection?.subsections ?? [])[subsectionIndex]
        const isValid = Boolean(subsection?.accountId)

        if (!isValid) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    posSectionIndex,
                    'subsections',
                    subsectionIndex,
                    'accountId',
                ],
                message: 'Must specify Lopay tips account',
            })
        }
    })
    .superRefine((val, ctx) => {
        const feesSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'fees'
        )
        const feesSection = (val.sections ?? [])[feesSectionIndex]

        const subsectionIndex = (feesSection?.subsections ?? []).findIndex(
            (subsection) => subsection.type === 'fees_account'
        )
        const subsection = (feesSection?.subsections ?? [])[subsectionIndex]
        const isValid = Boolean(subsection?.accountId)

        if (!isValid) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    feesSectionIndex,
                    'subsections',
                    subsectionIndex,
                    'accountId',
                ],
                message: 'Must specify Lopay fees account',
            })
        }
    })
    .refine(
        (val) =>
            !isNil(
                (val.sections ?? []).find(
                    (section) => section.type === 'payments'
                )
            ),
        {
            message: 'Missing payments section',
        }
    )
    .superRefine((val, ctx) => {
        const paymentsSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'payments'
        )
        const paymentsSection = (val.sections ?? [])[paymentsSectionIndex]

        const clearingsIndex = (paymentsSection?.subsections ?? []).findIndex(
            (subsection) => subsection.type === 'payments_clearing_account'
        )

        const clearingsSubsection = (paymentsSection?.subsections ?? [])[
            clearingsIndex
        ]

        const isValid = Boolean(clearingsSubsection?.accountId)

        if (!isValid) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    paymentsSectionIndex,
                    'subsections',
                    clearingsIndex,
                    'accountId',
                ],
                message: 'Must specify Lopay clearings account',
            })

            return
        }

        const payoutsSectionIndex = (val.sections ?? []).findIndex(
            (section) => section.type === 'payouts'
        )
        const payoutsSection = (val.sections ?? [])[payoutsSectionIndex]

        const payoutsAccountIndex = (
            payoutsSection?.subsections ?? []
        ).findIndex((subsection) => subsection.type === 'payouts_account')

        const payoutsSubsection = (payoutsSection?.subsections ?? [])[
            payoutsAccountIndex
        ]

        if (
            Boolean(payoutsSubsection.accountId) &&
            payoutsSubsection.accountId === clearingsSubsection.accountId
        ) {
            ctx.addIssue({
                code: 'custom',
                path: [
                    'sections',
                    payoutsSectionIndex,
                    'subsections',
                    payoutsAccountIndex,
                    'accountId',
                ],
                message: 'Lopay clearings account and Lopay payout account cannot be the same',
            })
        }
    })

export type UpdateQuickBooksSyncingConfig = z.infer<
    typeof UpdateQuickBooksSyncingConfigSchema
>
