import React, { useState } from 'react'
import { isNil } from 'lodash'
import { Spinner } from 'reactstrap'

type BlockMaintainedToggleProps = {
    currentBlockMaintainedStatus?: boolean,
    merchantId: string,
    setBlockMaintained: (status: boolean) => Promise<void>
}

/**
 * Component handling the toggling of merchant's block maintained status. A merchant can only be marked with the block
 * maintained status when they are banned.
 *
 * @param currentBlockMaintainedStatus - The current block maintained status of the merchant.
 * @param setBlockMaintained - The function handling the block maintained status change.
 * @constructor
 */
const BlockMaintainedToggle = ({
    currentBlockMaintainedStatus,
    setBlockMaintained,
}: BlockMaintainedToggleProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onChange = async () => {
        if (!isNil(currentBlockMaintainedStatus)) {
            const isBlockMaintained = !currentBlockMaintainedStatus

            setIsLoading(true)

            await setBlockMaintained(isBlockMaintained)

            setIsLoading(false)
        }
    }

    return (
        <div className="form-check mt-2">
            <input
                onChange={onChange}
                disabled={isLoading}
                checked={currentBlockMaintainedStatus}
                className="form-check-input"
                type="checkbox"
                id="checkbox-is-block-maintained"
            />
            {
                isLoading &&
                    <Spinner
                        style={{ marginRight: 6 }}
                        color="primary"
                        size="sm"
                    />
            }
            <label
                className="form-check-label"
                htmlFor="checkbox-is-block-maintained"
            >
                Mark account as block maintained
            </label>
        </div>
    )
}

export default BlockMaintainedToggle
