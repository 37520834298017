import { lighten, rgba } from 'polished';

/** Brand colour variables */
const brand = {
	black: '#000000',
	white: '#ffffff',
	body: '#101010',
	neutral: '#f2f2f2',
	neutralLight: '#fefefe',
	borders: rgba(0, 0, 0, 0.2),
	text: '#fff',
	textLight: '#545454',
	placeholder: rgba('#000000', 0.6),
	selectBackground: '#e9edf3',
	get headings() {
		return this.text;
	},
	primary: '#4F80FF',
	secondary: '#5AF091',
	genericError: '#D63636',
	get link() {
		return this.primary;
	},
	get linkHover() {
		return lighten(0.02, this.primary);
	},
	get linkActive() {
		return lighten(0.08, this.primary);
	},
	get validationDisabled() {
		return this.neutral;
	},
	validationError: '#dc3545',
	validationSuccess: '#28a745',
	validationWarning: '#ffc107',
};

export default brand;
