import React from 'react'
import { Badge, Modal, Spinner } from 'reactstrap'
import styled from 'styled-components'

import { usePayoutTransactions } from '../../support-merchant-payouts.hook'
import PayoutTimeline from '../payout-timeline/payout-timeline.component'
import PayoutPaymentsTable from '../payout-payments-table/payout-payments-table.component'
import { priceFormat } from 'src/helpers/utils'

const StyledCenteredModal = styled.div`
    display: flex;
    justify-content: center;
`

const StyledModalBody = styled.div``

const StyledPayoutAmountContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
`

const StyledPayoutAmount = styled.h6`
    font-size: 1.5rem;
    margin-bottom: 0;
`

const StyledPayoutAdditionalPayoutFee = styled.div`
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
`

const StyledStatus = styled.div`
    margin-left: 0.5rem;
`

const StyledSpacer = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`

type IComponentProps = {
    toggle: () => void
    merchantId: string
    payoutId: string | undefined
}

const PayoutDetailsModal: React.FC<IComponentProps> = ({
    toggle,
    merchantId,
    payoutId,
}) => {
    const copyMerchantId = (id: string) => {
        navigator.clipboard.writeText(id).then(
            function () {},
            function (err) {}
        )
    }

    const { data, isLoading, isError } = usePayoutTransactions({
        merchantId,
        payoutId,
        pageSize: 1000,
    })

    const payout = data?.pages[0]?.payout

    return (
        <Modal size="lg" isOpen={!!payoutId} toggle={toggle}>
            <div className="modal-header">
                <div>
                    <h5 className="modal-title mt-0" id="payout-details-modal">
                        Payout Details{' '}
                        {payout?.providerPayoutId && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                                href="#"
                                onClick={() =>
                                    copyMerchantId(payout.providerPayoutId)
                                }
                            >
                                [{payout.providerPayoutId}]{' '}<i className="bx bx-paste"></i>
                            </a>
                        )}
                    </h5>
                </div>
                <button
                    type="button"
                    onClick={() => {
                        toggle()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {isLoading && (
                    <StyledCenteredModal>
                        <Spinner className="ms-2" color="primary" />
                    </StyledCenteredModal>
                )}
                {isError && (
                    <div>Error loading payout details. Please try again.</div>
                )}
                {!isLoading && !isError && payout && (
                    <StyledModalBody>
                        <StyledPayoutAmountContainer>
                            <StyledPayoutAmount>
                                {priceFormat(payout.amount)}{' '}
                            </StyledPayoutAmount>
                            <StyledStatus>
                                {payout.status === 'paid' && (
                                    <Badge className="bg-success">Paid</Badge>
                                )}
                                {(payout.status === 'pending' ||
                                    payout?.status === 'in_transit') && (
                                    <Badge className="bg-warning">
                                        Pending
                                    </Badge>
                                )}
                                {payout.status === 'failed' && (
                                    <Badge className="bg-danger">Failed</Badge>
                                )}
                                {payout.status === 'canceled' && (
                                    <Badge className="bg-danger">
                                        Canceled
                                    </Badge>
                                )}
                            </StyledStatus>
                        </StyledPayoutAmountContainer>
                        <StyledPayoutAdditionalPayoutFee>
                            Payout fee {priceFormat(payout.payoutFee)}
                        </StyledPayoutAdditionalPayoutFee>
                        <PayoutTimeline payout={payout} />
                        <StyledSpacer />
                        <PayoutPaymentsTable
                            data={data}
                            isLoading={isLoading}
                            isError={isError}
                        />
                    </StyledModalBody>
                )}
            </div>
        </Modal>
    )
}

export default PayoutDetailsModal
