import { supportToggleIsUnderReview } from '../../../../../helpers/lopay_api_helper'
import React, { useState } from 'react'
import { Spinner } from 'reactstrap'

type UnderReviewToggleProps = {
    isUnderReview?: boolean
    merchantId: string
    setIsUnderReview: (status: boolean) => void
}

/**
 * Component handling the toggle of a merchant's under review status.
 *
 * @param isUnderReview - The merchant's current review status.
 * @param merchantId - The ID of the merchant.
 * @param setIsUnderReview - The function handling the state change for the status.
 * @constructor
 */
const UnderReviewToggle = ({
    isUnderReview = false,
    merchantId,
    setIsUnderReview
}: UnderReviewToggleProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const onChange = () => {
        const updatedIsUnderReview = !isUnderReview

        setIsUnderReview(updatedIsUnderReview)

        setIsLoading(true)
        supportToggleIsUnderReview(
            merchantId,
            updatedIsUnderReview
        )
            .catch((e) => {
                alert('Could not update under review status' + e)
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <input
                onChange={onChange}
                checked={isUnderReview}
                className="form-check-input"
                type="checkbox"
                id="checkbox-is-under-review"
            />
            {
                isLoading &&
                    <Spinner
                        style={{ marginRight: 6 }}
                        color="primary"
                        size="sm"
                    />
            }
            <label
                className="form-check-label"
                htmlFor="checkbox-is-under-review"
            >
                { isUnderReview ? 'Account is under review' : 'Mark account as under review' }
            </label>
        </>
    )
}

export default UnderReviewToggle
