import { useKYBSettingsQuery } from './hooks/kyb-settings.hook'
import { CardBody } from 'reactstrap'
import KYBStatusSelector from './components/kyb-status-selector/kyb-status-selector.component'
import KYBSettings from './components/kyb-settings/kyb-settings.component'
import KYB3DS2Limits from './components/kyb-limits/kyb-3ds2-limits.component'
import KYBAdditionalDocumentsSelector
    from './components/kyb-additional-documents-selector/kyb-additional-documents-selector.component'

type SupportMerchantKYBProps = {
    merchantId: string
}

const SupportMerchantKYB = (props: SupportMerchantKYBProps) => {
    const { merchantId } = props

    const query = useKYBSettingsQuery(merchantId)

    if (query.isLoading) {
        return <div>Loading...</div>
    }

    if (query.isError || !query.data) {
        return <div>Error!</div>
    }

    return (
        <CardBody>
            <KYBStatusSelector merchantId={merchantId} kybStatus={query.data.kybStatus} refetchFn={query.refetch} />
            <br/>
            <KYBSettings merchantId={merchantId} kybSettings={query.data} refetchFn={query.refetch} />
            <br/>
            <KYB3DS2Limits merchantId={merchantId} limits={query.data} refetchFn={query.refetch} />
            <br/>
            <KYBAdditionalDocumentsSelector
                merchantId={merchantId}
                additionalDocumentsRequestStatus={query.data.additionalDocumentsRequestStatus}
                refetchFn={query.refetch}
            />
        </CardBody>
    )
}

export default SupportMerchantKYB