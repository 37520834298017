import { useQuery } from 'react-query'

import MerchantApiDto from 'src/api-dtos/merchant/merchant.dto.interface'
import { getRewardChallenges } from './support-merchant-reward-challenges.api'

export function useRewardChallenges({
    merchant,
    isActive,
}: {
    merchant?: MerchantApiDto
    isActive: boolean
}) {
    const merchantId = merchant?.id
    const ownerId = (merchant?.associatedUsers ?? []).find(
        (user) => user.isOwner
    )?.id

    return useQuery({
        queryKey: ['userRewardChallenges', { merchantId, ownerId, isActive }],
        queryFn: () =>
            merchantId && ownerId && isActive
                ? getRewardChallenges({ userId: ownerId })
                : Promise.resolve(undefined),
    })
}
