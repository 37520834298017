import { Link } from "react-router-dom"
import ReactTimeAgo from "react-time-ago"
import { Badge } from "reactstrap"
import { UserApiDto } from "src/api-dtos/user/user-api.dto.interface"

interface UserRowProps {
    user: UserApiDto
    onClick: (user: UserApiDto) => void
}

export const UserRow = ({
    user,
    onClick
}: UserRowProps) => {
    const getFullName = (user: UserApiDto) => user ? `${user.firstName} ${user.lastName}` : ''
    return (
        <tr key={user.id}>
            <td
                onClick={async () => {
                    onClick(user)
                }}
            >
                <b>
                    {getFullName(user)}
                </b>
                <br />
                <span>{user.email}</span>
            </td>
            <td>
                <ReactTimeAgo
                    date={
                        new Date(
                            user.createdAt
                        )
                    }
                    locale="en-US"
                />
            </td>
            <td>
            {user.merchants.length}
            </td>
            <td>
                <div className="mt-1">
                    {user
                        .merchants
                        .some(m => m.isOwner) ? (
                        <Badge className="me-2 bg-success">
                            Is a Merchant Owner
                        </Badge>
                    ) : null
                    }
                    
                    {user
                        .emailVerified ? (
                        <Badge className="me-2 bg-success">
                            Verified Email
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            Unverfified Email
                        </Badge>
                    )}

                    {user
                        .invitedForTrustpilotReview ? (
                        <Badge className="me-2 bg-success">
                            Invited For Trustpilot Review
                        </Badge>
                    ) : null}

                    {user
                        .security
                        .smsEnabled ? (
                        <Badge className="me-2 bg-success">
                            Mobile 2FA Enabled
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            No Mobile 2FA
                        </Badge>
                    )}

                    {user
                        .security
                        .passcodeEnabled ? (
                        <Badge className="me-2 bg-success">
                            Passcode Enabled
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            No Passcode
                        </Badge>
                    )}

                    {user
                        .security
                        .biometricsEnabled ? (
                        <Badge className="me-2 bg-success">
                            Biometrics Enabled
                        </Badge>
                    ) : (
                        <Badge className="me-2 bg-danger">
                            No Biometrics
                        </Badge>
                    )}

                    
                </div>
            </td>
            <td>
                <Link
                    onClick={async (
                        e
                    ) => {
                        
                        onClick(user)
                        e.preventDefault()
                    }}
                    to="#"
                    className="btn btn-primary"
                >
                    View account
                </Link>
            </td>
        </tr>
    )
}