import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { AvForm } from 'availity-reactstrap-validation'
import { CardBody, CardTitle, Col, Input, Label, Row } from 'reactstrap'
import MerchantChargeDto from 'src/api-dtos/merchant-charges/merchant-charge-api.dto.interface'
import MerchantChargeCategory from 'src/api-dtos/merchant-charges/merchant-charge-category.type'
import MerchantChargeRequestBody from 'src/api-dtos/merchant-charges/merchant-charge-request.dto.interface'
import {
    supportChargeMerchant,
    supportFetchMerchantCharges,
} from 'src/helpers/lopay_api_helper'
import SupportMerchantApiDto from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'
import { priceFormat } from 'src/helpers/utils'
import moment from 'moment'

// Charges tables
const chargesColumns = [
    {
        dataField: 'total',
        text: 'Amount',
    },
    {
        dataField: 'description',
        text: 'Description',
    },
    {
        dataField: 'createdAt',
        text: 'Date',
    },
]

const merchantChargeChoices: {
    value: MerchantChargeCategory
    label: string
}[] = [
    {
        value: 'chargeback',
        label: 'Chargeback',
    },
    {
        value: 'kyc_admin',
        label: 'KYC Admin',
    },
    {
        value: 'support',
        label: 'Support',
    },
]

type SelectOption = { value: string; label: string }

const availableMerchantChargeCategoryFilters: SelectOption[] = [
    {
        value: 'kyc_admin',
        label: 'KYC Admin',
    },
    {
        value: 'chargeback',
        label: 'Chargeback',
    },
    {
        value: 'support',
        label: 'Support',
    },
    {
        value: 'capital_repayment',
        label: 'Working capital repayment',
    },
    {
        value: 'payout_fee',
        label: 'Instant payout upgrade fee',
    },
    {
        value: 'payment_fee_deficit',
        label: 'Payment fee deficit',
    },
]

type IComponentProps = {
    merchant: SupportMerchantApiDto | undefined
}

const SupportMerchantCharges: React.FC<IComponentProps> = ({ merchant }) => {
    // Merchant changes
    const [merchantCharges, setMerchantCharges] = useState<MerchantChargeDto[]>(
        []
    )

    const [selectedMerchantChargeFilters, setSelectedMerchantChargeFilters] =
        useState<SelectOption[]>(availableMerchantChargeCategoryFilters)

    // Create a merchant charge
    const [merchantChargeCategory, setMerchantChargeCategory] =
        useState<MerchantChargeCategory>('support')
    const [merchantChargeAmount, setMerchantChargeAmount] = useState<string>('')
    const [merchantChargeDescription, setMerchantChargeDescription] =
        useState<string>('')

    const [loadingChargingMerchant, setLoadingChargingMerchant] =
        useState<boolean>(false)

    const chargesPageOptions = React.useCallback(() => {
        return {
            sizePerPage: 10,
            totalSize: merchantCharges?.length || 0,
            custom: true,
        }
    }, [merchantCharges])

    const chargeMerchant = async () => {
        if (!merchant) {
            return
        }

        const request: MerchantChargeRequestBody = {
            source: 'balance',
            amountUnits:
                parseFloat(
                    merchantChargeAmount === '' ? '0' : merchantChargeAmount
                ) * 100,
            currency: merchant.currencyCode,
            description: merchantChargeDescription,
            category: merchantChargeCategory,
        }

        setLoadingChargingMerchant(true)

        await supportChargeMerchant(merchant.id, request)
            .catch((e) => {
                setLoadingChargingMerchant(false)
                alert('Could not charge merchant' + e)
            })
            .finally(() => {
                setLoadingChargingMerchant(false)
            })

        // Set the values
        setMerchantChargeDescription('')
        setMerchantChargeAmount('')
        loadMerchantCharges()
    }

    const chargesList = (): {
        id: string
        total: string
        createdAt: string
    }[] => {
        return merchantCharges?.map((charge) => ({
            id: charge.id,
            total: priceFormat(charge.amount),
            description: charge.description,
            createdAt: moment(charge.createdAt)
                .local()
                .format('DD-MM-YY hh:mm'),
        }))
    }

    const loadMerchantCharges = React.useCallback(async () => {
        if (!merchant) {
            return
        }

        const categories = selectedMerchantChargeFilters.map((option) => option.value)
        const { charges } = await supportFetchMerchantCharges({
            merchantId: merchant.id,
            categories,
        })
        setMerchantCharges(charges)
    }, [merchant, selectedMerchantChargeFilters])

    useEffect(() => {
        if (!merchant) {
            return
        }

        loadMerchantCharges()
    }, [loadMerchantCharges, merchant, selectedMerchantChargeFilters])

    return (
        <CardBody>
            <AvForm>
                <CardTitle>Charge this merchant</CardTitle>

                <form className="row gx-3 gy-2 align-items-center">
                    <Col sm={6}>
                        <Label
                            className="visually-hidden"
                            htmlFor="specificSizeInputName"
                        >
                            Name
                        </Label>
                        <select
                            value={merchantChargeCategory}
                            className="form-select"
                            onChange={(e) => {
                                setMerchantChargeCategory(
                                    e.target.value as MerchantChargeCategory
                                )
                            }}
                        >
                            {merchantChargeChoices.map((option) => (
                                <option value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col sm={4}>
                        <Label
                            className="visually-hidden"
                            htmlFor="specificSizeInputGroupUsername"
                        >
                            Amount to charge
                        </Label>
                        <div className="input-group">
                            <div className="input-group-text">£</div>
                            <Input
                                type="text"
                                className="form-control"
                                id="specificSizeInputGroupUsername"
                                placeholder="0.00"
                                value={merchantChargeAmount}
                                onChange={(e) =>
                                    setMerchantChargeAmount(e.target.value)
                                }
                            />
                        </div>
                    </Col>
                    <Col sm={10}>
                        <Label
                            className="visually-hidden"
                            htmlFor="specificSizeInputGroupUsername"
                        >
                            Description
                        </Label>
                        <div className="input-group">
                            <Input
                                type="text"
                                className="form-control"
                                id="specificSizeInputGroupUsername"
                                placeholder="Description"
                                value={merchantChargeDescription}
                                onChange={(e) => {
                                    setMerchantChargeDescription(e.target.value)
                                }}
                            />
                        </div>
                    </Col>
                    <div className="col-auto">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={async () => {
                                if (loadingChargingMerchant) {
                                    return
                                }

                                await chargeMerchant()
                            }}
                        >
                            {loadingChargingMerchant ? (
                                <>Charging...</>
                            ) : (
                                <>Charge</>
                            )}
                        </button>
                    </div>
                </form>

                <CardTitle className="mt-3">Recent charges</CardTitle>
                <Label
                    htmlFor="specificSizeInputName"
                >
                    Filter by charge category
                </Label>
                <Select
                    value={selectedMerchantChargeFilters}
                    options={availableMerchantChargeCategoryFilters}
                    onChange={(values: SelectOption[]) => {
                        setSelectedMerchantChargeFilters(values)
                    }}
                    isMulti
                />
                <PaginationProvider
                    pagination={paginationFactory(chargesPageOptions())}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            columns={chargesColumns}
                            data={chargesList()}
                            search
                        >
                            {(toolkitProps) => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    // responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={[
                                                        {
                                                            dataField: 'date',
                                                            order: 'desc',
                                                        },
                                                    ]}
                                                    classes={
                                                        'table align-middle table-nowrap'
                                                    }
                                                    headerWrapperClasses={
                                                        'thead-light'
                                                    }
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
            </AvForm>
        </CardBody>
    )
}

export default SupportMerchantCharges
