import React, { useEffect, useState } from 'react'
import { Badge, Input, Label } from 'reactstrap'
import Select from "react-select";

import { HardwareDto } from '../hardware.type'
import { convertToUnits } from 'src/helpers/utils'
import { fetchCountries } from 'src/helpers/lopay_api_helper'
import { formatNiceName, formatNotNiceName } from '../hardware.utils';

type IComponentProps = {
    data: HardwareDto[] | undefined
    isLoading: boolean
    isError: boolean
    onCloseModal: () => void
    onSubmitNewHardware: (newHardware: Partial<HardwareDto>, newMerchantsOnly: boolean, overwriteDefaultTerminals: boolean) => void
    createError: string
}

const CreateHardware: React.FC<IComponentProps> = ({
    data,
    isLoading,
    isError,
    onCloseModal,
    onSubmitNewHardware,
    createError
}) => {
    const [newHardware, setNewHardware] = useState<Partial<HardwareDto>>({
        name: '',
        hardwareCostUnits: 0,
        postageCostUnits: 0,
        firstOrderDiscountUnits: 0,
        vatPercent: 0,
        countriesAvailableIn: [],
        fallbackToStripe: true,
        isTerminal: false,
        mintsoftSku: '',
        stripeSku: '',
        hardwareParentId: null
    })
    const [newHardwareHasParent, setNewHardwareHasParent] = useState(false);
    const [newHardwareParentOption, setNewHardwareParentOption] = useState<{label: string, value: string} | null>()
    const [newHardwareCostDecimal, setNewHardwareCostDecimal] = useState('');
    const [newPostageCostDecimal, setNewPostageCostDecimal] = useState('');
    const [newFirstOrderDiscountDecimal, setNewFirstOrderDiscountDecimal] = useState('');
    const [countriesOptions, setCountriesOptions] = useState<{label: string, value: string}[]>([]);
    const [countriesLoading, setCountriesLoading] = useState(true);
    const [isForNewMerchantsOnly, setIsForNewMerchantsOnly] = useState(false);
    const [countriesWithExistingDefaultTerminal, setCountriesWithExistingDefaultTerminal] = useState<string[]>([])
    const [overwriteDefaultTerminals, setOverwriteDefaultTerminals] = useState(false);


    const handleFetchCountries = async () => {
        const countries = await fetchCountries()
        setCountriesOptions(countries.map(country => ({
            label: `${country.flag} ${country.name}`,
            value: country.code,
        })))
        setCountriesLoading(false);
    }

    const updateCountriesWithExistingTerminal = (selectedCountries: string[]) => {
        if (!data) return
        const countries = []
        console.log(selectedCountries)
        for (const countryCode of selectedCountries) {
            const defaultTerminalHardwareForCountry = data.find(h => 
                h.isDefaultTerminalForCountry && h.countriesAvailableIn.includes(countryCode)
            )
            if (defaultTerminalHardwareForCountry) {
                const country = countriesOptions.find(countryOption => countryOption.value === countryCode)
                countries.push(country!.label)
            }
        }
        setCountriesWithExistingDefaultTerminal(countries)
    }

    useEffect(() => {
        handleFetchCountries();
    }, [])

    if (isLoading || countriesLoading) {
        return <div>Loading ...</div>
    }

    if (isError) {
        return <div>Error loading hardware. Please try again.</div>
    }

    return (
        <React.Fragment>
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    New Hardware
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseModal()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <div>
                    <div className="mb-4">
                        <div>
                            <div className="mb-3">
                                <Label htmlFor="hardware-name" className="form-Label">Name <span className="card-title-desc mb-2">{newHardware.name ? `(${newHardware.name})` : ''}</span></Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Name of Hardware"
                                    onChange={e => {
                                        setNewHardware({
                                            ...newHardware,
                                            name: formatNotNiceName(e.target.value)
                                        })
                                    }}
                                    defaultValue={formatNiceName(newHardware.name!)}
                                    id="hardware-name"
                                />
                            </div>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="new-merchants-only"
                                        defaultChecked={isForNewMerchantsOnly}
                                        onChange={() => setIsForNewMerchantsOnly(!isForNewMerchantsOnly)}/>
                                    <label className="form-check-label" htmlFor="new-merchants-only">
                                        Available to new merchants only
                                    </label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="is-terminal"
                                        defaultChecked={newHardware.isTerminal}
                                        onChange={() => setNewHardware({
                                            ...newHardware!,
                                            isTerminal: !newHardware.isTerminal
                                        })}/>
                                    <label className="form-check-label" htmlFor="is-terminal">
                                        Is a Terminal
                                    </label>
                                </div>
                            </div>

                            {newHardware.isTerminal ?
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="is-default-terminal"
                                            defaultChecked={newHardware.isDefaultTerminalForCountry}
                                            onChange={() => {
                                                setNewHardware({
                                                    ...newHardware!,
                                                    isDefaultTerminalForCountry: !newHardware.isDefaultTerminalForCountry
                                                })
                                                updateCountriesWithExistingTerminal(newHardware.countriesAvailableIn!)
                                            }
                                        }/>
                                        <label className="form-check-label" htmlFor="is-default-terminal">
                                            Is Default Terminal for country/countries
                                        </label>
                                        {newHardware.isDefaultTerminalForCountry && countriesWithExistingDefaultTerminal.length ?
                                            <>
                                            <br />
                                            <strong className="mb-0 text-danger">The following countries already have default terminals:</strong>
                                            {countriesWithExistingDefaultTerminal.map(country => (
                                                <p className='mb-0 text-danger'>
                                                    {country}
                                                </p>
                                            ))}
                                            </>
                                        : null}
                                    </div>
                                </div>
                            : null}

                            {
                                newHardware.isTerminal &&
                                newHardware.isDefaultTerminalForCountry &&
                                countriesWithExistingDefaultTerminal.length ?
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="overwrite-default-terminal"
                                        defaultChecked={overwriteDefaultTerminals}
                                        onChange={() => {
                                            setOverwriteDefaultTerminals(!overwriteDefaultTerminals)
                                        }
                                        }/>
                                        <label className="form-check-label text-danger" htmlFor="overwrite-default-terminal">
                                            Overwrite <strong>{newHardware.name ? formatNiceName(newHardware.name) : 'this terminal'}</strong> as the new default for the above countries
                                        </label>
                                    </div>
                                </div>
                            : null}

                            {data && data.length ?
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="is-accessory"
                                            defaultChecked={newHardwareHasParent}
                                            onChange={() => {
                                                setNewHardwareHasParent(!newHardwareHasParent)
                                                if (newHardwareHasParent){
                                                    setNewHardware({
                                                        ...newHardware!,
                                                        hardwareParentId: null
                                                    })
                                                } else if (newHardwareParentOption) {
                                                    setNewHardware({
                                                        ...newHardware!,
                                                        hardwareParentId: newHardwareParentOption?.value
                                                    })
                                                }
                                            }}/>
                                        <label className="form-check-label" htmlFor="is-accessory">
                                            Is an accessory to another hardware item
                                        </label>
                                    </div>
                                </div>
                            : null}

                            {data && data.length && newHardwareHasParent ?
                                <div className="mb-3">
                                    <Label
                                        htmlFor="choices-multiple-default"
                                        className="form-label"
                                    >
                                        Parent Hardware
                                    </Label>
                                    <Select
                                        value={newHardwareParentOption}
                                        onChange={(selection: {label: string, value: string}) => {
                                            setNewHardware({
                                                ...newHardware,
                                                hardwareParentId: selection.value
                                            })
                                            setNewHardwareParentOption(selection)
                                        }}
                                        options={data.map(h => {
                                            return {
                                                label: formatNiceName(h.name),
                                                value: h.id
                                            }
                                        })}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            : null}

                            <div className="mb-3">
                                <Label
                                    htmlFor="choices-multiple-default"
                                    className="form-label"
                                >
                                    Countries Available In
                                </Label>
                                <Select
                                    defaultValue={newHardware.countriesAvailableIn!.map(selectedCountryCode => {
                                        const country = countriesOptions.find(co => co.value === selectedCountryCode);
                                        return country;
                                    })}
                                    onChange={(list: {label: string, value: string}[]) => {
                                        setNewHardware({
                                            ...newHardware,
                                            countriesAvailableIn: list.map(i => i.value)
                                        })
                                        updateCountriesWithExistingTerminal(list.map(i => i.value))
                                    }}
                                    isMulti
                                    options={countriesOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>

                            <hr/>

                            <div className="mb-3">
                                <Label
                                htmlFor="hardware-cost"
                                className="form-Label"
                                >
                                    Hardware Cost
                                </Label>

                                <div className="input-group">
                                    <div className="input-group-text">£</div>
                                    <Input
                                        type="number"
                                        min="1"
                                        step="any"
                                        className="form-control"
                                        value={newHardwareCostDecimal}
                                        onChange={(e) => {
                                            setNewHardwareCostDecimal(e.target.value)
                                            setNewHardware({
                                                ...newHardware!,
                                                hardwareCostUnits: convertToUnits(e.target.value)
                                            })
                                        }}
                                        id="hardware-cost"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <Label
                                htmlFor="postage-cost"
                                className="form-Label"
                                >
                                    Postage Cost
                                </Label>

                                <div className="input-group">
                                    <div className="input-group-text">£</div>
                                    <Input
                                        type="number"
                                        min="1"
                                        step="any"
                                        className="form-control"
                                        value={newPostageCostDecimal}
                                        onChange={(e) => {
                                            setNewPostageCostDecimal(e.target.value)
                                            setNewHardware({
                                                ...newHardware!,
                                                postageCostUnits: convertToUnits(e.target.value)
                                            })
                                        }}
                                        id="postage-cost"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <Label
                                htmlFor="discount-amount"
                                className="form-Label"
                                >
                                    First Order Discount Amount
                                </Label>

                                <div className="input-group">
                                    <div className="input-group-text">£</div>
                                    <Input
                                        type="number"
                                        min="1"
                                        step="any"
                                        className="form-control"
                                        value={newFirstOrderDiscountDecimal}
                                        onChange={(e) => {
                                            setNewFirstOrderDiscountDecimal(e.target.value)
                                            setNewHardware({
                                                ...newHardware!,
                                                firstOrderDiscountUnits: convertToUnits(e.target.value)
                                            })
                                        }}
                                        id="discount-amount"
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>

                            <div className="mb-3">
                                <Label
                                htmlFor="vat-percent"
                                className="form-Label"
                                >
                                    VAT
                                </Label>

                                <div className="input-group">
                                    <Input
                                        type="number"
                                        min="0"
                                        max="100"
                                        step="any"
                                        className="form-control"
                                        value={(newHardware.vatPercent! * 100).toString()}
                                        onChange={(e) =>
                                            setNewHardware({
                                                ...newHardware!,
                                                vatPercent: Number(e.target.value) / 100
                                            })
                                        }
                                        id="discount-amount"
                                        placeholder="0"
                                    />
                                        <div className="input-group-text">%</div>
                                </div>
                            </div>

                            <hr/>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="fallback-to-stripe"
                                        defaultChecked={!newHardware.fallbackToStripe}
                                        onChange={() => setNewHardware({
                                            ...newHardware!,
                                            fallbackToStripe: !newHardware.fallbackToStripe
                                        })}
                                    />
                                    <label className="form-check-label" htmlFor="fallback-to-stripe">
                                        Provided by Mintsoft Only
                                    </label>
                                </div>
                            </div>

                            {newHardware.fallbackToStripe ? 
                                <div className="mb-3">
                                    <Label htmlFor="hardware-stripe-sku" className="form-Label">Stripe SKU</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="thsku_abcXYZ123"
                                        onChange={e => {
                                            setNewHardware({
                                                ...newHardware,
                                                stripeSku: e.target.value
                                            })
                                        }}
                                        defaultValue={newHardware.stripeSku!}
                                        id="hardware-stripe-sku"
                                        />
                                </div>
                            : null }

                            <div className="mb-3">
                                <Label htmlFor="hardware-mintsoft-sku" className="form-Label">Mintsoft SKU</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="ABC-123"
                                    onChange={e => {
                                        setNewHardware({
                                            ...newHardware,
                                            mintsoftSku: e.target.value
                                        })
                                    }}
                                    defaultValue={newHardware.mintsoftSku!}
                                    id="hardware-mintsoft-sku"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <Badge className="me-2 bg-danger">{createError}</Badge>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        onSubmitNewHardware(newHardware, isForNewMerchantsOnly, overwriteDefaultTerminals)
                    }}
                    type="button"
                    className="btn btn-primary"
                    disabled={newHardware.isDefaultTerminalForCountry && !!countriesWithExistingDefaultTerminal.length && !overwriteDefaultTerminals}
                >
                    Create Hardware
                </button>
            </div>
        </React.Fragment>
    )
}

export default CreateHardware
