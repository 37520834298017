import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import moment from 'moment'
import { Search } from "react-bootstrap-table2-toolkit";

import _ from 'lodash'

//redux
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Modal,
  Badge,
  Table,
  Container,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  archivePaymentRequest,
  createPaymentRequest,
  fetchSetupIntent,
  getUserProfile,
  listPaymentRequests,
} from "../../helpers/lopay_api_helper";
import { fetchProfile } from "../../store/actions";
import UserProfileResponse from "../../api-dtos/profile/user-profile-response.dto.interface";
import { PaymentRequestsResponseDto } from "../../api-dtos/payment-requests/list-payment-requests-response.dto.interface";
import PaymentRequestDto from "../../api-dtos/payment-requests/payment-request.dto.interface";
import { currencyFormat, priceFormat } from "../../helpers/utils";
import PaymentRequestStatus from "../../api-dtos/payment-requests/payment-request-status.enum";
import SweetAlert from "react-bootstrap-sweetalert";
import PaymentForm from "./payment-form";
import config from "../../config";

interface MerchantPageProps {
  history: any;
}

const stripePromise = loadStripe(config.stripe.publicKey);

const PaymentRequestsPage = ({ history }: MerchantPageProps) => {
  const dispatch = useDispatch();

  const { SearchBar } = Search;
  const [searchText, setSearchText] = useState<string>("");

  // Stripe setup intent
  const [clientSecret, setClientSecret] = useState<string | undefined>();

  // Selected payment request (for modal)
  const [selectedPaymentRequest, setSelectedPaymentRequest] = useState<
    PaymentRequestDto | undefined
  >();

  // Payment request state (created in model)
  const [, setNewPaymentRequestLoading] = useState<boolean>(false);
  const [newPaymentRequestError, setNewPaymentRequestError] = useState<
    string | null
  >();
  const [newPaymentRequest, setNewPaymentRequest] = useState<
    PaymentRequestDto | undefined
  >();
  const [newPaymentRequestName, setNewPaymentRequestName] =
    useState<string>("");
  const [newPaymentRequestAmountDecimal, setNewPaymentRequestAmountDecimal] =
    useState<string>("");

  const [isShowingNewPaymentRequestModal, setIsShowingNewPaymentRequestModal] =
    useState(false);

  const [
    deletePaymentRequestConfirmation,
    setDeletePaymentRequestConfirmation,
  ] = useState(false);

  // List of payment request
  const [isLoadingPaymentRequests, setIsLoadingPaymentRequests] =
    useState<boolean>(false);
  const [paymentRequests, setPaymentRequests] =
    useState<PaymentRequestsResponseDto>({
      pageSize: 0,
      count: 0,
      paymentRequests: [],
    });

  const [userProfile] = useState<UserProfileResponse | null>(getUserProfile());

  const merchant = userProfile?.user.merchants[0];

  var debouncedSearch = _.debounce(function (e) {
    if (!merchant) {
      return
    }
    console.log('Searching payment links for:', e);
    loadPaymentRequests(
      userProfile?.user.merchants[0]?.id,
      undefined,
      e.trim(),
    );
    }, 1200);
  
  const paymentDate = (date: Date) => {
    return new Date(date);
  };

  const copyLink = (link: PaymentRequestDto) => {
    navigator.clipboard.writeText(link.link).then(
      function () {
        alert("Link copied");
      },
      function (err) {
        alert("Could not copy link");
      }
    );
  };

  useEffect(() => {
    dispatch(fetchProfile());
    userProfile?.user.merchants[0]?.id &&
      loadPaymentRequests(userProfile?.user.merchants[0]?.id);
    // eslint-disable-next-line
  }, []);

  function toggleIsShowingNewPaymentRequestModal() {
    setNewPaymentRequest(undefined);
    setIsShowingNewPaymentRequestModal(!isShowingNewPaymentRequestModal);
  }

  if (!merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Payment Links</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading your payment links
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  const loadPaymentRequests = async (
    merchantId: string,
    offsetId?: string,
    query?: string,
  ) => {
    setIsLoadingPaymentRequests(true);
    const loadedPaymentRequests = await listPaymentRequests(
      merchantId,
      offsetId,
      query,
      undefined,
    );
    setIsLoadingPaymentRequests(false);

    if (offsetId && paymentRequests) {
      loadedPaymentRequests.paymentRequests =
        paymentRequests.paymentRequests.concat(loadedPaymentRequests.paymentRequests);
    }

    setPaymentRequests(loadedPaymentRequests);
  };

  const handleCreatePaymentRequest = async () => {
    const amountString = newPaymentRequestAmountDecimal;
    const amountUnits: number = parseInt(
      (parseFloat(amountString) * 100).toFixed(0)
    );

    setNewPaymentRequestError(null);

    if (!newPaymentRequestName || !newPaymentRequestName.trim().length) {
      setNewPaymentRequestError(
        "Please enter a name or short description for your payment link"
      );
      return;
    }

    if (!amountUnits || amountUnits < 0) {
      setNewPaymentRequestError("Please enter an amount of money to request");
      return;
    }

    setNewPaymentRequestLoading(true);

    const [paymentRequest, intentSecret] = await Promise.all([
      createPaymentRequest(
        merchant.id,
        amountUnits,
        newPaymentRequestName,
        merchant.currencyCode
      ),
      fetchSetupIntent(),
    ]);

    setClientSecret(intentSecret.clientSecret);
    setNewPaymentRequest(paymentRequest);
    setNewPaymentRequestLoading(false);

    paymentRequests.paymentRequests.unshift(paymentRequest);
  };

  const renderPaymentStatus = (status: PaymentRequestStatus, paidAt?: Date) => {
    switch (status) {
      case "pending":
        return <Badge className="me-2 bg-danger">Pending payment</Badge>;
      case "cancelled":
        return <Badge className="me-2 bg-dark">Cancelled</Badge>;
      case "completed":
        if (paidAt) {
          return <Badge className="me-2 bg-success">Completed at {moment(
            paymentDate(paidAt)
          ).format("MMM Do YYYY h:mm:ss a")}</Badge>;
        } else {
          return <Badge className="me-2 bg-success">Completed</Badge>;
        }
    }
  };

  const handleArchiveOpenPaymentRequest = async () => {
    if (!selectedPaymentRequest) {
      return;
    }

    await archivePaymentRequest(merchant.id, selectedPaymentRequest.id);

    const indexOfPaymentRequest = paymentRequests.paymentRequests.findIndex(
      (pr) => pr.id === selectedPaymentRequest.id
    );

    paymentRequests.paymentRequests.splice(indexOfPaymentRequest, 1);

    setSelectedPaymentRequest(undefined);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Payment Links</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={merchant.name} breadcrumbItem="Payment links" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                  <div className="col-md-4 align-items-left">
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <SearchBar placeholder='Search by reference' searchText={searchText}  onSearch={(e) => {
                            console.log('e', e)
                            setSearchText(e)
                            debouncedSearch(e)
                          }} />
                          <i className="bx bx-search-alt search-icon-search" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              toggleIsShowingNewPaymentRequestModal();
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i> New Payment Link
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Ref (name)</th>
                          <th>Amount (£)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {(isLoadingPaymentRequests && !paymentRequests?.paymentRequests?.length) ? (
                        <tbody><tr>
                        <th>Loading...</th></tr></tbody>
                      ) : (
                        <tbody>
                          {paymentRequests &&
                            paymentRequests.paymentRequests.map(
                              (paymentRequest) => {
                                return (
                                  <tr
                                    onClick={async () => {
                                      setClientSecret(undefined)
                                      setSelectedPaymentRequest(paymentRequest)

                                      const intentSecret = await fetchSetupIntent()
                                      setClientSecret(intentSecret.clientSecret);
                                    }}
                                    key={paymentRequest.id}
                                  >
                                    <td>
                                      <span className="time">
                                        {moment(
                                          paymentDate(paymentRequest.createdAt)
                                        ).format("MMM Do h:mm:ss a")}
                                      </span>
                                    </td>
                                    <th scope="row">
                                      {paymentRequest.customerFacingDescription}
                                    </th>
                                    <td>
                                      {priceFormat(paymentRequest.amount)}
                                    </td>
                                    <td>
                                      {renderPaymentStatus(
                                        paymentRequest.status,
                                        paymentRequest.paidAt,
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      )}
                    </Table>

                    {paymentRequests?.paymentRequests?.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-dark btn-sm waves-effect waves-light mt-2"
                        onClick={() => {
                          if (isLoadingPaymentRequests || !userProfile?.user.merchants[0]?.id) {
                            return;
                          }
                          const lastItem = paymentRequests.paymentRequests[paymentRequests?.paymentRequests.length-1]
                          loadPaymentRequests(
                            userProfile?.user.merchants[0]?.id,
                            lastItem.id,
                            searchText,
                          );
                        }}
                      >
                        {isLoadingPaymentRequests && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                          </>
                        )}
                        {isLoadingPaymentRequests ? <b>Loading...</b> : <b>Show more</b>}
                      </button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isShowingNewPaymentRequestModal}
            toggle={() => {
              toggleIsShowingNewPaymentRequestModal();
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">New payment link</h5>
              <button
                type="button"
                onClick={() => setIsShowingNewPaymentRequestModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  {newPaymentRequestError ? (
                    <Alert color="danger">{newPaymentRequestError}</Alert>
                  ) : null}
                </div>

                {newPaymentRequest ? (
                  <div className="modal-body">
                    <div>
                      <div className="mb-4">
                        <h5 className="font-size-14 mb-1">
                          Sharable payment link
                        </h5>
                        <p className="card-title-desc mb-2">
                          Share this link via email or messaging app:
                        </p>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={newPaymentRequest?.link}
                        >
                          {newPaymentRequest?.link}
                        </a>
                        <hr />
                        {clientSecret && (
                          <>
                            <p className="card-title-desc mb-4">
                              Pay now by entering card details:
                            </p>
                            <Elements
                              stripe={stripePromise}
                              options={{ clientSecret }}
                            >
                              <PaymentForm
                                setupSecret={clientSecret}
                                link={newPaymentRequest}
                                paymentCompleted={() => {
                                  toggleIsShowingNewPaymentRequestModal();
                                  setNewPaymentRequest({
                                    ...newPaymentRequest,
                                    status: PaymentRequestStatus.completed,
                                  })
                                  paymentRequests.paymentRequests.unshift(newPaymentRequest)
                                }}
                              />
                            </Elements>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Name or description
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="x3 boxes of supplies"
                        onChange={(e) =>
                          setNewPaymentRequestName(e.target.value)
                        }
                        defaultValue=""
                        id="example-text-input"
                      />
                    </div>
                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        className="form-control"
                        onChange={(e) =>
                          setNewPaymentRequestAmountDecimal(e.target.value)
                        }
                        id="specificSizeInputGroupUsername"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsShowingNewPaymentRequestModal(false)}
                >
                  Close
                </button>
                {!newPaymentRequest?.id && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleCreatePaymentRequest()}
                  >
                    Create payment link
                  </button>
                )}
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={!!selectedPaymentRequest}
            toggle={() => {
              setSelectedPaymentRequest(undefined);
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                £{currencyFormat(selectedPaymentRequest?.amount.units || 0)}{" "}
                {selectedPaymentRequest &&
                  renderPaymentStatus(selectedPaymentRequest?.status)}
              </h5>
              <button
                type="button"
                onClick={() => setSelectedPaymentRequest(undefined)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>


              <div className="mb-4">
                      <div className="table-responsive">
                        <Table className="table mb-0">
                          <tbody>
                          {
                              selectedPaymentRequest?.createdAt && <tr>
                              <th>Created</th>
                              <td>{moment(
                                    paymentDate(selectedPaymentRequest.createdAt)
                                  ).format("MMM Do h:mm:ss a")}</td>
                            </tr>
                            }
                            <tr>
                              <th>Ref (name)</th>
                              <td>{selectedPaymentRequest?.customerFacingDescription}</td>
                            </tr>
                            {
                              selectedPaymentRequest?.paidAt && <tr>
                              <th>Paid</th>
                              <td>{moment(
                                    paymentDate(selectedPaymentRequest.paidAt)
                                  ).format("MMM Do h:mm:ss a")}</td>
                            </tr>
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>


                <div className="mb-4">
                  {
                    selectedPaymentRequest?.status === PaymentRequestStatus.pending && <>
                    <h5 className="font-size-14 mb-1">Sharable payment link</h5>
                  <p className="card-title-desc mb-2">
                    Share this link via email or messaging app. The recipient
                    can click the link to pay:
                  </p>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={selectedPaymentRequest?.link}
                  >
                    {selectedPaymentRequest?.link}
                  </a>

                  <hr />
                    </>
                  }
                  
                  {(clientSecret && selectedPaymentRequest && selectedPaymentRequest.status === PaymentRequestStatus.completed) && (
                  <>
                  <Alert color="success">
                    This payment link has been paid
                  </Alert>
                  </>
                  )}
                        {(clientSecret && selectedPaymentRequest && selectedPaymentRequest.status !== PaymentRequestStatus.completed) && (
                          <>
                            <p className="card-title-desc mb-4">
                              Pay now by entering card details:
                            </p>
                            <Elements
                              stripe={stripePromise}
                              options={{ clientSecret }}
                            >
                              <PaymentForm
                                setupSecret={clientSecret}
                                link={selectedPaymentRequest}
                                paymentCompleted={() => {
                                  setSelectedPaymentRequest({
                                    ...selectedPaymentRequest,
                                    status: PaymentRequestStatus.completed,
                                  })
                                }}
                              />
                            </Elements>
                          </>
                        )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {selectedPaymentRequest?.status === PaymentRequestStatus.pending && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setDeletePaymentRequestConfirmation(true)}
                >
                  Delete link
                </button>
              )}

              {selectedPaymentRequest?.status === PaymentRequestStatus.pending && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => copyLink(selectedPaymentRequest)}
                >
                  Copy payment link
                </button>
              )}
              {selectedPaymentRequest?.status !== "cancelled" &&
                deletePaymentRequestConfirmation && (
                  <SweetAlert
                    title="Confirm Deletion?"
                    warning
                    showCancel
                    // confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                      setDeletePaymentRequestConfirmation(false);
                      handleArchiveOpenPaymentRequest();
                    }}
                    onCancel={() => setDeletePaymentRequestConfirmation(false)}
                  >
                    Please confirm you would like to delete this payment link
                  </SweetAlert>
                )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setSelectedPaymentRequest(undefined)}
              >
                Close
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentRequestsPage;
