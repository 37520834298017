import React, { useState, useCallback, useEffect } from 'react'
import { Button, Modal, Label, Input, Alert } from 'reactstrap'
import styled from 'styled-components'
import currency from 'currency.js'

import { PayoutMechanismType } from 'src/api-dtos/types/payout-mechanism.type'
import { supportPayoutFees, triggerPayout as triggerPayoutApi } from 'src/helpers/lopay_api_helper'
import PriceDto from '../../../api-dtos/types/price.dto'
import Currency from '../../../api-dtos/types/currency.type'
import { priceFormat } from '../../../helpers/utils'

const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`

interface IComponentProps {
    merchantId: string
    merchantName: string
    currencyCode: Currency
    payoutMade: () => void
}

const TriggerPayout: React.FC<IComponentProps> = ({
    merchantId,
    merchantName,
    payoutMade,
    currencyCode,
}) => {
  
    let payoutFeesController = new AbortController()
    
    const [isLoading, setIsLoading] = useState(false)
    const [excludeFees, setExcludeFees] = useState(true)
    const [payoutError, setPayoutError] = useState<string | undefined>(
        undefined
    )

    const [isLoadingPayoutFees, setIsLoadingPayoutFees] = useState(false)
    const [payoutFeesError, setPayoutFeesError] = useState<string | undefined>(
        undefined
    )
    const [payoutFees, setPayoutFees] = useState<PriceDto>({
        units: 0,
        currencyCode,
    })

    const [modalOpen, setModalOpen] = useState(false)
    const [payoutAmount, setPayoutAmount] = useState<string>('')
    const [payoutMechanism, setPayoutMechanism] =
        useState<PayoutMechanismType>('stripe')

    const payoutMechanismChoices: {
        value: PayoutMechanismType
        label: string
    }[] = [
        {
            value: 'stripe',
            label: 'Stripe',
        },
        {
            value: 'true_layer',
            label: 'TrueLayer',
        },
    ]

    const updateFees = useCallback(async () => {

        try {
            payoutFeesController?.abort()
        } catch (e) {
            console.error(e)
        }

        setPayoutFeesError(undefined)

        const amountString = payoutAmount === '' ? '0' : payoutAmount
        const payoutAmountUnits = currency(amountString).intValue

        if (payoutAmountUnits <= 0) {
            setPayoutFees({
                currencyCode,
                units: 0,
            })
            return
        }

        setIsLoadingPayoutFees(true)

        try {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            payoutFeesController = new AbortController()
            setPayoutFees((await supportPayoutFees(
                merchantId,
                payoutAmountUnits,
                payoutFeesController,
            )).additionalFee)

        } catch (error: any) {
            setPayoutFeesError(error?.message || error)
        }

        setIsLoadingPayoutFees(false)

    }, [merchantId, payoutAmount, currencyCode])

    useEffect(() => {
        updateFees()
    }, [payoutAmount, updateFees])

    const triggerPayout = useCallback(async () => {
        if (isLoading) {
            return
        }

        setPayoutError(undefined)

        const amountString = payoutAmount === '' ? '0' : payoutAmount
        const payoutAmountUnits = currency(amountString).intValue

        if (payoutAmountUnits <= 0) {
            setPayoutError('Please enter an amount above zero')
            return
        }

        let didError = false

        setIsLoading(true)
        await triggerPayoutApi({
            merchantId,
            payoutMechanism,
            payoutAmountUnits,
            excludeFees,
        }).catch((error: any) => {
            didError = true
            setPayoutError(error?.message || error)
        })

        setIsLoading(false)

        if (!didError) {
            if (payoutMade) {
                payoutMade()
            }
            setPayoutAmount('')
            setModalOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, payoutAmount, payoutMechanism, excludeFees])

    return (
        <React.Fragment>
            <Button
                className="btn btn-primary"
                onClick={() => setModalOpen(true)}
            >
                Trigger Payout
            </Button>
            <Modal
                isOpen={modalOpen}
                toggle={() => setModalOpen((prev) => !prev)}
                scrollable={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{merchantName}</h5>
                    <button
                        type="button"
                        onClick={() => setModalOpen(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-4">
                        <div className="mb-3">
                            <Label htmlFor="specificSizeInputGroupUsername">
                                Payout amount
                            </Label>
                            <div className="mb-3">
                                <div className="input-group">
                                    <div className="input-group-text">£</div>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="specificSizeInputGroupUsername"
                                        placeholder="0.00"
                                        value={payoutAmount}
                                        onChange={(e) =>
                                            setPayoutAmount(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Label htmlFor="specificSizeInputName">
                                Payout mechanism
                            </Label>
                            <select
                                value={payoutMechanism}
                                className="form-select"
                                onChange={(e) => {
                                    console.log('e.target:', e.target.value)
                                    setPayoutMechanism(
                                        e.target.value as PayoutMechanismType
                                    )
                                }}
                            >
                                {payoutMechanismChoices.map((option) => (
                                    <option value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-check mt-3">
                            <input
                                checked={!excludeFees}
                                onChange={(() => setExcludeFees((v) =>!v))}
                                className="form-check-input"
                                type="checkbox"
                                id="apply-payout-fees"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="apply-payout-fees"
                            >
                                Apply payout fees (Est. {isLoadingPayoutFees ? <>Calculating fee...</> : priceFormat(payoutFees)}) {payoutFeesError && <>(Error: {payoutFeesError})</>}
                            </label>
                        </div>

                        <div>
                            {payoutError ? (
                                <Alert color="danger">{payoutError}</Alert>
                            ) : null}
                        </div>

                        <ButtonContainer>
                            <Button
                                className="btn btn-primary"
                                disabled={isLoading}
                                onClick={triggerPayout}
                            >
                                {isLoading
                                    ? 'Making payout...'
                                    : 'Trigger Payout'}
                            </Button>
                        </ButtonContainer>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default TriggerPayout
