import React from 'react'
import MetaTags from 'react-meta-tags'
import {
    Container,
    Col,
    Card,
    CardBody,
    CardHeader,
    Row,
} from 'reactstrap'

import Breadcrumbs from '../../components/Common/Breadcrumb'
import WaitingListsTable from './components/waiting-lists-table/waiting-lists-table.component'
import { useWaitingLists } from './waiting-lists.hook'

type IComponentProps = {}

const WaitingListsPage: React.FC<IComponentProps> = () => {
    const { data, isError, isLoading } = useWaitingLists()

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Waiting lists</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs
                        title={'LoPay Support'}
                        breadcrumbItem="Waiting lists"
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader></CardHeader>
                                <CardBody>
                                    <WaitingListsTable
                                        data={data}
                                        isError={isError}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default WaitingListsPage
